import {
  Alert,
  AlertIcon,
  AlertTitle,
  Box,
  Button,
  Checkbox,
  IconButton,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Stack,
  Textarea,
  useDisclosure,
} from "@chakra-ui/core";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";

import { CreateDeviceInput, UpdateDeviceInput } from "../API";
import { Device } from "../types";
interface UpsertDeviceProps {
  triggerText?: string;
  icon?: "add" | "settings";
  title: string;
  submit: Function;
  device?: Device;
}

const initInputData = (device?: Device | null): CreateDeviceInput | UpdateDeviceInput => ({
  id: device ? device.id : null,
  name: device ? device.name : "",
  active: device ? device.active : 1,
  comment: device ? device.comment : "",
});

const UpsertDevice = ({ triggerText, icon, title, submit, device }: UpsertDeviceProps) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { t } = useTranslation();
  const [inputData, setInputData] = useState<CreateDeviceInput | UpdateDeviceInput>(
    initInputData(device)
  );
  const [success, setSuccess] = useState(false);

  return (
    <>
      {icon ? (
        <IconButton onClick={onOpen} variant="ghost" aria-label={triggerText || ""} icon={icon}>
          {triggerText}
        </IconButton>
      ) : (
        <Button onClick={onOpen}>{triggerText}</Button>
      )}
      <Modal
        isOpen={isOpen}
        onClose={() => {
          onClose();
          setInputData(initInputData(null));
        }}
      >
        <ModalOverlay />
        <ModalContent>
          {success ? (
            <Alert
              status="success"
              variant="subtle"
              flexDirection="column"
              justifyContent="center"
              textAlign="center"
              height="200px"
            >
              <AlertIcon size="40px" mr={0} />
              <AlertTitle mt={4} mb={1} fontSize="lg">
                {t("Änderungen gespeichert")}!
              </AlertTitle>
            </Alert>
          ) : (
            <>
              <ModalHeader>{title}</ModalHeader>
              <ModalCloseButton />
              <ModalBody>
                <Stack spacing={3}>
                  <Input
                    placeholder={t("Gerätename")}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                      setInputData({
                        ...inputData,
                        name: e.target.value,
                      });
                    }}
                    key="name"
                    value={inputData.name || ""}
                  />
                  <Input
                    placeholder={t("Seriennummer / ID")}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                      setInputData({
                        ...inputData,
                        id: e.target.value,
                      });
                    }}
                    key="name"
                    value={inputData.id || ""}
                    isDisabled={device ? true : false}
                  />
                  <Box>
                    <Checkbox
                      // @ts-ignore
                      isChecked={inputData ? inputData.active === 0 : false}
                      onChange={(e: any) => {
                        setInputData({
                          ...inputData,
                          active: e.target.checked ? 0 : 1,
                        });
                      }}
                    >
                      {t("Gerät ausgelagert")}
                    </Checkbox>
                  </Box>
                  <Textarea
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                      setInputData({
                        ...inputData,
                        comment: e.target.value,
                      });
                    }}
                    key="comment"
                    value={inputData.comment || ""}
                    placeholder={`${t("Kommentar")}`}
                  ></Textarea>
                </Stack>
              </ModalBody>
              <ModalFooter>
                <Button mr={3} variant="outline" onClick={onClose}>
                  {t("Abbrechen")}
                </Button>
                <Button
                  variantColor="green"
                  onClick={() => {
                    submit(inputData, () => {
                      setSuccess(true);
                      setTimeout(() => {
                        onClose();
                        setSuccess(false);
                      }, 1000);
                    });
                  }}
                >
                  OK
                </Button>
              </ModalFooter>
            </>
          )}
        </ModalContent>
      </Modal>
    </>
  );
};

export default UpsertDevice;
