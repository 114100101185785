import {
  Box,
  Button,
  IconButton,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Spinner,
  Stack,
  Text,
  useDisclosure,
} from "@chakra-ui/core";
import React, { useEffect, useState } from "react";
import { measuringPointDeviceHistory } from "../../graphql/customQueries";
import _ from "lodash";
import DataTable from "react-data-table-component";
import {dateToHuman, dbTimeToDate, fetchEverything} from "../../utils";

interface UpsertProjectProps {
  triggerText?: string;
  icon?: "add" | "settings" | "info-outline";
  title: string;
  device: any
}

const UpsertProject = ({ triggerText, icon, title, device}: UpsertProjectProps) => {
  const { isOpen, onOpen, onClose } = useDisclosure();

  const [loading, setLoading] = useState(true)
  const [deviceHistory, setDeviceHistory] = useState([])


  useEffect(() => {
    if(isOpen){

      fetchDeviceHistory()
    }
  }, [isOpen])


  const fetchDeviceHistory = async () => {
    let devices = await fetchEverything(measuringPointDeviceHistory, "listMeasuringPointDevices",{filter: {deviceID: {eq: device.id}}})

    devices = _.orderBy(devices, ["startDate", "endDate"], ["desc"])

    setDeviceHistory(devices)
    setLoading(false)
    //@ts-ignore
  }

  let columns = [{
    name: "Projekt Name",
    selector: "MeasuringPoint.Project.name",
    cell: (row:any) => {

      return typeof row.MeasuringPoint?.Project?.name=== "string" ? <Text p={2}>{row.MeasuringPoint?.Project?.name}</Text> : "Nicht angegeben"
    }
  },
    {
      name: "Messpunkt",
      selector: "MeasuringPoint.name",
      cell: (row:any) => {

        return typeof row.MeasuringPoint?.name=== "string" ? <Text p={2}>{row.MeasuringPoint?.name}</Text> : "Nicht angegeben"
      }
    }
    ,{
    name: "Startdatum",
    selector: "startDate",
      cell: (row:any) => {

        return typeof row.startDate=== "string" ? dateToHuman(dbTimeToDate(row.startDate)) : "Nicht angegeben"
      }
  },{
    name: "Enddatum",
    selector: "endDate",
      cell: (row:any) => {

        return typeof row.endDate === "string" ? dateToHuman(dbTimeToDate(row.endDate)) : "Nicht angegeben"
      }
  }]

  return (
    <>
      {icon ? (
        <IconButton onClick={() => {
          onOpen()
        }} variant="ghost" aria-label={triggerText || ""} icon={icon}>
          {triggerText}
        </IconButton>
      ) : (
        <Button onClick={() => {
          onOpen()
        }
        }>{triggerText}</Button>
      )}
      <Modal size={"xl"} isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent rounded="md">
            <>
              <ModalHeader>{title}</ModalHeader>
              <ModalCloseButton />
              <ModalBody>
                {loading ? (<Box display={"flex"} alignItems={"center"}><Spinner mx={"auto"}/></Box>) : (
                  <DataTable noHeader={true} pagination={true} columns={columns} data={deviceHistory}/>
                )}
              </ModalBody>
              <ModalFooter>
                <Stack w="full" spacing={2}>
                  <Stack isInline justify="space-between" align="center">
                  </Stack>
                </Stack>
              </ModalFooter>
            </>
        </ModalContent>
      </Modal>
    </>
  );
};

export default UpsertProject;
