import { Button, Heading, Spinner, Stack, Text, useTheme } from "@chakra-ui/core";
import { getISOWeek, getYear } from "date-fns";
import React, { useEffect } from "react";
import ContainerDimensions from "react-container-dimensions";
import CsvDownloader from "react-csv-downloader";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";

import { EventFilter } from "../components/EventFilter";
import { EventsTable } from "../components/EventsTable";
import { PanelCard } from "../components/PanelCard";
import { TimeseriesDiagram } from "../components/TimeseriesDiagram";
import { onUpdateMeasuringPoint } from "../graphql/subscriptions";
import { Layout } from "../Layout";
import { useMpStore } from "../store/mp-store";
import subQuery from "../subQuery";
import { BackgroundData, DateFilter } from "../types";
import { dateToDbTime, dateToString, stringToExport } from "../utils";
import { getEventExport } from "../utils/events";

type PageParams = {
  mpId?: string;
};

const MeasuringPointScreen: React.FunctionComponent = () => {
  const { t } = useTranslation();
  const { mpId } = useParams<PageParams>();
  const {
    bgLoading,
    eventLoading,
    mp,
    peaks,
    events,
    bgDatas,
    dateFilter,
    setDateFilter,
    loadMp,
  } = useMpStore();

  const [exportLoading, setExportLoading] = React.useState<boolean>(false);

  useEffect(() => {
    if (mpId) {
      loadMp(mpId);
      subQuery([onUpdateMeasuringPoint], () => {
        loadMp(mpId);
      });
    }
  }, [mpId, mp]);

  const { colors } = useTheme();

  const getFilterText = (filter: DateFilter) => {
    const earliest = filter.earliest ? dateToString(filter.earliest) : null;
    const latest = filter.latest ? dateToString(filter.latest) : null;

    if (earliest && latest) {
      return `${earliest} - ${latest}`;
    }
    if (earliest) {
      return `nach ${earliest}`;
    }
    if (latest) {
      return `vor ${latest}`;
    }
    return null;
  };

  return (
    <Layout>
      <Stack spacing={4}>
        <Stack isInline align="center" justify="space-between">
          {mp?.name ? (
            <Stack spacing={2}>
              <Heading as="h2" size="lg">
                {mp?.name}
              </Heading>
              <Heading size="sm" color="grey">
                {mp?.project?.name}
              </Heading>
            </Stack>
          ) : (
            <Spinner />
          )}
          <Stack isInline spacing={4} align="center">
            <Text>{getFilterText(dateFilter)}</Text>
            <EventFilter setFilter={setDateFilter} filter={dateFilter} />
          </Stack>
        </Stack>
        <PanelCard>
          <Stack isInline justify="space-between" align="center">
            <Heading as="h2" size="md">
              {t("Event-Tabelle")}
            </Heading>
            {eventLoading ||
            !events ||
            events.length === 0 ||
            !peaks?.time ||
            !peaks?.vX ||
            !peaks.vY ||
            !peaks.vZ ? (
              <Button isDisabled={true}>
                <Text>Export Events</Text>
              </Button>
            ) : (
              // <CsvDownloader
              //   datas={events.map((event) => {
              //     return {
              //       "Trigger Zeitpunkt": event.starttimestamp?.toString(),
              //       Dauer: event.duration?.toString(),
              //       Vektorlänge: calcVectorLength(event).toString(),
              //       vX: event.vX?.toString(),
              //       vY: event.vY?.toString(),
              //       vZ: event.vZ?.toString(),
              //     };
              //   })}
              //   filename={`${mp?.project?.name?.toString()}_${mp?.name?.toString()}_events.csv`}
              // >
              <Button
                isDisabled={exportLoading}
                onClick={() => {
                  setExportLoading(true);
                  if (mp?.id) {
                    (async () => {
                      try {
                        const res = await getEventExport(
                          mp?.id,
                          dateFilter.earliest ? dateToDbTime(dateFilter.earliest) : undefined,
                          dateFilter.latest ? dateToDbTime(dateFilter.latest) : undefined
                        );
                        const presigned = res?.data?.presignedurl;
                        if (presigned) {
                          const link = document.createElement("a");
                          link.href = presigned;
                          document.body.appendChild(link);
                          link.click();
                          document.body.removeChild(link);
                        }
                        setExportLoading(false);
                      } catch (error) {
                        setExportLoading(false);
                      }
                    })();
                  } else {
                    setExportLoading(false);
                  }
                }}
              >
                {exportLoading ? <Spinner size="sm" /> : "Export Events"}
              </Button>
              // </CsvDownloader>
            )}
          </Stack>
          {eventLoading ? <Spinner /> : <EventsTable events={events} />}
        </PanelCard>
        <PanelCard>
          <Stack isInline justify="space-between" align="center">
            <Heading as="h2" size="md">
              {t("Peak-Messwerte")}
            </Heading>
            {bgLoading ||
            !bgDatas ||
            !(bgDatas.length > 0) ||
            !peaks ||
            !(peaks.time.length > 0) ? (
              <Button isDisabled={true}>
                <Text>{t("Export Messwerte")}</Text>
              </Button>
            ) : (
              <CsvDownloader
                datas={[
                  // Header

                  {
                    cell1: "Zeit",
                    cell2: "Peak X in mm/s",
                    cell3: "Peak Y in mm/s",
                    cell4: "Peak Z in mm/s",
                  },

                  // Actual data
                  ...bgDatas.map((data: BackgroundData) => {
                    return {
                      cell1: stringToExport(data.datatimestamp),
                      cell2: data.vX.toString(),
                      cell3: data.vY.toString(),
                      cell4: data.vZ.toString(),
                    };
                  }),
                ]}
                noHeader
                wrapColumnChar={`"`}
                filename={`${getYear(new Date())}-KW${getISOWeek(new Date())}_${
                  mp?.project?.name
                }_${mp?.name?.toString()}_data.csv`}
              >
                <Button>Export Messwerte</Button>
              </CsvDownloader>
            )}
          </Stack>
          {bgLoading ? (
            <Spinner />
          ) : (
            <Stack spacing={8} align="center" justify="space-between">
              {peaks?.time && peaks?.vX && peaks.vY && peaks.vZ ? (
                <ContainerDimensions>
                  {({ width }) => (
                    <>
                      <TimeseriesDiagram
                        label="Peak X [mm/s]"
                        color={colors.red[500]}
                        data={[peaks?.time, peaks?.vX]}
                        width={width}
                      />
                      <TimeseriesDiagram
                        label="Peak Y [mm/s]"
                        color={colors.green[500]}
                        data={[peaks?.time, peaks?.vY]}
                        width={width}
                      />
                      <TimeseriesDiagram
                        label="Peak Z [mm/s]"
                        color={colors.blue[500]}
                        data={[peaks?.time, peaks?.vZ]}
                        width={width}
                      />
                    </>
                  )}
                </ContainerDimensions>
              ) : (
                <Text>{t("Keine Daten vorhanden")}.</Text>
              )}
            </Stack>
          )}
        </PanelCard>
      </Stack>
    </Layout>
  );
};

export default MeasuringPointScreen;
