import { API, graphqlOperation } from "aws-amplify";

import {
  DeleteDeviceInput,
  DeleteMeasuringPointDeviceInput,
  DeleteMeasuringPointInput,
  DeleteProjectInput,
} from "../API";
import {
  deleteDevice as deleteDeviceM,
  deleteMeasuringPoint,
  deleteMeasuringPointDevice,
  deleteProject as deleteProjectM,
} from "../graphql/mutations";
import { getMeasuringPoint, getProject, listMeasuringPointDevices } from "../graphql/queries";
import { MeasuringPoint, MeasuringPointDevice, Project } from "../types";
import { fetchEverything } from ".";

export const checkDeviceAssigned = async (deviceId: string) => {
  const measuringPointDevices: MeasuringPointDevice[] = await fetchEverything(
    listMeasuringPointDevices,
    "listMeasuringPointDevices",
    {}
  );
  const assigned = measuringPointDevices?.filter((mpd) => mpd.device.id === deviceId);
  return assigned?.length > 0;
};

export const deleteDevice = async (deviceId: string) => {
  // Check if device is assigned to any MP.
  const assigned = await checkDeviceAssigned(deviceId);
  if (assigned) {
    console.warn("Can't delete: Device is still assigned to measuring point.");
  } else {
    const input: DeleteDeviceInput = { id: deviceId };
    await API.graphql(graphqlOperation(deleteDeviceM, { input }));
  }
};

export const deleteMP = async (mpId: string) => {
  const measuringPoint: MeasuringPoint = ((await API.graphql(
    graphqlOperation(getMeasuringPoint, { id: mpId })
  )) as any)?.data.getMeasuringPoint;
  if (!measuringPoint) {
    console.error(`Measuring point ${mpId} not found`);
    return;
  }

  // Remove all measuring point devices of this measuring point.
  // @ts-ignore
  const mpds = measuringPoint.devices?.items;
  if (mpds.length > 0) {
    for (const mpd of mpds) {
      const input: DeleteMeasuringPointDeviceInput = { id: mpd.id };
      await API.graphql(graphqlOperation(deleteMeasuringPointDevice, { input }));
    }
  }

  // Remove measuring point.
  const input: DeleteMeasuringPointInput = { id: mpId };
  await API.graphql(graphqlOperation(deleteMeasuringPoint, { input }));
};

export const deleteProject = async (projectId: string) => {
  const project: Project = ((await API.graphql(
    graphqlOperation(getProject, { id: projectId })
  )) as any)?.data.getProject;

  if (!project) {
    console.error(`Project ${projectId} not found`);
    return;
  }

  // Remove measuring points and measuring point devices of this project.
  // @ts-ignore
  const mps = project.measuringPoints?.items;
  if (mps.length > 0) {
    for (const mp of mps) {
      await deleteMP(mp.id);
    }
  }

  // Remove project.
  const input: DeleteProjectInput = { id: projectId };
  await API.graphql(graphqlOperation(deleteProjectM, { input }));
};
