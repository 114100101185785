import { Heading, Stack } from "@chakra-ui/core";
import React, { FunctionComponent } from "react";
import { isMobile } from "react-device-detect";

import Navigation from "./components/Navigation";

interface Props {
  title?: string | React.ReactNode;
}

export const Layout: FunctionComponent<Props> = ({ title, children }) => {
  return (
    <Stack isInline={!isMobile} spacing={0}>
      <Navigation position="sticky" top={0} />
      <Stack
        w="full"
        py={[4, 4, 8, 8]}
        px={[2, 2, 4, 8]}
        mx="auto"
        spacing={8}
        bg="gray.50"
        maxW="2500px"
      >
        {title && (
          <Heading as="h1" size="lg">
            {title}
          </Heading>
        )}
        <Stack spacing={4}>{children}</Stack>
      </Stack>
    </Stack>
  );
};
