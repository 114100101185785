import { Icon, SimpleGrid, Spinner, Stack, Text, useTheme } from "@chakra-ui/core";
import React, { FunctionComponent, useEffect, useState } from "react";
import { isMobile } from "react-device-detect";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";

import { deviceDismantling } from "../graphql/customQueries";
import { listProjects } from "../graphql/queries";
import { useDeviceStore } from "../store/device-store";
import { useProjectStore } from "../store/project-store";
import { Device, Project } from "../types";
import { fetchEverything } from "../utils";
interface MetricPanelProps {
  name: string;
  value: number | string | null;
  icon: any;
  onClick?: React.MouseEventHandler;
  isActive?: boolean;
  isDouble?: boolean;
  name_2?: string;
  value_2?: number | string | null;
}

const MetricPanel = ({
  name,
  value,
  icon,
  onClick,
  isActive,
  isDouble,
  value_2,
  name_2,
}: MetricPanelProps) => {
  const { loading } = useDeviceStore();

  return (
    <Stack
      isInline
      align="center"
      justify="center"
      bg="white"
      px={isMobile ? 2 : 4}
      py={isMobile ? 4 : 8}
      rounded="lg"
      shadow="sm"
      spacing={4}
      cursor="pointer"
      onClick={onClick}
      borderWidth="2px"
      borderColor={isActive ? icon.color[200] : "transparent"}
    >
      <Stack p={2} justify="center" bg={icon.color[200]} rounded="full" align="center">
        <Icon size={isMobile ? "4" : "6"} name={icon.name} color={icon.color[500]} />
      </Stack>
      <Stack>
        <Stack isInline spacing={2} align="center">
          <Text fontSize={isMobile ? "xl" : "2xl"}>{loading ? <Spinner size="sm" /> : value}</Text>
          <Text fontSize={isMobile ? "sm" : "md"}>{name}</Text>
        </Stack>
        {isDouble && (
          <Stack isInline spacing={2} align="center">
            <Text fontSize={isMobile ? "xl" : "2xl"}>
              {loading ? <Spinner size="sm" /> : value_2}
            </Text>
            <Text fontSize={isMobile ? "sm" : "md"}>{name_2}</Text>
          </Stack>
        )}
      </Stack>
    </Stack>
  );
};

const Metrics: FunctionComponent = () => {
  const { t } = useTranslation();
  const [projectsActive, setProjectsActive] = useState<Project[] | null>(null);
  const [readyDismantling, setReadyDismantling] = useState([]);
  const history = useHistory();
  const { projectFilter, setProjectFilter } = useProjectStore();
  const {
    loadDevices,
    allDevices,
    deviceFilter,
    setDeviceFilter,
    assignedDevices,
  } = useDeviceStore();

  //Fetching devices that are ready to be dismantled (frei gemeldet)
  useEffect(() => {
    (async () => {
      const dismantling = await fetchEverything(deviceDismantling, "listProjects", {});
      const res: any = [];
      // @ts-ignore
      dismantling.forEach((project) => {
        const readyDevices = project.measuringPoints.items;
        if (readyDevices.length > 0) {
          res.push(...readyDevices);
        }
      });
      setReadyDismantling(res);
    })();
  }, []);

  useEffect(() => {
    (async () => {
      const projectsActive = await fetchEverything(listProjects, "listProjects", {
        filter: {
          active: { eq: 1 },
        },
      });
      // @ts-ignore
      setProjectsActive(projectsActive);
    })();
  }, []);

  useEffect(() => {
    loadDevices();
  }, []);

  const countDevices = (devices: Device[], status: number[]) => {
    return devices.filter((device) => {
      return device.status ? status.includes(device.status) : false;
    }).length;
  };

  const { colors } = useTheme();

  const metrics = [
    {
      name: `${t("Aktive Projekte")}`,
      icon: { name: "copy", color: colors.orange },
      value: projectsActive ? projectsActive.length : null,
      onClick: () => {
        history.push("/");
        setProjectFilter({ active: true, deviceStatus: undefined });
      },
      isActive: projectFilter.active && window.location.pathname === "/",
    },
    {
      name: `${t("Geräte online")}`,
      icon: { name: "check", color: colors.green },
      value: allDevices ? countDevices(allDevices, [1]) : 0,
      onClick: () => {
        history.push("/devices");
        setDeviceFilter({ ...deviceFilter, assigned: undefined, status: 1 });
        setProjectFilter({ active: undefined, deviceStatus: undefined });
      },
      isActive:
        window.location.pathname === "/devices" &&
        deviceFilter.status === 1 &&
        deviceFilter.assigned === undefined,
    },
    {
      name: `${t("Geräte Fehler")}`,
      icon: { name: "warning-2", color: colors.red },
      value: allDevices ? countDevices(allDevices, [2, 3]) : 0,
      onClick: () => {
        history.push("/devices");
        setDeviceFilter({ ...deviceFilter, assigned: undefined, status: [2, 3] });
        setProjectFilter({ active: undefined, deviceStatus: undefined });
      },
      isActive:
        window.location.pathname === "/devices" &&
        deviceFilter.status === 3 &&
        deviceFilter.assigned === undefined,
    },
    {
      isDouble: true,
      icon: { name: "moon", color: colors.gray },
      name: `${t("Geräte Bestand")}`,
      value:
        allDevices && assignedDevices.length > 0
          ? allDevices.length -
            allDevices.filter((device: Device) =>
              assignedDevices.find((assignedDevice) => assignedDevice.id === device.id)
            ).length
          : 0,
      onClick: () => {
        history.push("/devices");
        setProjectFilter({ active: undefined, deviceStatus: undefined });
        setDeviceFilter({ ...deviceFilter, assigned: false, status: undefined });
      },
      isActive:
        window.location.pathname === "/devices" &&
        projectFilter.deviceStatus === undefined &&
        projectFilter.active === undefined &&
        deviceFilter.assigned === false,
      name_2: `${t("Geräte Freimeldung")}`,
      value_2: readyDismantling.length,
    },
    {
      name: `${t("Geräte Gesamt")}`,
      icon: { name: "copy", color: colors.purple },
      value: allDevices
        ? allDevices.filter((deviceFilter) => deviceFilter.active === 1).length
        : null,
      onClick: () => {
        history.push("/devices");
        setProjectFilter({ active: undefined, deviceStatus: undefined });
        setDeviceFilter({ ...deviceFilter, assigned: undefined, status: undefined });
      },
      isActive:
        window.location.pathname === "/devices" &&
        projectFilter.deviceStatus === undefined &&
        projectFilter.active === undefined &&
        deviceFilter.assigned === undefined &&
        deviceFilter.status === undefined,
    },
  ];

  return (
    <SimpleGrid columns={[1, 2, 5, 5]} spacing={[2, 2, 4, 4]} pb={4}>
      {metrics.map((metric) => (
        <MetricPanel key={metric.name} {...metric} />
      ))}
    </SimpleGrid>
  );
};

export default Metrics;
