import {
  Button,
  FormControl,
  FormLabel,
  IconButton,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Spinner,
  Stack,
  Text,
  useDisclosure,
} from "@chakra-ui/core";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import { MeasuringPoint } from "../types";
import { deleteMP } from "../utils/delete";

interface DeleteMeasuringPointProps {
  measuringPoint: MeasuringPoint;
}

const DeleteMeasuringPoint = ({ measuringPoint }: DeleteMeasuringPointProps) => {
  const { t } = useTranslation();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [loading, setLoading] = useState<boolean>(false);
  const [warning, setWarning] = useState<string>("");
  const [applying, setApplying] = useState<boolean>(false);
  const [approved, setApproved] = useState<boolean>(false);

  useEffect(() => {
    if (!isOpen) {
      return;
    }
    setLoading(true);
    // Check if device is assigned to any measuringpoint.
    // @ts-ignore
    const assigned = measuringPoint?.devices?.items?.length > 0;
    if (assigned) {
      setWarning(
        `${t(
          "Dieser Messstelle sind noch Geräte zugeordnet. Wenn Sie fortfahren werden diese Zuordnungen ebenfalls gelöscht."
        )}`
      );
    }
    setLoading(false);
  }, [isOpen]);

  return (
    <>
      <IconButton
        variant="ghost"
        aria-label="Update Measuring Point"
        icon="delete"
        onClick={async () => {
          onOpen();
        }}
      />
      <Modal
        isOpen={isOpen}
        onClose={() => {
          onClose();
        }}
        size="5xl"
      >
        <ModalOverlay />
        <ModalContent>
          <>
            <ModalHeader>{`${t("Messstelle löschen")}: ${measuringPoint.name}`}</ModalHeader>
            <ModalCloseButton />
            <ModalBody>
              <Stack spacing={3}>
                <FormControl>
                  <FormLabel htmlFor="email">
                    {`${t(
                      "Um diese Aktion zu bestätigen, tippen Sie bitte 'entfernen' in das Eingabefeld und klicken Sie auf bestätigen."
                    )}`}
                  </FormLabel>
                  <Input
                    placeholder="entfernen"
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                      if (e.target.value === "entfernen") {
                        setApproved(true);
                      } else {
                        setApproved(false);
                      }
                    }}
                  />
                </FormControl>
              </Stack>
            </ModalBody>
            <ModalFooter>
              <Stack spacing={4}>
                {loading ? (
                  <Text>{t("Überprüfe ob Messstelle Geräte zugeordnet hat")}...</Text>
                ) : null}
                {warning ? <Text color="orange.500">{warning}</Text> : null}
                <Stack w="full" spacing={4} isInline justifyContent="flex-end">
                  <Button
                    onClick={() => {
                      onClose();
                    }}
                  >
                    abbrechen
                  </Button>
                  <Button
                    minW={32}
                    variantColor="red"
                    isDisabled={!approved}
                    onClick={async () => {
                      setApplying(true);
                      deleteMP(measuringPoint.id);
                      setApplying(false);
                      onClose();
                    }}
                  >
                    {applying ? <Spinner size="sm" /> : `${t("bestätigen")}`}
                  </Button>
                </Stack>
              </Stack>
            </ModalFooter>
          </>
        </ModalContent>
      </Modal>
    </>
  );
};

export default DeleteMeasuringPoint;
