import {
  Alert,
  AlertIcon,
  AlertTitle,
  Box,
  Button,
  Checkbox,
  FormControl,
  FormLabel,
  Icon,
  IconButton,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Select,
  Stack,
  Text,
  Textarea,
  useDisclosure,
} from "@chakra-ui/core";
import de from "date-fns/locale/de";
import React, { useEffect, useState } from "react";
import DatePicker, { registerLocale } from "react-datepicker";
import { useTranslation } from "react-i18next";

import { CreateProjectInput, UpdateProjectInput } from "../API";
import { useSettingsStore } from "../store/settings-store";
import { Project } from "../types";
import { dateToDbTime, dbTimeToDate, hasWrongOrder } from "../utils";
interface UpsertProjectProps {
  triggerText?: string;
  icon?: "add" | "settings";
  title: string;
  submit: Function;
  project?: any;
}

type InputError = {
  dateOrder: boolean | null;
};

const initInputData = (project: Project): CreateProjectInput | UpdateProjectInput => ({
  id: project ? project.id : null,
  name: project ? project.name : "",
  comment: project ? project.comment : "",
  active: project ? project.active : 1,
  timeoutActive: project ? project.timeoutActive : 0,
  projectOwner: project ? project.projectOwner : "",
  planStart: project ? project.planStart : null,
  planEnd: project ? project.planEnd : null,
  plan: false,
});

registerLocale("de", de);

const UpsertProject = ({ triggerText, icon, title, submit, project }: UpsertProjectProps) => {
  const { t } = useTranslation();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { projectOwners } = useSettingsStore();
  const [inputData, setInputData] = useState<CreateProjectInput | UpdateProjectInput>(
    initInputData(project)
  );
  const [success, setSuccess] = useState(false);

  const [errors, setErrors] = useState<InputError>({
    dateOrder: false,
  });

  useEffect(() => {
    setInputData(initInputData(project));
  }, [onOpen]);

  const onDateDelete = () => {
    setInputData((input) => ({
      ...input,
      planStart: null,
      planEnd: null,
    }));
  };

  const checkDateValidity = () => {
    if (inputData.planStart && inputData.planEnd) {
      const wrongOrder = hasWrongOrder([
        {
          startDate: dbTimeToDate(inputData.planStart),
          endDate: dbTimeToDate(inputData.planEnd),
        },
      ]);

      if (wrongOrder) {
        setErrors((err) => {
          return { ...err, dateOrder: true };
        });

        return true;
      }
    }

    return false;
  };

  return (
    <>
      {icon ? (
        <IconButton onClick={onOpen} variant="ghost" aria-label={triggerText || ""} icon={icon}>
          {triggerText}
        </IconButton>
      ) : (
        <Button onClick={onOpen}>{triggerText}</Button>
      )}
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent rounded="md">
          {success ? (
            <Alert
              status="success"
              variant="subtle"
              flexDirection="column"
              justifyContent="center"
              textAlign="center"
              height="200px"
            >
              <AlertIcon size="40px" mr={0} />
              <AlertTitle mt={4} mb={1} fontSize="lg">
                {t("Änderungen gespeichert")}!
              </AlertTitle>
            </Alert>
          ) : (
            <>
              <ModalHeader>{title}</ModalHeader>
              <ModalCloseButton />
              <ModalBody>
                <FormControl>
                  <Stack spacing={3}>
                    <Box>
                      <FormLabel htmlFor="name">Name</FormLabel>
                      <Input
                        placeholder={t("Name eingeben")}
                        onChange={(e: any) => {
                          setInputData({
                            ...inputData,
                            name: e.target.value,
                          });
                        }}
                        name="name"
                        aria-describedby="Projektname"
                        value={inputData.name ? inputData.name : project ? project.name : ""}
                      />
                    </Box>
                    <Box>
                      <FormLabel htmlFor="comment">{t("Kommentar")}</FormLabel>
                      <Textarea
                        placeholder={t("Notiz eingeben")}
                        onChange={(e: any) => {
                          setInputData({
                            ...inputData,
                            comment: e.target.value,
                          });
                        }}
                        name="comment"
                        aria-describedby={t("Notiz")}
                        value={inputData.comment ? inputData.comment : ""}
                      />
                    </Box>
                    <Box>
                      <FormLabel htmlFor="project-owner">{t("Projektleiter")}</FormLabel>
                      <Select
                        onChange={(e) => {
                          e.persist();
                          setInputData((inputData) => ({
                            ...inputData,
                            projectOwner: e.target?.value,
                          }));
                        }}
                        value={
                          inputData.projectOwner
                            ? inputData.projectOwner
                            : project
                            ? project.projectOwner
                            : ""
                        }
                        placeholder={t("Bitte wähle einen Projektleiter aus")}
                      >
                        {projectOwners?.map((owner) => {
                          return <option value={owner}>{owner}</option>;
                        })}
                      </Select>
                    </Box>
                    <Box>
                      <FormLabel htmlFor={"project-timeline"}>{t("Projektzeitraum")}</FormLabel>

                      <Stack isInline spacing={4}>
                        <Box>
                          <DatePicker
                            selected={
                              inputData.planStart ? dbTimeToDate(inputData.planStart) : null
                            }
                            onChange={(date: Date) => {
                              setInputData((inputData) => ({
                                ...inputData,
                                planStart: date ? dateToDbTime(date) : null,
                              }));
                            }}
                            locale="de"
                            todayButton="Heute"
                            placeholderText="Startdate"
                            timeIntervals={15}
                            dateFormat="dd.MM.yyyy, HH:mm"
                          />
                        </Box>
                        <Stack isInline>
                          <DatePicker
                            selected={inputData.planEnd ? dbTimeToDate(inputData.planEnd) : null}
                            onChange={(date: Date, event) => {
                              setInputData((inputData) => ({
                                ...inputData,
                                planEnd: date ? dateToDbTime(date) : null,
                              }));
                            }}
                            locale="de"
                            todayButton="today"
                            placeholderText="Enddate"
                            timeIntervals={15}
                            dateFormat="dd.MM.yyyy, HH:mm"
                          />
                        </Stack>
                        <IconButton
                          variant="ghost"
                          aria-label="Remove Device from Measuring Point"
                          icon="delete"
                          onClick={() => {
                            onDateDelete();
                          }}
                        />
                      </Stack>
                    </Box>
                    <Box>
                      <Checkbox
                        isChecked={inputData ? inputData.active === 0 : false}
                        onChange={(e: any) => {
                          setInputData({
                            ...inputData,
                            active: e.target.checked ? 0 : 1,
                          });
                        }}
                      >
                        {t("Projekt archiviert")}
                      </Checkbox>
                    </Box>
                    <Box>
                      <Checkbox
                        isChecked={inputData ? inputData.timeoutActive === 1 : false}
                        onChange={(e: any) => {
                          setInputData({
                            ...inputData,
                            timeoutActive: e.target.checked ? 1 : 0,
                          });
                        }}
                      >
                        {t("Timeout aktiv")}
                      </Checkbox>
                    </Box>
                  </Stack>
                </FormControl>
              </ModalBody>
              <ModalFooter>
                <Stack w="full" spacing={2}>
                  {errors.dateOrder ? (
                    <Stack w="full" isInline justify="space-around">
                      <Text color="red.500" fontWeight="bold">
                        {t("Das Enddatum liegt vor dem Startdatum")}
                      </Text>
                    </Stack>
                  ) : null}
                  <Stack isInline justify="space-between" align="center">
                    <Stack isInline spacing={3}>
                      <Button mr={3} variant="outline" onClick={onClose}>
                        {t("Abbrechen")}
                      </Button>
                      <Button
                        variantColor="green"
                        onClick={() => {
                          if (!checkDateValidity()) {
                            submit(inputData, () => {
                              setSuccess(true);
                              //@ts-ignore
                              setInputData(initInputData(undefined));
                              setTimeout(() => {
                                onClose();
                                setSuccess(false);
                              }, 1000);
                            });
                          }
                        }}
                      >
                        OK
                      </Button>
                    </Stack>
                  </Stack>
                </Stack>
              </ModalFooter>
            </>
          )}
        </ModalContent>
      </Modal>
    </>
  );
};

export default UpsertProject;
