import { Heading, Spinner, Stack, Text, useTheme } from "@chakra-ui/core";
import React, { useEffect, useState } from "react";
import ContainerDimensions from "react-container-dimensions";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";

// import { Diagram } from "../components/Diagram";
import { PanelCard } from "../components/PanelCard";
import { TimeseriesDiagram } from "../components/TimeseriesDiagram";
import { listEventDataDetails } from "../graphql/queries";
import { Layout } from "../Layout";
import { MeasuringPointDevice, Peaks } from "../types";
// import { Amps } from "../types";
import { dateToString, dbTimeToDate, transformEventDetailsToPeaks } from "../utils";
import { getEventMeta } from "../utils/events";
// import { transformEventDetailsToAmps } from "../utils";
import { fetchEverything } from "../utils/fetching";

type PageParams = {
  deviceId: string;
  starttimestamp: string;
};

const EventDetailScreen = () => {
  const { colors } = useTheme();
  const { deviceId, starttimestamp } = useParams<PageParams>();
  const [eventDetailsSpeeds, setEventDetailsSpeeds] = useState<Peaks | null>(null);
  const [mpd, setMpd] = useState<MeasuringPointDevice>();
  const { t } = useTranslation();
  // const [eventDetailsAmps, setEventDetailsAmps] = useState<Amps | null>(null);
  const [loadingSpeeds, setLoadingSpeeds] = useState<boolean>(false);
  // const [loadingAmps, setLoadingAmps] = useState<boolean>(false);

  const readData = async () => {
    if (!deviceId || !starttimestamp) {
      return;
    }

    setLoadingSpeeds(true);
    // setLoadingAmps(true);

    const eventDetailsData = await fetchEverything(listEventDataDetails, "listEventDataDetails", {
      deviceId,
      filter: { starttimestamp: { eq: starttimestamp } },
      sortDirection: "ASC",
    });

    setEventDetailsSpeeds(transformEventDetailsToPeaks(eventDetailsData));
    setLoadingSpeeds(false);

    // setEventDetailsAmps(transformEventDetailsToAmps(eventDetailsData));
    // setLoadingAmps(false);
  };

  useEffect(() => {
    readData();
    (async () => {
      // @ts-ignore
      setMpd(await getEventMeta(deviceId, starttimestamp));
    })();
  }, []);

  return (
    <Layout
      title={
        <Stack spacing={2}>
          <Heading size="lg">Event Details</Heading>
          <Heading size="sm" color="grey">
            {}
          </Heading>
          {mpd ? (
            <Heading size="sm" color="grey">
              {mpd?.MeasuringPoint.project?.name} / {mpd?.MeasuringPoint.name}
            </Heading>
          ) : (
            <Spinner size="xs" />
          )}
          <Heading size="sm" color="grey">
            {dateToString(dbTimeToDate(starttimestamp)) + " Uhr"}
          </Heading>
          <Heading size="sm" color="grey">
            {deviceId}
          </Heading>
        </Stack>
      }
    >
      <PanelCard>
        <Stack spacing={4}>
          <Heading size="md">{t("Geschwindigkeit")}</Heading>
          {loadingSpeeds ? (
            <Spinner />
          ) : (
            <Stack spacing={8} align="center" justify="space-between">
              {eventDetailsSpeeds?.time &&
              eventDetailsSpeeds?.vX &&
              eventDetailsSpeeds?.vY &&
              eventDetailsSpeeds?.vZ ? (
                <ContainerDimensions>
                  {({ width }) => (
                    <>
                      <TimeseriesDiagram
                        data={[eventDetailsSpeeds?.time, eventDetailsSpeeds?.vX]}
                        label="X [mm/s]"
                        color={colors.red[500]}
                        width={width}
                      ></TimeseriesDiagram>
                      <TimeseriesDiagram
                        data={[eventDetailsSpeeds?.time, eventDetailsSpeeds?.vY]}
                        label="Y [mm/s]"
                        color={colors.green[500]}
                        width={width}
                      ></TimeseriesDiagram>
                      <TimeseriesDiagram
                        data={[eventDetailsSpeeds?.time, eventDetailsSpeeds?.vZ]}
                        label="Z [mm/s]"
                        color={colors.blue[500]}
                        width={width}
                      ></TimeseriesDiagram>
                    </>
                  )}
                </ContainerDimensions>
              ) : (
                <Text>{t("Keine Daten vorhanden")}.</Text>
              )}
            </Stack>
          )}
        </Stack>
      </PanelCard>
      {/* <PanelCard>
        <Stack spacing={4}>
          <Heading size="md">Amplitudenspektrum</Heading>
          {loadingAmps ? (
            <Spinner />
          ) : eventDetailsAmps?.aX && eventDetailsAmps?.aY && eventDetailsAmps?.aZ ? (
            <ContainerDimensions>
              {({ width }) => (
                <>
                  <Diagram
                    data={[
                      eventDetailsAmps?.aX.frequency.frequency,
                      eventDetailsAmps?.aX.frequency.amplitude,
                    ]}
                    xLabel="Frequenz [Hz]"
                    yLabel="X [mm/s]"
                    color={colors.red[500]}
                    width={width}
                  ></Diagram>
                  <Diagram
                    data={[
                      eventDetailsAmps?.aY.frequency.frequency,
                      eventDetailsAmps?.aY.frequency.amplitude,
                    ]}
                    xLabel="Frequenz [Hz]"
                    yLabel="Y [mm/s]"
                    color={colors.green[500]}
                    width={width}
                  ></Diagram>
                  <Diagram
                    data={[
                      eventDetailsAmps?.aZ.frequency.frequency,
                      eventDetailsAmps?.aZ.frequency.amplitude,
                    ]}
                    xLabel="Frequenz [Hz]"
                    yLabel="Z [mm/s]"
                    color={colors.blue[500]}
                    width={width}
                  ></Diagram>
                </>
              )}
            </ContainerDimensions>
          ) : (
            <Text textAlign="center">Keine Daten vorhanden.</Text>
          )}
        </Stack>
      </PanelCard> */}
    </Layout>
  );
};

export default EventDetailScreen;
