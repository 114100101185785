import { theme } from "@chakra-ui/core";

export default {
  ...theme,
  colors: {
    ...theme.colors,
    primary: "blue",
    secondary: "tomato",
    success: "green",
    danger: "tomato",
    iseconRed: "#3c4858",

    status: {
      default: "#d3d3d3",
      ok: "green",
      warning: "orange",
      error: "red",
    },
  },
};
