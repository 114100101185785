import { subDays } from "date-fns";
import create from "zustand";
import { BackgroundData, DateFilter, EventData, MeasuringPoint, Peaks } from "../types";
import { readData, readMp } from "../utils/mps";

interface MpState extends Record<string, unknown> {
  mp: MeasuringPoint | null;
  peaks: Peaks | null;
  events: EventData[];
  bgDatas: BackgroundData[] | null;
  bgLoading: boolean;
  eventLoading: boolean;
  dateFilter: DateFilter;

  setMp: (mp: MeasuringPoint | null) => void;
  setPeaks: (peaks: Peaks | null) => void;
  setEvents: (events: EventData[]) => void;
  setBgDatas: (bgDatas: BackgroundData[] | null) => void;
  setBgLoading: (bgLoading: boolean) => void;
  setEventLoading: (eventLoading: boolean) => void;
  setDateFilter: (dateFilter: DateFilter) => void;

  loadMp: (mpId: string) => void;
  loadData: () => void;
}

const initialState = {
  mp: null,
  peaks: null,
  bgLoading: false,
  eventLoading: false,
  bgDatas: [],
  events: [],
  dateFilter: {
    earliest: subDays(new Date(), 7),
    latest: undefined,
  },
};

const loadMp = async (mpId: string, set: any, get: any) => {
  if (mpId === get().mp?.id) {
    return;
  }
  const mp = await readMp(mpId);
  set({ mp });
};

const loadData = async (set: any, get: any) => {
  set({ bgLoading: true, eventLoading: true });
  const mp = get().mp;
  if (!mp) {
    return;
  }
  const { peaks, bgDatas, events } = await readData(mp, get().dateFilter);
  set({ peaks, bgDatas, events, bgLoading: false, eventLoading: false });
};

export const useMpStore = create<MpState>((set, get) => ({
  ...initialState,
  setMp: (mp) => set({ mp }),
  setPeaks: (peaks) => set({ peaks }),
  setEvents: (events) => set({ events }),
  setBgDatas: (bgDatas) => set({ bgDatas }),
  setBgLoading: (bgLoading) => set({ bgLoading }),
  setEventLoading: (eventLoading) => set({ eventLoading }),
  setDateFilter: (dateFilter) => set({ dateFilter }),
  loadMp: async (mpId) => {
    await loadMp(mpId, set, get);
  },
  loadData: async () => {
    await loadData(set, get);
  },
}));

useMpStore.subscribe(
  () => {
    loadData(useMpStore.setState, useMpStore.getState);
  },
  (state) => state.dateFilter
);

useMpStore.subscribe(
  () => {
    loadData(useMpStore.setState, useMpStore.getState);
  },
  (state) => state.mp
);
