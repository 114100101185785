/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "eu-central-1",
    "aws_cognito_identity_pool_id": "eu-central-1:bc3ad5cb-88b8-4de4-9632-90b5d1ea473f",
    "aws_cognito_region": "eu-central-1",
    "aws_user_pools_id": "eu-central-1_pLdXgbYJ4",
    "aws_user_pools_web_client_id": "2n45b263ugh7qh2lgu9opq7rul",
    "oauth": {},
    "aws_cognito_login_mechanisms": [
        "EMAIL"
    ],
    "aws_cognito_signup_attributes": [
        "EMAIL"
    ],
    "aws_cognito_mfa_configuration": "OFF",
    "aws_cognito_mfa_types": [
        "SMS"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": []
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ],
    "aws_appsync_graphqlEndpoint": "https://2tm4ft5dpbhhditvkpj4awhezy.appsync-api.eu-central-1.amazonaws.com/graphql",
    "aws_appsync_region": "eu-central-1",
    "aws_appsync_authenticationType": "AMAZON_COGNITO_USER_POOLS",
    "aws_content_delivery_bucket": "isecon-20200812131124-hostingbucket-master",
    "aws_content_delivery_bucket_region": "eu-central-1",
    "aws_content_delivery_url": "http://isecon-20200812131124-hostingbucket-master.s3-website.eu-central-1.amazonaws.com"
};


export default awsmobile;
