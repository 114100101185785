import {
  Button,
  FormControl,
  FormLabel,
  Icon,
  IconButton,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Spinner,
  Stack,
  Text,
  useDisclosure,
} from "@chakra-ui/core";
import { API, graphqlOperation } from "aws-amplify";
import _, { toInteger } from "lodash";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import {
  CreateMeasuringPointDeviceInput,
  DeleteMeasuringPointDeviceInput,
  UpdateMeasuringPointDeviceInput,
  UpdateMeasuringPointInput,
} from "../../API";
import { shortlistMeasuringPointDevices } from "../../graphql/customQueries";
import {
  createMeasuringPointDevice,
  deleteMeasuringPointDevice,
  updateMeasuringPoint,
  updateMeasuringPointDevice,
} from "../../graphql/mutations";
import { listDevices, listMeasuringPointDevices } from "../../graphql/queries";
import { Changes, Device, MeasuringPointDevice } from "../../types";
import {
  dateToDbTime,
  dbTimeToDate,
  getActiveDevices,
  hasAssignedDevice,
  hasOverlap,
  hasWrongOrder,
} from "../../utils";
import { fetchEverything } from "../../utils";
import MPDLine from "./MPDLine";
import MPSettingsLine from "./MPSettingsLine";
import { NewMPDPopover } from "./NewMPDPopover";

interface UpdateMeasuringPointProps {
  measuringPoint: any;
}

const UpdateMeasuringPoint = ({ measuringPoint }: UpdateMeasuringPointProps) => {
  const { t } = useTranslation();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [loading, setLoading] = useState(true);
  const [availableDevices, setAvailableDevices] = useState([]);
  const [assignedMPDs, setAssignedMPDs] = useState<MeasuringPointDevice[]>([]);
  const [applying, setApplying] = useState<boolean>(false);
  const [activeMPDs, setActiveMPDs] = useState<Array<MeasuringPointDevice>>([]);
  const [changes, setChanges] = useState<Changes>({});
  const [overlap, setOverlap] = useState<boolean>(false);
  const [wrongOrder, setWrongOrder] = useState<boolean>(false);
  const [noReadyDate, setNoReadyDate] = useState<boolean>(false);

  useEffect(() => {
    // const existingKeys = Object.keys(changes?.measuringPointDevices || {});
    // const rangesExisting: MPDRange[] =
    //   existingKeys.length > 0
    //     ? existingKeys.map((key) => {
    //         return {
    //           startDate: changes.measuringPointDevices?.[key]?.startDate
    //             ? // @ts-ignore
    //               dbTimeToDate(changes.measuringPointDevices[key].startDate)
    //             : null,
    //           endDate: changes?.measuringPointDevices?.[key]?.endDate
    //             ? // @ts-ignore
    //               dbTimeToDate(changes?.measuringPointDevices[key]?.endDate)
    //             : null,
    //         };
    //       })
    //     : [];
    // const addingKeys = Object.keys(changes?.addMeasuringPointDevices || {});
    // const rangesAdding: MPDRange[] =
    //   addingKeys.length > 0
    //     ? addingKeys.map((key) => {
    //         return {
    //           startDate: changes.addMeasuringPointDevices?.[key]?.startDate
    //             ? // @ts-ignore
    //               dbTimeToDate(changes.addMeasuringPointDevices[key].startDate)
    //             : null,
    //           endDate: changes?.addMeasuringPointDevices?.[key]?.endDate
    //             ? // @ts-ignore
    //               dbTimeToDate(changes?.addMeasuringPointDevices[key]?.endDate)
    //             : null,
    //         };
    //       })
    //     : [];
    const assignedMPDRanges = assignedMPDs.map((mpd) => {
      return {
        startDate: mpd.startDate ? dbTimeToDate(mpd.startDate) : null,
        endDate: mpd.endDate ? dbTimeToDate(mpd.endDate) : null,
      };
    });
    setOverlap(hasOverlap(assignedMPDRanges));
    setWrongOrder(hasWrongOrder(assignedMPDRanges));

    if (measuringPoint.deviceReady && measuringPoint.deviceReadyTime == null) {
      setNoReadyDate(true);
    }
  }, [assignedMPDs]);

  const readAvailableDevices = async () => {
    setLoading(true);

    const allMeasuringPointDevices = await fetchEverything(
      shortlistMeasuringPointDevices,
      "listMeasuringPointDevices",
      {}
    );

    const allDevices = await fetchEverything(listDevices, "listDevices", {});
    let available = [];
    const assigned: any = [];

    // This has high complexity and can be optimized for large datasets.
    if (allMeasuringPointDevices.length === 0) {
      available = allDevices;
    } else {
      allDevices.forEach((device: Device) => {
        let keep = true;
        allMeasuringPointDevices.forEach((mpd: MeasuringPointDevice) => {
          const isCurrentMPD = device.id === mpd.device.id;
          if (isCurrentMPD) {
            if (hasAssignedDevice(mpd)) {
              keep = false;
            }
          }
          if (mpd.MeasuringPoint.id === measuringPoint.id) {
            assigned.push(mpd);
          }
        });
        if (keep) {
          available.push(device);
        }
      });
    }
    setAvailableDevices(available);
    const uniqueAssigned = _.uniqWith(assigned, _.isEqual);
    setAssignedMPDs(uniqueAssigned);
    setActiveMPDs(getActiveDevices(uniqueAssigned));
    setLoading(false);
  };

  useEffect(() => {
    const mergedAssignedChanged: MeasuringPointDevice[] = [];
    assignedMPDs.forEach((original: MeasuringPointDevice) => {
      if (changes.measuringPointDevices && changes.measuringPointDevices[original.id]) {
        const changedMPD = changes.measuringPointDevices[original.id];
        const merged = _.merge({}, original, changedMPD);
        mergedAssignedChanged.push(merged);
      } else {
        mergedAssignedChanged.push(original);
      }
    });
    setActiveMPDs(getActiveDevices(mergedAssignedChanged));
  }, [changes]);

  const getDate = (
    changes: unknown,
    path: string,
    fallback: string | null | undefined
  ): Date | null => {
    const inChanges = _.get(changes, path, fallback);
    if (typeof inChanges === "string") {
      return dbTimeToDate(inChanges);
    }
    return inChanges;
  };

  return (
    <>
      <IconButton
        variant="ghost"
        aria-label="Update Measuring Point"
        icon="settings"
        onClick={async () => {
          readAvailableDevices();
          onOpen();
        }}
      />
      <Modal
        isOpen={isOpen}
        onClose={() => {
          setChanges({});
          onClose();
        }}
        size="5xl"
      >
        <ModalOverlay />
        <ModalContent>
          <>
            <ModalHeader>Messstelle bearbeiten</ModalHeader>
            <ModalCloseButton />
            <ModalBody>
              {loading ? (
                <Stack>
                  <Spinner />
                </Stack>
              ) : (
                <Stack spacing={3}>
                  <FormControl>
                    <FormLabel htmlFor="email">Name der Messstelle</FormLabel>
                    <Input
                      placeholder="Name eingeben"
                      onChange={(e: any) => {
                        setChanges(
                          _.merge({}, changes, {
                            measuringPoint: { id: measuringPoint.id, name: e.target.value },
                          })
                        );
                      }}
                      aria-describedby="Name der Messstelle"
                      value={
                        changes.measuringPoint?.name || changes.measuringPoint?.name === ""
                          ? changes.measuringPoint.name
                          : measuringPoint.name
                      }
                    />
                  </FormControl>

                  <MPSettingsLine
                    deviceCase={
                      changes.measuringPoint?.deviceCase != null
                        ? changes.measuringPoint.deviceCase
                        : measuringPoint.deviceCase
                    }
                    onDeviceCaseChange={(deviceCase) => {
                      setChanges(
                        _.merge({}, changes, {
                          measuringPoint: {
                            id: measuringPoint.id,
                            deviceCase: deviceCase,
                          },
                        })
                      );
                    }}
                    deviceReady={
                      changes.measuringPoint?.deviceReady != null
                        ? changes.measuringPoint.deviceReady
                        : measuringPoint.deviceReady
                    }
                    onDeviceReadyChange={(deviceReady) => {
                      const update = {
                        measuringPoint: {
                          id: measuringPoint.id,
                          deviceReady: deviceReady,
                          deviceReadyTime: getDate(
                            changes,
                            `measuringPoint.deviceReadyTime`,
                            measuringPoint.deviceReadyTime
                          ),
                        },
                      };

                      if (deviceReady === false) {
                        update.measuringPoint.deviceReadyTime = null;
                        setNoReadyDate(false);
                      }
                      setChanges(_.merge({}, changes, update));
                    }}
                    deviceReadyTime={getDate(
                      changes,
                      `measuringPoint.deviceReadyTime`,
                      measuringPoint.deviceReadyTime
                    )}
                    onDeviceReadyTimeChange={(date) => {
                      if (date) {
                        setNoReadyDate(false);
                      }
                      setChanges(
                        _.merge({}, changes, {
                          measuringPoint: {
                            id: measuringPoint.id,
                            deviceReadyTime: date ? dateToDbTime(date) : null,
                          },
                        })
                      );
                    }}
                  />

                  <Stack spacing={3} py={4}>
                    {assignedMPDs
                      .filter((mpd: MeasuringPointDevice) => {
                        if (changes.removeMeasuringPointDevices) {
                          return !changes.removeMeasuringPointDevices.includes(mpd.id);
                        }
                        return true;
                        // @ts-ignore
                      })
                      .sort(
                        (a: MeasuringPointDevice, b: MeasuringPointDevice) =>
                          toInteger(a.endDate) - toInteger(b.endDate)
                      )
                      .map((mpd: MeasuringPointDevice) => {
                        return false ? null : (
                          <MPDLine
                            key={mpd.id}
                            name={mpd.device.name}
                            isActive={
                              _.filter(activeMPDs, (activeMPD) => activeMPD.id === mpd.id).length >
                              0
                            }
                            onStartChange={(date) => {
                              setChanges(
                                _.merge({}, changes, {
                                  measuringPointDevices: {
                                    [mpd.id]: { startDate: date ? dateToDbTime(date) : null },
                                  },
                                })
                              );
                            }}
                            onEndChange={(date) => {
                              setChanges(
                                _.merge({}, changes, {
                                  measuringPointDevices: {
                                    [mpd.id]: { endDate: date ? dateToDbTime(date) : null },
                                  },
                                })
                              );
                            }}
                            onDelete={() => {
                              const removeMeasuringPointDevices = changes.removeMeasuringPointDevices
                                ? Array.from(
                                    new Set([...changes.removeMeasuringPointDevices, mpd.id])
                                  )
                                : [mpd.id];
                              setChanges(_.merge({}, changes, { removeMeasuringPointDevices }));
                            }}
                            startDate={getDate(
                              changes,
                              `measuringPointDevices.${mpd.id}.startDate`,
                              mpd.startDate
                            )}
                            endDate={getDate(
                              changes,
                              `measuringPointDevices.${mpd.id}.endDate`,
                              mpd.endDate
                            )}
                          />
                        );
                      })}
                    {changes.addMeasuringPointDevices
                      ? Object.keys(changes.addMeasuringPointDevices).map((deviceId: string) => {
                          // @ts-ignore
                          const mpd = changes.addMeasuringPointDevices[deviceId];
                          return (
                            <MPDLine
                              key={mpd.id}
                              name={mpd.device.name}
                              isActive={
                                _.filter(activeMPDs, (activeMPD) => activeMPD.id === mpd.id)
                                  .length > 0
                              }
                              startDate={mpd.startDate ? dbTimeToDate(mpd.startDate) : null}
                              endDate={mpd.endDate ? dbTimeToDate(mpd.endDate) : null}
                              onStartChange={(date) => {
                                const update = {
                                  addMeasuringPointDevices: {
                                    [deviceId]: { startDate: date ? dateToDbTime(date) : null },
                                  },
                                };
                                setChanges(_.merge({}, changes, update));
                              }}
                              onEndChange={(date) => {
                                const update = {
                                  addMeasuringPointDevices: {
                                    [deviceId]: { endDate: date ? dateToDbTime(date) : null },
                                  },
                                };
                                setChanges(_.merge({}, changes, update));
                              }}
                              onDelete={() => {
                                setChanges(_.omit(changes, `addMeasuringPointDevices.${deviceId}`));
                              }}
                            />
                          );
                        })
                      : null}
                  </Stack>
                </Stack>
              )}
            </ModalBody>
            <ModalFooter>
              <Stack w="full" spacing={2}>
                {wrongOrder || overlap || noReadyDate ? (
                  <Stack w="full" isInline justify="space-around">
                    <Icon name="warning-2" color="red.500" fontSize="3xl" />
                  </Stack>
                ) : null}
                {wrongOrder ? (
                  <Stack w="full" isInline justify="space-around">
                    <Text color="red.500" fontWeight="bold">
                      Mindestens ein Startdatum liegt vor dem Enddatum.
                    </Text>
                  </Stack>
                ) : null}
                {overlap ? (
                  <Stack w="full" isInline justify="space-around">
                    <Text color="red.500" fontWeight="bold">
                      Mindestens zwei Zuweisungszeiträume überschneiden sich.
                    </Text>
                  </Stack>
                ) : null}
                {noReadyDate ? (
                  <Stack w="full" isInline justify="space-around">
                    <Text color="red.500" fontWeight="bold">
                      Das Freigabedatum ist nicht angegeben.
                    </Text>
                  </Stack>
                ) : null}
                {activeMPDs.length > 1 ? (
                  <Stack w="full" isInline justify="space-around">
                    <Text color="red.500" fontWeight="bold">
                      Es ist mehr als ein Gerät aktiv!
                    </Text>
                  </Stack>
                ) : null}
                <Stack isInline justify="space-between" align="center">
                  <NewMPDPopover
                    availableDevices={availableDevices}
                    changes={changes}
                    setChanges={setChanges}
                  />
                  <Stack isInline spacing={3}>
                    <Button
                      onClick={() => {
                        setChanges({});
                        if (Object.keys(changes).length === 0) {
                          onClose();
                        }
                      }}
                    >
                      {Object.keys(changes).length > 0 ? "Änderungen verwerfen" : "schließen"}
                    </Button>
                    <Button
                      variantColor="green"
                      isLoading={applying}
                      isDisabled={_.size(changes) === 0}
                      onClick={async () => {
                        setApplying(true);

                        // Apply selected MPD removals.
                        if (changes.removeMeasuringPointDevices) {
                          await Promise.all(
                            changes.removeMeasuringPointDevices.map(async (mpdId: string) => {
                              const input: DeleteMeasuringPointDeviceInput = { id: mpdId };
                              await API.graphql(
                                graphqlOperation(deleteMeasuringPointDevice, {
                                  input,
                                })
                              );
                            })
                          );
                        }

                        // Apply selected MPD updates.
                        if (changes.measuringPointDevices) {
                          await Promise.all(
                            Object.keys(changes.measuringPointDevices)
                              // Don't update removed MPDs.
                              .filter(
                                (mpdId) => !changes.removeMeasuringPointDevices?.includes(mpdId)
                              )
                              .map(async (mpdId: string) => {
                                // @ts-ignore
                                const mpd = changes.measuringPointDevices[mpdId];
                                const input: UpdateMeasuringPointDeviceInput = { id: mpdId };
                                if (mpd.startDate) input.startDate = mpd.startDate;
                                input.endDate = mpd.endDate;
                                await API.graphql(
                                  graphqlOperation(updateMeasuringPointDevice, { input })
                                );
                              })
                          );
                        }

                        // Apply selected MPD adds.
                        if (changes.addMeasuringPointDevices) {
                          await Promise.all(
                            Object.keys(changes.addMeasuringPointDevices).map(
                              async (deviceId: string) => {
                                // @ts-ignore
                                const mpd = changes.addMeasuringPointDevices[deviceId];
                                const input: CreateMeasuringPointDeviceInput = {
                                  measuringPoint: measuringPoint.id,
                                  measuringPointDeviceMeasuringPointId: measuringPoint.id,
                                  measuringPointDeviceDeviceId: deviceId,
                                  deviceID: deviceId,
                                };
                                if (mpd.startDate) input.startDate = mpd.startDate;
                                if (mpd.endDate) input.endDate = mpd.endDate;
                                await API.graphql(
                                  graphqlOperation(createMeasuringPointDevice, { input })
                                );
                              }
                            )
                          );
                        }

                        // Apply selected MP name change.
                        if (changes.measuringPoint) {
                          const input: UpdateMeasuringPointInput = {
                            id: changes.measuringPoint.id,
                          };

                          if (changes.measuringPoint.name) input.name = changes.measuringPoint.name;

                          /**
                           * specifies if device case is on site
                           */
                          if (changes.measuringPoint.deviceCase != null)
                            input.deviceCase = changes.measuringPoint.deviceCase;

                          /**
                           * specifies if device is ready to be picked up
                           */
                          if (changes.measuringPoint.deviceReady != null)
                            input.deviceReady = changes.measuringPoint.deviceReady;

                          /**
                           * specifies time when/since device is ready to be picked up
                           */
                          input.deviceReadyTime = changes.measuringPoint.deviceReadyTime;

                          await API.graphql(graphqlOperation(updateMeasuringPoint, { input }));
                        }

                        // TODO: Make loading smoother.
                        await readAvailableDevices();
                        setChanges({});
                        setApplying(false);
                      }}
                    >
                      {t("speichern")}
                    </Button>
                  </Stack>
                </Stack>
              </Stack>
            </ModalFooter>
          </>
        </ModalContent>
      </Modal>
    </>
  );
};

export default UpdateMeasuringPoint;
