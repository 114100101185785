import {
  Button,
  FormControl,
  FormLabel,
  IconButton,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Spinner,
  Stack,
  Text,
  useDisclosure,
} from "@chakra-ui/core";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import { Device } from "../types";
import { checkDeviceAssigned, deleteDevice } from "../utils/delete";

interface DeleteDeviceProps {
  device: Device;
}

const DeleteDevice = ({ device }: DeleteDeviceProps) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [applying, setApplying] = useState<boolean>(false);
  const [approved, setApproved] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string>("");
  const { t } = useTranslation();
  useEffect(() => {
    if (!isOpen) {
      return;
    }
    (async () => {
      setLoading(true);
      // Check if device is assigned to any measuringpoint.
      const assigned = await checkDeviceAssigned(device.id);
      if (assigned) {
        setError(
          `${t("Das Gerät kann nicht gelöscht werden, wenn es noch Messstellen zugeordnet ist.")}`
        );
      }
      setLoading(false);
    })();
  }, [isOpen]);

  return (
    <>
      <IconButton
        variant="ghost"
        aria-label="Update Measuring Point"
        icon="delete"
        onClick={async () => {
          onOpen();
        }}
      />
      <Modal
        isOpen={isOpen}
        onClose={() => {
          onClose();
        }}
        size="5xl"
      >
        <ModalOverlay />
        <ModalContent>
          <>
            <ModalHeader>{`${t("Gerät löschen")}: ${device.name} `}</ModalHeader>
            <ModalCloseButton />
            <ModalBody>
              <Stack spacing={3}>
                <FormControl>
                  <FormLabel htmlFor="email">
                    {`${"Um diese Aktion zu bestätigen, tippen Sie bitte 'entfernen' in das Eingabefeld und klicken Sie auf bestätigen."}`}
                  </FormLabel>
                  <Input
                    placeholder="entfernen"
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                      if (e.target.value === "entfernen") {
                        setApproved(true);
                      } else {
                        setApproved(false);
                      }
                    }}
                  />
                </FormControl>
              </Stack>
            </ModalBody>
            <ModalFooter>
              <Stack spacing={4}>
                {loading ? (
                  <Text>{t("Überprüfe ob Gerät einer Messstelle zugewiesen ist")} ...</Text>
                ) : null}
                {error ? <Text color="red.500">{error}</Text> : null}
                <Stack w="full" spacing={4} isInline justifyContent="flex-end">
                  <Button
                    onClick={() => {
                      onClose();
                    }}
                  >
                    {t("abbrechen")}
                  </Button>
                  <Button
                    minW={32}
                    variantColor="red"
                    isDisabled={!approved || loading || error.length > 0}
                    onClick={() => {
                      (async () => {
                        setApplying(true);
                        await deleteDevice(device.id);
                        setApplying(false);
                        onClose();
                      })();
                    }}
                  >
                    {applying ? <Spinner size="sm" /> : "bestätigen"}
                  </Button>
                </Stack>
              </Stack>
            </ModalFooter>
          </>
        </ModalContent>
      </Modal>
    </>
  );
};

export default DeleteDevice;
