import {
  Button,
  IconButton,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverCloseButton,
  PopoverContent,
  PopoverHeader,
  PopoverTrigger,
  Stack,
  Text,
} from "@chakra-ui/core";
import { subDays } from "date-fns";
import React, { FunctionComponent, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import { DateFilter } from "../types";
import { Datepicker } from "./Datepicker";
interface EventFilterProps {
  filter: DateFilter;
  setFilter: (filter: DateFilter) => void;
}

export const EventFilter: FunctionComponent<EventFilterProps> = ({ setFilter, filter }) => {
  const { t } = useTranslation();
  const [startAfterEnd, setStartAfterEnd] = useState<boolean>(false);
  const [tmpFilter, setTmpFilter] = useState<DateFilter>({});
  const [isOpen, setIsOpen] = useState<boolean>(false);

  useEffect(() => {
    setTmpFilter(filter);
  }, [filter]);

  return (
    <Popover
      placement="bottom-end"
      isOpen={isOpen}
      onOpen={() => {
        setIsOpen(true);
      }}
      onClose={() => {
        setIsOpen(false);
      }}
    >
      <PopoverTrigger>
        <IconButton aria-label="Events filtern" icon="time" />
      </PopoverTrigger>
      <PopoverContent zIndex={4}>
        <PopoverArrow />
        <PopoverCloseButton />
        <PopoverHeader>Events und Messwerte filtern</PopoverHeader>
        <PopoverBody>
          <Stack>
            <Stack spacing={3}>
              <Datepicker
                date={tmpFilter.earliest}
                setDate={(date) => {
                  setTmpFilter({ ...tmpFilter, earliest: date });
                }}
                placeholder="Startzeit"
              />
              <Datepicker
                date={tmpFilter.latest}
                setDate={(date) => {
                  setTmpFilter({ ...tmpFilter, latest: date });
                }}
                placeholder="Endzeit"
              />
            </Stack>
            <Stack align="center">
              <Button
                w="full"
                variant="outline"
                onClick={() => {
                  setTmpFilter({ earliest: subDays(new Date(), 7), latest: undefined });
                }}
              >
                letzten 7 Tage
              </Button>
              <Button
                w="full"
                variant="outline"
                onClick={() => {
                  setTmpFilter({ earliest: undefined, latest: undefined });
                  // setFilter({});
                }}
              >
                alles
              </Button>
              <Button
                w="full"
                variant="solid"
                variantColor="green"
                onClick={() => {
                  const invalid =
                    (tmpFilter.earliest &&
                      tmpFilter.latest &&
                      tmpFilter.earliest > tmpFilter.latest) ||
                    false;
                  setStartAfterEnd(invalid);
                  if (invalid) {
                    return;
                  }
                  setFilter(tmpFilter);
                  setIsOpen(false);
                }}
              >
                anwenden
              </Button>
              {startAfterEnd ? (
                <Text color="danger">Startzeit muss vor Endzeit liegen.</Text>
              ) : null}
            </Stack>
          </Stack>
        </PopoverBody>
      </PopoverContent>
    </Popover>
  );
};
