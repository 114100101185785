import React, { useEffect, useState } from "react";
import {tokenizePath, resolveValue} from 'path-value';
import { Icon, Link, Spinner, Stack } from "@chakra-ui/core";
import DataTable from "react-data-table-component";
import {dateToHuman, dbTimeToDate, fetchEverything} from "../../utils";
import {Text} from "@chakra-ui/core";
import { useHistory } from "react-router-dom";
import { useProjectStore } from "../../store/project-store";
import { deviceDismantling } from "../../graphql/customQueries";
const DismantlingTable = () => {
  const history = useHistory();
  const { expandProject, setProjectFilter } = useProjectStore();
  const [measuringPoints, setMeasuringPoints] = useState([])
  const [loading, setLoading] = useState(true)

  const fetchMeasuringPoints = async () => {
    const projects = await fetchEverything(deviceDismantling, "listProjects", {})

    let temp:any = [] ;

    // @ts-ignore
    projects.forEach(item => {
      if( item.measuringPoints.items?.length > 0){
        temp.push(...item.measuringPoints.items)
      }

    })
    // @ts-ignore
    setMeasuringPoints(temp ? temp : [])
    setLoading(false)
  }
  useEffect(() => {
    fetchMeasuringPoints()
  }, [])


  const columns = [
    {
      name: "Projekt",
      selector: "Project.name",
      cell: (row:any) => {
        return row.Project?.id ? (
          <Link
            src="/"
            onClick={(e: React.MouseEvent<HTMLAnchorElement>) => {
              e.preventDefault();
              expandProject(row.Project.id, true);
              setProjectFilter({ active: undefined });
              history.push("/");
            }}
          >
            <Stack isInline align="center">
              <Icon name="external-link" />
              <Text>{row.Project.name}</Text>
            </Stack>
          </Link>
        ) : "Keinem Projekt zugeordnet";
      },
      sortable: true,
    },
    {
      name: "Messstelle",
      selector:"name",
      sortable: true,
    },
    {
      name: "Freimeldungsdatum",
      selector:"deviceReadyTime",
      sortable: true,
      cell: (row: any) => {
        return row.deviceReadyTime ? <Text>{dateToHuman(dbTimeToDate(row.deviceReadyTime))}</Text>: <Text>Kein Datum angegeben</Text>
      }
    },
    {
      name: "Kofferstatus",
      selector:"deviceCase",
      sortable: true,
      cell: (row: any) => {
        return row.deviceCase ? <Text>Koffer vor Ort</Text> : <Text>Kein Koffer vor Ort</Text>
      }
    },
    {
      name: "Projektleiter",
      selector:"Project.projectOwner",
      sortable: true,
      cell: (row:any) => {
        return row.Project?.projectOwner ? <Text>{row.Project.projectOwner}</Text> : <Text>Kein Projektleiter angegeben</Text>
      }
    },

  ]


  if(loading){
    return <Spinner mx={"auto"}/>
  }
  if(measuringPoints.length > 0) {
    return <DataTable pagination={true} columns={columns} data={measuringPoints} sortFunction={(rows, field, sortDirection) => {

      if(field === "name" || field === "Project.name" || field === "Project.projectOwner"){
        rows.sort((a,b )=> {
          let path = tokenizePath(field)
          let locA = resolveValue(a, path) ?? ""
          let locB = resolveValue(b, path) ?? ""
          if(sortDirection === "asc"){
            return  locB.localeCompare(locA, "de", {sensitivity: "base"})
          } else {
            return locA.localeCompare(locB, "de", {sensitivity: "base"})
          }
        })
      }

      if(field === "deviceReadyTime"){
        rows.sort((a, b) => {
          if(sortDirection === "asc"){
            // @ts-ignore
            return new Date(a[field]) - new Date(b[field])
          } else {
            // @ts-ignore
            return new Date(b[field]) - new Date(a[field])
          }
        })
      }

      if(field === "deviceCase"){
       rows.sort((a,b) => {

        let locA = a.deviceCase ?? false

         let locB = b.deviceCase ?? false

         if(sortDirection === "asc") {
           return (locA === locB) ? 0 : locA ? 1 : -1;
         }else {
           return (locA === locB) ? 0 : locA ? -1 : 1;

         }
       })
      }


      return rows.slice(0)
    }} />
  }else {
    return <p>Kein Geräte</p>
  }
};

export default DismantlingTable;