/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getProject = /* GraphQL */ `
  query GetProject($id: ID!) {
    getProject(id: $id) {
      id
      name
      active
      comment
      timeoutActive
      measuringPoints {
        items {
          id
          project
          name
          devices {
            items {
              id
              measuringPoint
              device {
                id
                MeasuringPointDevices {
                  nextToken
                }
                name
                status
                active
                lastData
                comment
                createdAt
                updatedAt
              }
              startDate
              endDate
              deviceID
              MeasuringPoint {
                id
                project
                name
                devices {
                  nextToken
                }
                deviceCase
                deviceReady
                deviceReadyTime
                Project {
                  id
                  name
                  active
                  comment
                  timeoutActive
                  projectOwner
                  plan
                  planStart
                  planEnd
                  planNumber
                  createdAt
                  updatedAt
                }
                createdAt
                updatedAt
              }
              createdAt
              updatedAt
            }
            nextToken
          }
          deviceCase
          deviceReady
          deviceReadyTime
          Project {
            id
            name
            active
            comment
            timeoutActive
            measuringPoints {
              items {
                id
                project
                name
                devices {
                  nextToken
                }
                deviceCase
                deviceReady
                deviceReadyTime
                Project {
                  id
                  name
                  active
                  comment
                  timeoutActive
                  projectOwner
                  plan
                  planStart
                  planEnd
                  planNumber
                  createdAt
                  updatedAt
                }
                createdAt
                updatedAt
              }
              nextToken
            }
            projectOwner
            plan
            planStart
            planEnd
            planNumber
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
        }
        nextToken
      }
      projectOwner
      plan
      planStart
      planEnd
      planNumber
      createdAt
      updatedAt
    }
  }
`;
export const listProjects = /* GraphQL */ `
  query ListProjects(
    $filter: ModelProjectFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listProjects(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        active
        comment
        timeoutActive
        measuringPoints {
          items {
            id
            project
            name
            devices {
              items {
                id
                measuringPoint
                device {
                  id
                  name
                  status
                  active
                  lastData
                  comment
                  createdAt
                  updatedAt
                }
                startDate
                endDate
                deviceID
                MeasuringPoint {
                  id
                  project
                  name
                  deviceCase
                  deviceReady
                  deviceReadyTime
                  createdAt
                  updatedAt
                }
                createdAt
                updatedAt
              }
              nextToken
            }
            deviceCase
            deviceReady
            deviceReadyTime
            Project {
              id
              name
              active
              comment
              timeoutActive
              measuringPoints {
                items {
                  id
                  project
                  name
                  deviceCase
                  deviceReady
                  deviceReadyTime
                  createdAt
                  updatedAt
                }
                nextToken
              }
              projectOwner
              plan
              planStart
              planEnd
              planNumber
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        projectOwner
        plan
        planStart
        planEnd
        planNumber
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getMeasuringPoint = /* GraphQL */ `
  query GetMeasuringPoint($id: ID!) {
    getMeasuringPoint(id: $id) {
      id
      project
      name
      devices {
        items {
          id
          measuringPoint
          device {
            id
            MeasuringPointDevices {
              items {
                id
                measuringPoint
                device {
                  id
                  name
                  status
                  active
                  lastData
                  comment
                  createdAt
                  updatedAt
                }
                startDate
                endDate
                deviceID
                MeasuringPoint {
                  id
                  project
                  name
                  deviceCase
                  deviceReady
                  deviceReadyTime
                  createdAt
                  updatedAt
                }
                createdAt
                updatedAt
              }
              nextToken
            }
            name
            status
            active
            lastData
            comment
            createdAt
            updatedAt
          }
          startDate
          endDate
          deviceID
          MeasuringPoint {
            id
            project
            name
            devices {
              items {
                id
                measuringPoint
                device {
                  id
                  name
                  status
                  active
                  lastData
                  comment
                  createdAt
                  updatedAt
                }
                startDate
                endDate
                deviceID
                MeasuringPoint {
                  id
                  project
                  name
                  deviceCase
                  deviceReady
                  deviceReadyTime
                  createdAt
                  updatedAt
                }
                createdAt
                updatedAt
              }
              nextToken
            }
            deviceCase
            deviceReady
            deviceReadyTime
            Project {
              id
              name
              active
              comment
              timeoutActive
              measuringPoints {
                items {
                  id
                  project
                  name
                  deviceCase
                  deviceReady
                  deviceReadyTime
                  createdAt
                  updatedAt
                }
                nextToken
              }
              projectOwner
              plan
              planStart
              planEnd
              planNumber
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
        }
        nextToken
      }
      deviceCase
      deviceReady
      deviceReadyTime
      Project {
        id
        name
        active
        comment
        timeoutActive
        measuringPoints {
          items {
            id
            project
            name
            devices {
              items {
                id
                measuringPoint
                device {
                  id
                  name
                  status
                  active
                  lastData
                  comment
                  createdAt
                  updatedAt
                }
                startDate
                endDate
                deviceID
                MeasuringPoint {
                  id
                  project
                  name
                  deviceCase
                  deviceReady
                  deviceReadyTime
                  createdAt
                  updatedAt
                }
                createdAt
                updatedAt
              }
              nextToken
            }
            deviceCase
            deviceReady
            deviceReadyTime
            Project {
              id
              name
              active
              comment
              timeoutActive
              measuringPoints {
                items {
                  id
                  project
                  name
                  deviceCase
                  deviceReady
                  deviceReadyTime
                  createdAt
                  updatedAt
                }
                nextToken
              }
              projectOwner
              plan
              planStart
              planEnd
              planNumber
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        projectOwner
        plan
        planStart
        planEnd
        planNumber
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const listMeasuringPoints = /* GraphQL */ `
  query ListMeasuringPoints(
    $filter: ModelMeasuringPointFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listMeasuringPoints(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        project
        name
        devices {
          items {
            id
            measuringPoint
            device {
              id
              MeasuringPointDevices {
                items {
                  id
                  measuringPoint
                  startDate
                  endDate
                  deviceID
                  createdAt
                  updatedAt
                }
                nextToken
              }
              name
              status
              active
              lastData
              comment
              createdAt
              updatedAt
            }
            startDate
            endDate
            deviceID
            MeasuringPoint {
              id
              project
              name
              devices {
                items {
                  id
                  measuringPoint
                  startDate
                  endDate
                  deviceID
                  createdAt
                  updatedAt
                }
                nextToken
              }
              deviceCase
              deviceReady
              deviceReadyTime
              Project {
                id
                name
                active
                comment
                timeoutActive
                measuringPoints {
                  nextToken
                }
                projectOwner
                plan
                planStart
                planEnd
                planNumber
                createdAt
                updatedAt
              }
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        deviceCase
        deviceReady
        deviceReadyTime
        Project {
          id
          name
          active
          comment
          timeoutActive
          measuringPoints {
            items {
              id
              project
              name
              devices {
                items {
                  id
                  measuringPoint
                  startDate
                  endDate
                  deviceID
                  createdAt
                  updatedAt
                }
                nextToken
              }
              deviceCase
              deviceReady
              deviceReadyTime
              Project {
                id
                name
                active
                comment
                timeoutActive
                measuringPoints {
                  nextToken
                }
                projectOwner
                plan
                planStart
                planEnd
                planNumber
                createdAt
                updatedAt
              }
              createdAt
              updatedAt
            }
            nextToken
          }
          projectOwner
          plan
          planStart
          planEnd
          planNumber
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getMeasuringPointDevice = /* GraphQL */ `
  query GetMeasuringPointDevice($id: ID!) {
    getMeasuringPointDevice(id: $id) {
      id
      measuringPoint
      device {
        id
        MeasuringPointDevices {
          items {
            id
            measuringPoint
            device {
              id
              MeasuringPointDevices {
                items {
                  id
                  measuringPoint
                  startDate
                  endDate
                  deviceID
                  createdAt
                  updatedAt
                }
                nextToken
              }
              name
              status
              active
              lastData
              comment
              createdAt
              updatedAt
            }
            startDate
            endDate
            deviceID
            MeasuringPoint {
              id
              project
              name
              devices {
                items {
                  id
                  measuringPoint
                  startDate
                  endDate
                  deviceID
                  createdAt
                  updatedAt
                }
                nextToken
              }
              deviceCase
              deviceReady
              deviceReadyTime
              Project {
                id
                name
                active
                comment
                timeoutActive
                measuringPoints {
                  nextToken
                }
                projectOwner
                plan
                planStart
                planEnd
                planNumber
                createdAt
                updatedAt
              }
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        name
        status
        active
        lastData
        comment
        createdAt
        updatedAt
      }
      startDate
      endDate
      deviceID
      MeasuringPoint {
        id
        project
        name
        devices {
          items {
            id
            measuringPoint
            device {
              id
              MeasuringPointDevices {
                items {
                  id
                  measuringPoint
                  startDate
                  endDate
                  deviceID
                  createdAt
                  updatedAt
                }
                nextToken
              }
              name
              status
              active
              lastData
              comment
              createdAt
              updatedAt
            }
            startDate
            endDate
            deviceID
            MeasuringPoint {
              id
              project
              name
              devices {
                items {
                  id
                  measuringPoint
                  startDate
                  endDate
                  deviceID
                  createdAt
                  updatedAt
                }
                nextToken
              }
              deviceCase
              deviceReady
              deviceReadyTime
              Project {
                id
                name
                active
                comment
                timeoutActive
                measuringPoints {
                  nextToken
                }
                projectOwner
                plan
                planStart
                planEnd
                planNumber
                createdAt
                updatedAt
              }
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        deviceCase
        deviceReady
        deviceReadyTime
        Project {
          id
          name
          active
          comment
          timeoutActive
          measuringPoints {
            items {
              id
              project
              name
              devices {
                items {
                  id
                  measuringPoint
                  startDate
                  endDate
                  deviceID
                  createdAt
                  updatedAt
                }
                nextToken
              }
              deviceCase
              deviceReady
              deviceReadyTime
              Project {
                id
                name
                active
                comment
                timeoutActive
                measuringPoints {
                  nextToken
                }
                projectOwner
                plan
                planStart
                planEnd
                planNumber
                createdAt
                updatedAt
              }
              createdAt
              updatedAt
            }
            nextToken
          }
          projectOwner
          plan
          planStart
          planEnd
          planNumber
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const listMeasuringPointDevices = /* GraphQL */ `
  query ListMeasuringPointDevices(
    $filter: ModelMeasuringPointDeviceFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listMeasuringPointDevices(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        measuringPoint
        device {
          id
          MeasuringPointDevices {
            items {
              id
              measuringPoint
              device {
                id
                MeasuringPointDevices {
                  nextToken
                }
                name
                status
                active
                lastData
                comment
                createdAt
                updatedAt
              }
              startDate
              endDate
              deviceID
              MeasuringPoint {
                id
                project
                name
                devices {
                  nextToken
                }
                deviceCase
                deviceReady
                deviceReadyTime
                Project {
                  id
                  name
                  active
                  comment
                  timeoutActive
                  projectOwner
                  plan
                  planStart
                  planEnd
                  planNumber
                  createdAt
                  updatedAt
                }
                createdAt
                updatedAt
              }
              createdAt
              updatedAt
            }
            nextToken
          }
          name
          status
          active
          lastData
          comment
          createdAt
          updatedAt
        }
        startDate
        endDate
        deviceID
        MeasuringPoint {
          id
          project
          name
          devices {
            items {
              id
              measuringPoint
              device {
                id
                MeasuringPointDevices {
                  nextToken
                }
                name
                status
                active
                lastData
                comment
                createdAt
                updatedAt
              }
              startDate
              endDate
              deviceID
              MeasuringPoint {
                id
                project
                name
                devices {
                  nextToken
                }
                deviceCase
                deviceReady
                deviceReadyTime
                Project {
                  id
                  name
                  active
                  comment
                  timeoutActive
                  projectOwner
                  plan
                  planStart
                  planEnd
                  planNumber
                  createdAt
                  updatedAt
                }
                createdAt
                updatedAt
              }
              createdAt
              updatedAt
            }
            nextToken
          }
          deviceCase
          deviceReady
          deviceReadyTime
          Project {
            id
            name
            active
            comment
            timeoutActive
            measuringPoints {
              items {
                id
                project
                name
                devices {
                  nextToken
                }
                deviceCase
                deviceReady
                deviceReadyTime
                Project {
                  id
                  name
                  active
                  comment
                  timeoutActive
                  projectOwner
                  plan
                  planStart
                  planEnd
                  planNumber
                  createdAt
                  updatedAt
                }
                createdAt
                updatedAt
              }
              nextToken
            }
            projectOwner
            plan
            planStart
            planEnd
            planNumber
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getDevice = /* GraphQL */ `
  query GetDevice($id: ID!) {
    getDevice(id: $id) {
      id
      MeasuringPointDevices {
        items {
          id
          measuringPoint
          device {
            id
            MeasuringPointDevices {
              items {
                id
                measuringPoint
                device {
                  id
                  name
                  status
                  active
                  lastData
                  comment
                  createdAt
                  updatedAt
                }
                startDate
                endDate
                deviceID
                MeasuringPoint {
                  id
                  project
                  name
                  deviceCase
                  deviceReady
                  deviceReadyTime
                  createdAt
                  updatedAt
                }
                createdAt
                updatedAt
              }
              nextToken
            }
            name
            status
            active
            lastData
            comment
            createdAt
            updatedAt
          }
          startDate
          endDate
          deviceID
          MeasuringPoint {
            id
            project
            name
            devices {
              items {
                id
                measuringPoint
                device {
                  id
                  name
                  status
                  active
                  lastData
                  comment
                  createdAt
                  updatedAt
                }
                startDate
                endDate
                deviceID
                MeasuringPoint {
                  id
                  project
                  name
                  deviceCase
                  deviceReady
                  deviceReadyTime
                  createdAt
                  updatedAt
                }
                createdAt
                updatedAt
              }
              nextToken
            }
            deviceCase
            deviceReady
            deviceReadyTime
            Project {
              id
              name
              active
              comment
              timeoutActive
              measuringPoints {
                items {
                  id
                  project
                  name
                  deviceCase
                  deviceReady
                  deviceReadyTime
                  createdAt
                  updatedAt
                }
                nextToken
              }
              projectOwner
              plan
              planStart
              planEnd
              planNumber
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
        }
        nextToken
      }
      name
      status
      active
      lastData
      comment
      createdAt
      updatedAt
    }
  }
`;
export const listDevices = /* GraphQL */ `
  query ListDevices(
    $filter: ModelDeviceFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listDevices(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        MeasuringPointDevices {
          items {
            id
            measuringPoint
            device {
              id
              MeasuringPointDevices {
                items {
                  id
                  measuringPoint
                  startDate
                  endDate
                  deviceID
                  createdAt
                  updatedAt
                }
                nextToken
              }
              name
              status
              active
              lastData
              comment
              createdAt
              updatedAt
            }
            startDate
            endDate
            deviceID
            MeasuringPoint {
              id
              project
              name
              devices {
                items {
                  id
                  measuringPoint
                  startDate
                  endDate
                  deviceID
                  createdAt
                  updatedAt
                }
                nextToken
              }
              deviceCase
              deviceReady
              deviceReadyTime
              Project {
                id
                name
                active
                comment
                timeoutActive
                measuringPoints {
                  nextToken
                }
                projectOwner
                plan
                planStart
                planEnd
                planNumber
                createdAt
                updatedAt
              }
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        name
        status
        active
        lastData
        comment
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getBackgroundData = /* GraphQL */ `
  query GetBackgroundData($deviceId: ID!, $datatimestamp: AWSDateTime!) {
    getBackgroundData(deviceId: $deviceId, datatimestamp: $datatimestamp) {
      deviceId
      datatimestamp
      vX
      vY
      vZ
      createdAt
      updatedAt
    }
  }
`;
export const listBackgroundDatas = /* GraphQL */ `
  query ListBackgroundDatas(
    $deviceId: ID
    $datatimestamp: ModelStringKeyConditionInput
    $filter: ModelBackgroundDataFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listBackgroundDatas(
      deviceId: $deviceId
      datatimestamp: $datatimestamp
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        deviceId
        datatimestamp
        vX
        vY
        vZ
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getEventData = /* GraphQL */ `
  query GetEventData($deviceId: ID!, $starttimestamp: AWSDateTime!) {
    getEventData(deviceId: $deviceId, starttimestamp: $starttimestamp) {
      deviceId
      starttimestamp
      duration
      vX
      vY
      vZ
      createdAt
      updatedAt
    }
  }
`;
export const listEventDatas = /* GraphQL */ `
  query ListEventDatas(
    $deviceId: ID
    $starttimestamp: ModelStringKeyConditionInput
    $filter: ModelEventDataFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listEventDatas(
      deviceId: $deviceId
      starttimestamp: $starttimestamp
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        deviceId
        starttimestamp
        duration
        vX
        vY
        vZ
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getEventDataDetail = /* GraphQL */ `
  query GetEventDataDetail($deviceId: ID!, $datatimestamp: AWSDateTime!) {
    getEventDataDetail(deviceId: $deviceId, datatimestamp: $datatimestamp) {
      deviceId
      datatimestamp
      starttimestamp
      duration
      vX
      vY
      vZ
      createdAt
      updatedAt
    }
  }
`;
export const listEventDataDetails = /* GraphQL */ `
  query ListEventDataDetails(
    $deviceId: ID
    $datatimestamp: ModelStringKeyConditionInput
    $filter: ModelEventDataDetailFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listEventDataDetails(
      deviceId: $deviceId
      datatimestamp: $datatimestamp
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        deviceId
        datatimestamp
        starttimestamp
        duration
        vX
        vY
        vZ
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getSetting = /* GraphQL */ `
  query GetSetting($type: String!) {
    getSetting(type: $type) {
      type
      value
      name
      createdAt
      updatedAt
    }
  }
`;
export const listSettings = /* GraphQL */ `
  query ListSettings(
    $type: String
    $filter: ModelSettingFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listSettings(
      type: $type
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        type
        value
        name
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const eventsByStarttime = /* GraphQL */ `
  query EventsByStarttime(
    $deviceId: ID
    $starttimestamp: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelEventDataDetailFilterInput
    $limit: Int
    $nextToken: String
  ) {
    eventsByStarttime(
      deviceId: $deviceId
      starttimestamp: $starttimestamp
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        deviceId
        datatimestamp
        starttimestamp
        duration
        vX
        vY
        vZ
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
