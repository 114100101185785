import {
  Alert,
  AlertIcon,
  AlertTitle,
  Box,
  Button,
  FormControl,
  FormLabel,
  IconButton,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Select,
  Stack,
  Text,
  useDisclosure,
} from "@chakra-ui/core";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import { CreateProjectInput, UpdateProjectInput } from "../../API";
import { Project, WeekUtilization } from "../../types";
import {
  calendarWeekToDbDate,
  dbDateToCalendarWeek,
  dbTimeToDate,
  getNextYears,
  getWeeksToYear,
  hasWrongOrder,
} from "../../utils";
interface UpsertProjectProps {
  triggerText?: string;
  icon?: "add" | "settings";
  title: string;
  submit: Function;
  project?: any;
  planning?: boolean;
  onClickCb?: () => void;
}

type InputError = {
  dateOrder: boolean | null;
  weeksWithExcess: WeekUtilization[] | null;
};

const initInputData = (project: Project): CreateProjectInput | UpdateProjectInput => ({
  id: project ? project.id : null,
  name: project ? project.name : "",
  plan: true,
  planNumber: project ? project.planNumber : null,
  planStart: project ? project.planStart : null,
  planEnd: project ? project.planEnd : null,
});

const UpsertProject = ({
  triggerText,
  icon,
  title,
  submit,
  project,
  onClickCb,
}: UpsertProjectProps) => {
  const { t } = useTranslation();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [inputData, setInputData] = useState<CreateProjectInput | UpdateProjectInput>(
    initInputData(project)
  );
  const [success, setSuccess] = useState(false);

  const [errors, setErrors] = useState<InputError>({
    dateOrder: false,
    weeksWithExcess: null,
  });

  const [years, setYears] = useState<number[]>([]);

  const [startYearSelected, setStartYearSelected] = useState<string>("");
  const [endYearSelected, setEndYearSelected] = useState<string>("");

  useEffect(() => {
    const years = getNextYears();
    setYears(years);
  }, []);

  useEffect(() => {
    setInputData(initInputData(project));
  }, [onOpen]);

  const isDateValid = () => {
    if (inputData.planStart && inputData.planEnd) {
      const wrongOrder = hasWrongOrder([
        {
          startDate: dbTimeToDate(inputData.planStart),
          endDate: dbTimeToDate(inputData.planEnd),
        },
      ]);
      if (wrongOrder) {
        setErrors((err) => {
          return { ...err, dateOrder: true };
        });
        return false;
      }
    }
    return true;
  };

  // Returns true if error found
  const checkInput = () => {
    let errorFound = false;
    if (isDateValid() === false) {
      errorFound = true;
    }
    return errorFound;
  };

  return (
    <>
      {icon ? (
        <IconButton
          onClick={() => {
            onOpen();
            onClickCb && onClickCb();
          }}
          variant="ghost"
          aria-label={triggerText || ""}
          icon={icon}
        >
          {triggerText}
        </IconButton>
      ) : (
        <Button
          onClick={() => {
            onOpen();
            onClickCb && onClickCb();
          }}
        >
          {triggerText}
        </Button>
      )}
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent rounded="md">
          {success ? (
            <Alert
              status="success"
              variant="subtle"
              flexDirection="column"
              justifyContent="center"
              textAlign="center"
              height="200px"
            >
              <AlertIcon size="40px" mr={0} />
              <AlertTitle mt={4} mb={1} fontSize="lg">
                {t("Änderungen gespeichert")}!
              </AlertTitle>
            </Alert>
          ) : (
            <>
              <ModalHeader>{title}</ModalHeader>
              <ModalCloseButton />
              <ModalBody>
                <FormControl>
                  <Stack spacing={3}>
                    <Box>
                      <FormLabel htmlFor="name">Name</FormLabel>
                      <Input
                        placeholder={t("Name eingeben")}
                        onChange={(e: any) => {
                          setInputData({
                            ...inputData,
                            name: e.target.value,
                          });
                        }}
                        name="name"
                        aria-describedby={t("Projektname")}
                        value={inputData.name ? inputData.name : ""}
                      />
                    </Box>
                    <Box>
                      <FormLabel htmlFor="planNumber">{t("Anzahl benötigter Geräte")}</FormLabel>
                      <Input
                        type={"number"}
                        placeholder={t("Anzahl benötigter Geräte eingeben")}
                        onChange={(e: any) => {
                          setInputData({
                            ...inputData,
                            planNumber: e.target.value,
                          });
                        }}
                        name="planNumber"
                        aria-describedby={t("Anzahl benötigter Geräte")}
                        value={inputData.planNumber ? inputData.planNumber : ""}
                      />
                    </Box>
                    <Box>
                      <FormLabel htmlFor={"project-timeline"}>{t("Projektzeitraum")}</FormLabel>
                      <Stack>
                        <Stack isInline spacing={4}>
                          <Box display={"flex"} alignItems={"center"} width={100}>
                            <Text>{t("Startdatum")}:</Text>
                          </Box>
                          <Box>
                            <Select
                              key={"start-year"}
                              placeholder={t("Jahr")}
                              value={
                                startYearSelected
                                  ? startYearSelected
                                  : inputData.planStart
                                  ? dbDateToCalendarWeek(inputData.planStart).year
                                  : ""
                              }
                              onChange={(e) => {
                                setStartYearSelected(e.target.value);
                              }}
                            >
                              {years.map((y, i) => {
                                return (
                                  <option key={i} value={y}>
                                    {y}
                                  </option>
                                );
                              })}
                            </Select>
                          </Box>
                          <Box>
                            <Select
                              key={"start-kw"}
                              value={
                                inputData.planStart && inputData.planStart.length > 0
                                  ? dbDateToCalendarWeek(inputData.planStart).week
                                  : ""
                              }
                              placeholder={"KW"}
                              onChange={(e) => {
                                e.persist();
                                if (e.target?.value) {
                                  setInputData((inputData) => {
                                    const date = e.target.value;
                                    return {
                                      ...inputData,
                                      planStart: calendarWeekToDbDate(startYearSelected, date),
                                    };
                                  });
                                }
                              }}
                            >
                              {getWeeksToYear(
                                inputData.planStart
                                  ? moment(inputData.planStart).year()
                                  : parseInt(startYearSelected)
                              ).map((w, i) => {
                                return (
                                  <option key={i} value={w}>
                                    {w}
                                  </option>
                                );
                              })}
                            </Select>
                          </Box>
                        </Stack>

                        <Stack isInline spacing={4}>
                          <Box display={"flex"} alignItems={"center"} width={100}>
                            <Text>{t("Enddatum")}:</Text>
                          </Box>
                          <Box>
                            <Select
                              key={"end-year"}
                              placeholder={t("Jahr")}
                              value={
                                endYearSelected
                                  ? endYearSelected
                                  : inputData.planEnd
                                  ? dbDateToCalendarWeek(inputData.planEnd).year
                                  : ""
                              }
                              onChange={(e) => {
                                setEndYearSelected(e.target.value);
                              }}
                            >
                              {years.map((y, i) => {
                                return (
                                  <option key={i} value={y}>
                                    {y}
                                  </option>
                                );
                              })}
                            </Select>
                          </Box>
                          <Box>
                            <Select
                              key={"end-kw"}
                              placeholder={"KW"}
                              value={
                                inputData.planEnd && inputData.planEnd.length > 0
                                  ? dbDateToCalendarWeek(inputData.planEnd).week
                                  : ""
                              }
                              onChange={(e) => {
                                if (e.target?.value) {
                                  const date = e.target.value;
                                  setInputData((inputData) => {
                                    return {
                                      ...inputData,
                                      planEnd: calendarWeekToDbDate(endYearSelected, date),
                                    };
                                  });
                                }
                              }}
                            >
                              {getWeeksToYear(
                                inputData.planEnd
                                  ? moment(inputData.planEnd).year()
                                  : parseInt(endYearSelected)
                              ).map((w, i) => {
                                return (
                                  <option key={i} value={w}>
                                    {w}
                                  </option>
                                );
                              })}
                            </Select>
                          </Box>
                        </Stack>
                      </Stack>
                    </Box>
                  </Stack>
                </FormControl>
              </ModalBody>
              <ModalFooter>
                <Stack w="full" spacing={2}>
                  {errors.dateOrder ? (
                    <Stack w="full" isInline justify="space-around">
                      <Text color="red.500" fontWeight="bold">
                        {t("Das Enddatum liegt vor dem Startdatum")}
                      </Text>
                    </Stack>
                  ) : null}
                  {errors.weeksWithExcess && errors.weeksWithExcess.length > 0 ? (
                    <Stack w="full" isInline justify="space-around">
                      {errors.weeksWithExcess.map((week) => {
                        return (
                          <Text color="red.500" fontWeight="bold">
                            {`${week.calendarWeek} hat einen Bedarf von ${week.needed} Geräten`}
                          </Text>
                        );
                      })}
                    </Stack>
                  ) : null}
                  <Stack isInline justify="space-between" align="center">
                    <Stack isInline spacing={3}>
                      <Button mr={3} variant="outline" onClick={onClose}>
                        {t("Abbrechen")}
                      </Button>
                      <Button
                        variantColor="green"
                        onClick={() => {
                          if (checkInput() === false) {
                            submit(inputData, () => {
                              setSuccess(true);
                              //@ts-ignore
                              setInputData(initInputData(undefined));
                              setTimeout(() => {
                                onClose();
                                setSuccess(false);
                              }, 1000);
                            });
                          }
                        }}
                      >
                        OK
                      </Button>
                    </Stack>
                  </Stack>
                </Stack>
              </ModalFooter>
            </>
          )}
        </ModalContent>
      </Modal>
    </>
  );
};

export default UpsertProject;
