import { Heading, Spinner, Stack } from "@chakra-ui/core";
import { API, graphqlOperation } from "aws-amplify";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { withRouter } from "react-router-dom";

import { CreateProjectInput } from "../API";
import FilterToggle from "../components/FilterToggle";
import Metrics from "../components/Metrics";
import DismantlingTable from "../components/Planner/DismantlingTable";
import PlanningTable from "../components/Planner/PlanningTable";
import UpsertPlannedProject from "../components/Planner/UpsertPlannedProject";
import UtilizationTable from "../components/Planner/UtilizationTable";
import { createProject } from "../graphql/mutations";
import { onCreateProject, onDeleteProject, onUpdateProject } from "../graphql/subscriptions";
import { Layout } from "../Layout";
import { useProjectStore } from "../store/project-store";
import subQuery from "../subQuery";

interface PlannerProps {}

const Planner = (props: PlannerProps) => {
  const { loadPlannedProjects, loading, plannedProjects } = useProjectStore();
  const { t } = useTranslation();
  const [activeTable, setActiveTable] = useState(1);

  useEffect(() => {
    loadPlannedProjects();
    subQuery([onCreateProject, onUpdateProject, onDeleteProject], loadPlannedProjects);
  }, []);
  return (
    <Layout>
      <Stack spacing={4} pb={24} w="full">
        <Metrics />
        <Stack rounded="lg" w="full" shadow="sm" bg="white" px="10" py="6" spacing="5">
          <Stack isInline align="center" justify="space-between">
            <Heading as="h2" size="lg">
              {t("Projekt- und Geräteplanung")}
            </Heading>
            <UpsertPlannedProject
              onClickCb={() => {
                setActiveTable(2);
              }}
              title={t("Neues Projekt planen")}
              triggerText={t("Projekt planen")}
              submit={async (input: CreateProjectInput, successCb: () => void) => {
                await API.graphql(graphqlOperation(createProject, { input }));
                successCb();
              }}
            />
          </Stack>
          <Stack isInline alignItems="center" justify="space-between" w="full">
            <FilterToggle
              onClick={(active: boolean | number | undefined) => {
                if (typeof active === "number") setActiveTable(active);
              }}
              options={[
                { name: `${t("Auslastung")}`, value: 1 },
                { name: `${t("Planung")}`, value: 2 },
                { name: `${t("Abbau")}`, value: 3 },
              ]}
              selectedId={activeTable}
            />
          </Stack>
          {loading ? (
            <Spinner mx="auto" />
          ) : activeTable === 1 ? (
            <UtilizationTable />
          ) : activeTable === 2 ? (
            <PlanningTable loading={loading} plannedProjects={plannedProjects} />
          ) : activeTable === 3 ? (
            <DismantlingTable />
          ) : null}
        </Stack>
      </Stack>
    </Layout>
  );
};

export default withRouter(Planner);
