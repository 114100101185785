import { API, Auth } from "aws-amplify";

import { listMeasuringPointDevices } from "../graphql/queries";
import { MeasuringPointDevice } from "../types";
import { fetchEverything } from ".";
import { hasOverlap } from "./data";
import { dateToDbTime, dbTimeToDate } from "./time";

export const getEventExport = async (mpId: string, startDate?: string, endDate?: string) => {
  type Params = { maeasuringpoint_id: string; startDate?: string; endDate?: string };
  const params: Params = {
    maeasuringpoint_id: mpId,
    startDate: dateToDbTime(new Date(0)),
    endDate: dateToDbTime(new Date()),
  };
  if (startDate) params.startDate = startDate;
  if (endDate) params.endDate = endDate;

  return API.get("events-zip-export", "", {
    headers: {
      Authorization: (await Auth.currentSession()).getIdToken().getJwtToken(),
      "Access-Control-Allow-Origin": "*",
    },
    response: true,
    queryStringParameters: params,
  });
};

export const getEventMeta = async (
  deviceId: string,
  eventDate: string
): Promise<MeasuringPointDevice | null> => {
  const measuringPointDevices: MeasuringPointDevice[] = await fetchEverything(
    listMeasuringPointDevices,
    "listMeasuringPointDevices",
    {}
  );
  const [mpd] = measuringPointDevices?.filter((mpd) => {
    return (
      mpd.device.id === deviceId &&
      hasOverlap(
        [{ startDate: dbTimeToDate(eventDate), endDate: dbTimeToDate(eventDate) }],
        [
          {
            startDate: mpd.startDate ? dbTimeToDate(mpd.startDate) : null,
            endDate: mpd.endDate ? dbTimeToDate(mpd.endDate) : null,
          },
        ]
      )
    );
  });
  return mpd;
};
