import create from "zustand";

import { Device, MeasuringPointDevice } from "../types";
import { hasAssignedDevice } from "../utils";
import { readAssignedDevices, readDevices, shortReadAssignedDevices } from "../utils/devices";

type DeviceFilter = {
  search: string;
  assigned?: boolean;
  status?: number | number[];
  active?: boolean;
};

interface DeviceState extends Record<string, unknown> {
  allDevices: Device[];
  devices: Device[];
  deviceFilter: DeviceFilter;
  loading: boolean;
  assignedDevices: Device[];
  measuringPointDevices: MeasuringPointDevice[];
  setDevices: (devices: Device[]) => void;
  setLoading: (loading: boolean) => void;
  setDeviceFilter: (deviceFilter: DeviceFilter) => void;
  loadDevices: () => Promise<void>;
}

const initialState = {
  devices: [],
  allDevices: [],
  assignedDevices: [],
  measuringPointDevices: [],
  loading: false,
  deviceFilter: { search: "" },
};

export const useDeviceStore = create<DeviceState>((set, get) => ({
  ...initialState,
  setDevices: (devices) => set({ devices }),
  setLoading: (loading) => set({ loading }),
  setDeviceFilter: (deviceFilter) => set({ deviceFilter }),
  loadDevices: async () => {
    set({ loading: true });

    const MPDs = await shortReadAssignedDevices();

    const devices: Device[] = [];
    for (const mpd of MPDs) {
      if (hasAssignedDevice(mpd)) {
        devices.push(mpd?.device);
      }
    }

    set({
      measuringPointDevices: MPDs,
      allDevices: await readDevices(),
      assignedDevices: devices,
      loading: false,
    });
  },
}));

const filter = (devices: Device[]): Device[] => {
  const state = useDeviceStore.getState();
  return devices
    .filter((device: Device) =>
      device.name.toLowerCase().includes(state.deviceFilter?.search?.toLowerCase())
    )
    .filter((device: Device) => {
      if (state.deviceFilter.active === undefined) {
        return true;
      }
      return state.deviceFilter.active ? device.active === 1 : device.active === 0;
    })
    .filter((device: Device) => {
      if (state.deviceFilter.assigned === undefined) {
        return true;
      }
      const isAssigned = !!state.assignedDevices.find(
        (assignedDevice: Device) => assignedDevice.id === device.id
      );
      return isAssigned === state.deviceFilter.assigned;
    })
    .filter((device: Device) => {
      if (state.deviceFilter.status === undefined) {
        return true;
      }

      if (
        Array.isArray(state.deviceFilter.status) &&
        state.deviceFilter.status.length > 0 &&
        device.status
      ) {
        return state.deviceFilter.status.includes(device.status);
      }
      return device.status === state.deviceFilter.status;
    });
};

useDeviceStore.subscribe(
  (allDevices: Device[]) => {
    useDeviceStore.setState({ devices: filter(allDevices) });
  },
  (state) => state.allDevices
);

useDeviceStore.subscribe(
  () => {
    useDeviceStore.setState({ devices: filter(useDeviceStore.getState().allDevices) });
  },
  (state) => state.deviceFilter
);
