import { API, graphqlOperation } from "aws-amplify";

import { deviceUtilization, deviceUtilizationDevices, shortQuery } from "../graphql/customQueries";
import { listDevices, listMeasuringPointDevices } from "../graphql/queries";
import { Device, MeasuringPointDevice, UtilizationResponse } from "../types";
import { fetchEverything } from ".";
export interface LocalMeasuringPointDevice {
  startDate?: string | undefined;
  endDate?: string | undefined;
  id?: string | undefined;
  MeasuringPoint: {
    Project: {
      planEnd?: string | undefined;
      planStart?: string | undefined;
    };
  };
}

export const readDevices = async (): Promise<Device[]> => {
  const deviceResult = await fetchEverything(listDevices, "listDevices", {});
  // @ts-ignore
  return deviceResult;
};

export const fetchDeviceInfo = async () => {
  //DEVICES UND PROJECTS EINZELN ZIEHEN
  /*  const result = await API.graphql(graphqlOperation(deviceUtilization));
   */
  const devices = await fetchEverything(deviceUtilizationDevices, "listDevices", {
    filter: {
      active: { eq: 1 },
    },
  });
  const projects = await fetchEverything(deviceUtilization, "listProjects", {});

  return {
    devices,
    projects,
  };
  /*
  return result*/
};

export const readAssignedDevices = async (): Promise<MeasuringPointDevice[]> => {
  const MPDs = await fetchEverything(listMeasuringPointDevices, "listMeasuringPointDevices", {});
  return MPDs;
};

export const shortReadAssignedDevices = async (): Promise<MeasuringPointDevice[]> => {
  const short = await fetchEverything(shortQuery, "listMeasuringPointDevices", {});
  return short;
};

/*export const getMeasuringPointDevice = (utilizationResponse: UtilizationResponse): LocalMeasuringPointDevice[] => {
  let devices = utilizationResponse.devices
  let mpd: LocalMeasuringPointDevice[] = [];
  devices.forEach(dev => {
    //@ts-ignore
    if (dev.MeasuringPointDevices.items.length > 0) {
      //@ts-ignore
      mpd.push(...dev.MeasuringPointDevices.items);
    }
  });
  return mpd;
};*/
