import React from "react";
import { Checkbox, IconButton, Stack } from "@chakra-ui/core";
import DatePicker, { registerLocale } from "react-datepicker";
import { TimeSelector } from "../TimeSelector";
import de from "date-fns/locale/de";

interface MPSettingsLineProps {
  deviceCase: boolean,
  onDeviceCaseChange: (deviceCase: boolean | null) => void

  deviceReady: boolean,
  onDeviceReadyChange: (deviceReady: boolean | null) => void,

  deviceReadyTime: Date | null;
  onDeviceReadyTimeChange: (date: Date | null) => void;
}


registerLocale("de", de);

const MPSettingsLine = ({
  deviceCase,
  onDeviceCaseChange,
  deviceReady,
  onDeviceReadyChange,
  deviceReadyTime,
  onDeviceReadyTimeChange
  }: MPSettingsLineProps) => {

  return(
    <Stack  align="left" spacing={3} py={2}>
      <Stack isInline align="center" spacing={1}>
        <Checkbox isChecked={deviceCase}  onChange={(e) => {
          onDeviceCaseChange(e.target.checked)
        }
        }>Koffer</Checkbox>
      </Stack>

      <Stack isInline spacing={4} py={2}>
        <Stack isInline >
          <Checkbox isChecked={deviceReady} onChange={e => onDeviceReadyChange(e.target.checked)}>Freimeldung</Checkbox>
        </Stack>
        {deviceReady && (
          <>
            <DatePicker
              selected={
                deviceReadyTime
              }
              onChange={(date: Date) => {
                onDeviceReadyTimeChange(date);
              }}
              showTimeSelect
              locale="de"
              todayButton="Heute"
              customInput={<TimeSelector />}
              placeholderText="Startdatum"
              timeIntervals={15}
              dateFormat="dd.MM.yyyy, HH:mm"
            />

            <IconButton
              aria-label="Clear Time"
              icon="small-close"
              onClick={() => {
                onDeviceReadyTimeChange(null);
              }}
            />
          </>
        )}
      </Stack>
    </Stack>
  )
}


export default MPSettingsLine;