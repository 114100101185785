import _ from "lodash";
import {fetchEverything, hasAssignedDevice} from ".";

import {listProjects} from "../graphql/queries";
import {Project} from "../types";

export const getQueryFilters = () => {
    const search = window.location.search;
    let active = {};
    let status = undefined;

    if (search.includes("projects.all")) {
        active = {};
    } else if (search.includes("projects.archived")) {
        active = {active: {eq: 0}};
    } else {
        // only active projects by default
        active = {active: {eq: 1}};
    }

    if (search.includes("devices.online")) {
        status = 1;
    } else if (search.includes("devices.error")) {
        status = 3;
    }

    return {active, status};
};

export const readPlannedProjects = async (): Promise<Project[]> => {
    const projects = await fetchEverything(listProjects, "listProjects", {filter: {plan: {eq: true}}})
    return projects;
}

export const readProjects = async (): Promise<Project[]> => {
    const projects = await fetchEverything(
        listProjects,
        "listProjects",
        {}
    );
    return projects;
};

export const getDeviceMapping = (projects: Project[]) => {
    let deviceMapping = {};
    if (!projects) {
        return deviceMapping;
    }
    projects.forEach((project: any) => {
        if (project.measuringPoints?.items) {
            // @ts-ignore
            project.measuringPoints.items.forEach((mp: MeasuringPoint) => {
                // @ts-ignore
                if (mp.devices?.items) {
                    // @ts-ignore
                    mp.devices.items.forEach((mpd: MeasuringPointDevice) => {
                        if (hasAssignedDevice(mpd)) {
                            if (mpd.device) {
                                deviceMapping = _.merge({}, deviceMapping, {
                                    [mpd.device.id]: {mp, project},
                                });
                            }
                        }
                    });
                }
            });
        }
    });
    return deviceMapping;
};
