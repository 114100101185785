import { Icon, Link, Stack, Text } from "@chakra-ui/core";
import _ from "lodash";
import React from "react";
import DataTable from "react-data-table-component";
import { useHistory } from "react-router-dom";

import { EventData } from "../types";
import { calcVectorLength, dateToString, dbTimeToDate } from "../utils";

interface EventsTableProps {
  events: EventData[];
}

export const EventsTable = ({ events }: EventsTableProps) => {
  const history = useHistory();
  const columns = [
    {
      name: "Trigger-Zeitpunkt",
      selector: "datatimestamp",
      sortable: true,

      // eslint-disable-next-line react/display-name
      cell: (row: any) => {
        return (
          <Link
            onClick={() => {
              history.push(`/event/${row.deviceId}/${row.starttimestamp}`);
            }}
          >
            <Stack isInline align="center">
              <Icon name="external-link" />
              <Text>{dateToString(dbTimeToDate(row.starttimestamp))}</Text>
            </Stack>
          </Link>
        );
      },
    },
    // {
    //   name: "Dauer",
    //   selector: "duration",
    //   sortable: true,
    //   center: true,
    // },
    {
      name: "Vektorlänge",
      selector: "vLength",
      sortable: true,
      center: true,
    },
    {
      name: "vX",
      selector: "vX",
      sortable: true,
      center: true,
      cell: (row: any) => row.vX.toFixed(3),
    },
    {
      name: "vY",
      selector: "vY",
      sortable: true,
      center: true,
      cell: (row: any) => row.vY.toFixed(3),
    },
    {
      name: "vZ",
      selector: "vZ",
      sortable: true,
      center: true,
      cell: (row: any) => row.vZ.toFixed(3),
    },
  ];

  const transformedRows = events.map((event: EventData) => ({
    ...event,
    vLength: calcVectorLength(event),
    keyField: event.deviceId + ":" + event.starttimestamp,
  }));

  if (!events || events.length === 0) {
    return <Text textAlign="center">Keine Daten vorhanden.</Text>;
  }
  return (
    <DataTable
      sortFunction={(rows, field, sortDirection) => {
        if (field && field === "datatimestamp") {
          return _.orderBy(rows, "starttimestamp", sortDirection);
        } else {
          return _.orderBy(rows, field, sortDirection);
        }
      }}
      data={transformedRows}
      columns={columns}
      noHeader
      pagination
    />
  );
};
