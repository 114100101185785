/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const onCreateProject = /* GraphQL */ `
  subscription OnCreateProject {
    onCreateProject {
      id
      name
      active
      comment
      timeoutActive
      measuringPoints {
        items {
          id
          project
          name
          devices {
            items {
              id
              measuringPoint
              device {
                id
                MeasuringPointDevices {
                  nextToken
                }
                name
                status
                active
                lastData
                comment
                createdAt
                updatedAt
              }
              startDate
              endDate
              deviceID
              MeasuringPoint {
                id
                project
                name
                devices {
                  nextToken
                }
                deviceCase
                deviceReady
                deviceReadyTime
                Project {
                  id
                  name
                  active
                  comment
                  timeoutActive
                  projectOwner
                  plan
                  planStart
                  planEnd
                  planNumber
                  createdAt
                  updatedAt
                }
                createdAt
                updatedAt
              }
              createdAt
              updatedAt
            }
            nextToken
          }
          deviceCase
          deviceReady
          deviceReadyTime
          Project {
            id
            name
            active
            comment
            timeoutActive
            measuringPoints {
              items {
                id
                project
                name
                devices {
                  nextToken
                }
                deviceCase
                deviceReady
                deviceReadyTime
                Project {
                  id
                  name
                  active
                  comment
                  timeoutActive
                  projectOwner
                  plan
                  planStart
                  planEnd
                  planNumber
                  createdAt
                  updatedAt
                }
                createdAt
                updatedAt
              }
              nextToken
            }
            projectOwner
            plan
            planStart
            planEnd
            planNumber
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
        }
        nextToken
      }
      projectOwner
      plan
      planStart
      planEnd
      planNumber
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateProject = /* GraphQL */ `
  subscription OnUpdateProject {
    onUpdateProject {
      id
      name
      active
      comment
      timeoutActive
      measuringPoints {
        items {
          id
          project
          name
          devices {
            items {
              id
              measuringPoint
              device {
                id
                MeasuringPointDevices {
                  nextToken
                }
                name
                status
                active
                lastData
                comment
                createdAt
                updatedAt
              }
              startDate
              endDate
              deviceID
              MeasuringPoint {
                id
                project
                name
                devices {
                  nextToken
                }
                deviceCase
                deviceReady
                deviceReadyTime
                Project {
                  id
                  name
                  active
                  comment
                  timeoutActive
                  projectOwner
                  plan
                  planStart
                  planEnd
                  planNumber
                  createdAt
                  updatedAt
                }
                createdAt
                updatedAt
              }
              createdAt
              updatedAt
            }
            nextToken
          }
          deviceCase
          deviceReady
          deviceReadyTime
          Project {
            id
            name
            active
            comment
            timeoutActive
            measuringPoints {
              items {
                id
                project
                name
                devices {
                  nextToken
                }
                deviceCase
                deviceReady
                deviceReadyTime
                Project {
                  id
                  name
                  active
                  comment
                  timeoutActive
                  projectOwner
                  plan
                  planStart
                  planEnd
                  planNumber
                  createdAt
                  updatedAt
                }
                createdAt
                updatedAt
              }
              nextToken
            }
            projectOwner
            plan
            planStart
            planEnd
            planNumber
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
        }
        nextToken
      }
      projectOwner
      plan
      planStart
      planEnd
      planNumber
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteProject = /* GraphQL */ `
  subscription OnDeleteProject {
    onDeleteProject {
      id
      name
      active
      comment
      timeoutActive
      measuringPoints {
        items {
          id
          project
          name
          devices {
            items {
              id
              measuringPoint
              device {
                id
                MeasuringPointDevices {
                  nextToken
                }
                name
                status
                active
                lastData
                comment
                createdAt
                updatedAt
              }
              startDate
              endDate
              deviceID
              MeasuringPoint {
                id
                project
                name
                devices {
                  nextToken
                }
                deviceCase
                deviceReady
                deviceReadyTime
                Project {
                  id
                  name
                  active
                  comment
                  timeoutActive
                  projectOwner
                  plan
                  planStart
                  planEnd
                  planNumber
                  createdAt
                  updatedAt
                }
                createdAt
                updatedAt
              }
              createdAt
              updatedAt
            }
            nextToken
          }
          deviceCase
          deviceReady
          deviceReadyTime
          Project {
            id
            name
            active
            comment
            timeoutActive
            measuringPoints {
              items {
                id
                project
                name
                devices {
                  nextToken
                }
                deviceCase
                deviceReady
                deviceReadyTime
                Project {
                  id
                  name
                  active
                  comment
                  timeoutActive
                  projectOwner
                  plan
                  planStart
                  planEnd
                  planNumber
                  createdAt
                  updatedAt
                }
                createdAt
                updatedAt
              }
              nextToken
            }
            projectOwner
            plan
            planStart
            planEnd
            planNumber
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
        }
        nextToken
      }
      projectOwner
      plan
      planStart
      planEnd
      planNumber
      createdAt
      updatedAt
    }
  }
`;
export const onCreateMeasuringPoint = /* GraphQL */ `
  subscription OnCreateMeasuringPoint {
    onCreateMeasuringPoint {
      id
      project
      name
      devices {
        items {
          id
          measuringPoint
          device {
            id
            MeasuringPointDevices {
              items {
                id
                measuringPoint
                device {
                  id
                  name
                  status
                  active
                  lastData
                  comment
                  createdAt
                  updatedAt
                }
                startDate
                endDate
                deviceID
                MeasuringPoint {
                  id
                  project
                  name
                  deviceCase
                  deviceReady
                  deviceReadyTime
                  createdAt
                  updatedAt
                }
                createdAt
                updatedAt
              }
              nextToken
            }
            name
            status
            active
            lastData
            comment
            createdAt
            updatedAt
          }
          startDate
          endDate
          deviceID
          MeasuringPoint {
            id
            project
            name
            devices {
              items {
                id
                measuringPoint
                device {
                  id
                  name
                  status
                  active
                  lastData
                  comment
                  createdAt
                  updatedAt
                }
                startDate
                endDate
                deviceID
                MeasuringPoint {
                  id
                  project
                  name
                  deviceCase
                  deviceReady
                  deviceReadyTime
                  createdAt
                  updatedAt
                }
                createdAt
                updatedAt
              }
              nextToken
            }
            deviceCase
            deviceReady
            deviceReadyTime
            Project {
              id
              name
              active
              comment
              timeoutActive
              measuringPoints {
                items {
                  id
                  project
                  name
                  deviceCase
                  deviceReady
                  deviceReadyTime
                  createdAt
                  updatedAt
                }
                nextToken
              }
              projectOwner
              plan
              planStart
              planEnd
              planNumber
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
        }
        nextToken
      }
      deviceCase
      deviceReady
      deviceReadyTime
      Project {
        id
        name
        active
        comment
        timeoutActive
        measuringPoints {
          items {
            id
            project
            name
            devices {
              items {
                id
                measuringPoint
                device {
                  id
                  name
                  status
                  active
                  lastData
                  comment
                  createdAt
                  updatedAt
                }
                startDate
                endDate
                deviceID
                MeasuringPoint {
                  id
                  project
                  name
                  deviceCase
                  deviceReady
                  deviceReadyTime
                  createdAt
                  updatedAt
                }
                createdAt
                updatedAt
              }
              nextToken
            }
            deviceCase
            deviceReady
            deviceReadyTime
            Project {
              id
              name
              active
              comment
              timeoutActive
              measuringPoints {
                items {
                  id
                  project
                  name
                  deviceCase
                  deviceReady
                  deviceReadyTime
                  createdAt
                  updatedAt
                }
                nextToken
              }
              projectOwner
              plan
              planStart
              planEnd
              planNumber
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        projectOwner
        plan
        planStart
        planEnd
        planNumber
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateMeasuringPoint = /* GraphQL */ `
  subscription OnUpdateMeasuringPoint {
    onUpdateMeasuringPoint {
      id
      project
      name
      devices {
        items {
          id
          measuringPoint
          device {
            id
            MeasuringPointDevices {
              items {
                id
                measuringPoint
                device {
                  id
                  name
                  status
                  active
                  lastData
                  comment
                  createdAt
                  updatedAt
                }
                startDate
                endDate
                deviceID
                MeasuringPoint {
                  id
                  project
                  name
                  deviceCase
                  deviceReady
                  deviceReadyTime
                  createdAt
                  updatedAt
                }
                createdAt
                updatedAt
              }
              nextToken
            }
            name
            status
            active
            lastData
            comment
            createdAt
            updatedAt
          }
          startDate
          endDate
          deviceID
          MeasuringPoint {
            id
            project
            name
            devices {
              items {
                id
                measuringPoint
                device {
                  id
                  name
                  status
                  active
                  lastData
                  comment
                  createdAt
                  updatedAt
                }
                startDate
                endDate
                deviceID
                MeasuringPoint {
                  id
                  project
                  name
                  deviceCase
                  deviceReady
                  deviceReadyTime
                  createdAt
                  updatedAt
                }
                createdAt
                updatedAt
              }
              nextToken
            }
            deviceCase
            deviceReady
            deviceReadyTime
            Project {
              id
              name
              active
              comment
              timeoutActive
              measuringPoints {
                items {
                  id
                  project
                  name
                  deviceCase
                  deviceReady
                  deviceReadyTime
                  createdAt
                  updatedAt
                }
                nextToken
              }
              projectOwner
              plan
              planStart
              planEnd
              planNumber
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
        }
        nextToken
      }
      deviceCase
      deviceReady
      deviceReadyTime
      Project {
        id
        name
        active
        comment
        timeoutActive
        measuringPoints {
          items {
            id
            project
            name
            devices {
              items {
                id
                measuringPoint
                device {
                  id
                  name
                  status
                  active
                  lastData
                  comment
                  createdAt
                  updatedAt
                }
                startDate
                endDate
                deviceID
                MeasuringPoint {
                  id
                  project
                  name
                  deviceCase
                  deviceReady
                  deviceReadyTime
                  createdAt
                  updatedAt
                }
                createdAt
                updatedAt
              }
              nextToken
            }
            deviceCase
            deviceReady
            deviceReadyTime
            Project {
              id
              name
              active
              comment
              timeoutActive
              measuringPoints {
                items {
                  id
                  project
                  name
                  deviceCase
                  deviceReady
                  deviceReadyTime
                  createdAt
                  updatedAt
                }
                nextToken
              }
              projectOwner
              plan
              planStart
              planEnd
              planNumber
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        projectOwner
        plan
        planStart
        planEnd
        planNumber
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteMeasuringPoint = /* GraphQL */ `
  subscription OnDeleteMeasuringPoint {
    onDeleteMeasuringPoint {
      id
      project
      name
      devices {
        items {
          id
          measuringPoint
          device {
            id
            MeasuringPointDevices {
              items {
                id
                measuringPoint
                device {
                  id
                  name
                  status
                  active
                  lastData
                  comment
                  createdAt
                  updatedAt
                }
                startDate
                endDate
                deviceID
                MeasuringPoint {
                  id
                  project
                  name
                  deviceCase
                  deviceReady
                  deviceReadyTime
                  createdAt
                  updatedAt
                }
                createdAt
                updatedAt
              }
              nextToken
            }
            name
            status
            active
            lastData
            comment
            createdAt
            updatedAt
          }
          startDate
          endDate
          deviceID
          MeasuringPoint {
            id
            project
            name
            devices {
              items {
                id
                measuringPoint
                device {
                  id
                  name
                  status
                  active
                  lastData
                  comment
                  createdAt
                  updatedAt
                }
                startDate
                endDate
                deviceID
                MeasuringPoint {
                  id
                  project
                  name
                  deviceCase
                  deviceReady
                  deviceReadyTime
                  createdAt
                  updatedAt
                }
                createdAt
                updatedAt
              }
              nextToken
            }
            deviceCase
            deviceReady
            deviceReadyTime
            Project {
              id
              name
              active
              comment
              timeoutActive
              measuringPoints {
                items {
                  id
                  project
                  name
                  deviceCase
                  deviceReady
                  deviceReadyTime
                  createdAt
                  updatedAt
                }
                nextToken
              }
              projectOwner
              plan
              planStart
              planEnd
              planNumber
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
        }
        nextToken
      }
      deviceCase
      deviceReady
      deviceReadyTime
      Project {
        id
        name
        active
        comment
        timeoutActive
        measuringPoints {
          items {
            id
            project
            name
            devices {
              items {
                id
                measuringPoint
                device {
                  id
                  name
                  status
                  active
                  lastData
                  comment
                  createdAt
                  updatedAt
                }
                startDate
                endDate
                deviceID
                MeasuringPoint {
                  id
                  project
                  name
                  deviceCase
                  deviceReady
                  deviceReadyTime
                  createdAt
                  updatedAt
                }
                createdAt
                updatedAt
              }
              nextToken
            }
            deviceCase
            deviceReady
            deviceReadyTime
            Project {
              id
              name
              active
              comment
              timeoutActive
              measuringPoints {
                items {
                  id
                  project
                  name
                  deviceCase
                  deviceReady
                  deviceReadyTime
                  createdAt
                  updatedAt
                }
                nextToken
              }
              projectOwner
              plan
              planStart
              planEnd
              planNumber
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        projectOwner
        plan
        planStart
        planEnd
        planNumber
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const onCreateMeasuringPointDevice = /* GraphQL */ `
  subscription OnCreateMeasuringPointDevice {
    onCreateMeasuringPointDevice {
      id
      measuringPoint
      device {
        id
        MeasuringPointDevices {
          items {
            id
            measuringPoint
            device {
              id
              MeasuringPointDevices {
                items {
                  id
                  measuringPoint
                  startDate
                  endDate
                  deviceID
                  createdAt
                  updatedAt
                }
                nextToken
              }
              name
              status
              active
              lastData
              comment
              createdAt
              updatedAt
            }
            startDate
            endDate
            deviceID
            MeasuringPoint {
              id
              project
              name
              devices {
                items {
                  id
                  measuringPoint
                  startDate
                  endDate
                  deviceID
                  createdAt
                  updatedAt
                }
                nextToken
              }
              deviceCase
              deviceReady
              deviceReadyTime
              Project {
                id
                name
                active
                comment
                timeoutActive
                measuringPoints {
                  nextToken
                }
                projectOwner
                plan
                planStart
                planEnd
                planNumber
                createdAt
                updatedAt
              }
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        name
        status
        active
        lastData
        comment
        createdAt
        updatedAt
      }
      startDate
      endDate
      deviceID
      MeasuringPoint {
        id
        project
        name
        devices {
          items {
            id
            measuringPoint
            device {
              id
              MeasuringPointDevices {
                items {
                  id
                  measuringPoint
                  startDate
                  endDate
                  deviceID
                  createdAt
                  updatedAt
                }
                nextToken
              }
              name
              status
              active
              lastData
              comment
              createdAt
              updatedAt
            }
            startDate
            endDate
            deviceID
            MeasuringPoint {
              id
              project
              name
              devices {
                items {
                  id
                  measuringPoint
                  startDate
                  endDate
                  deviceID
                  createdAt
                  updatedAt
                }
                nextToken
              }
              deviceCase
              deviceReady
              deviceReadyTime
              Project {
                id
                name
                active
                comment
                timeoutActive
                measuringPoints {
                  nextToken
                }
                projectOwner
                plan
                planStart
                planEnd
                planNumber
                createdAt
                updatedAt
              }
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        deviceCase
        deviceReady
        deviceReadyTime
        Project {
          id
          name
          active
          comment
          timeoutActive
          measuringPoints {
            items {
              id
              project
              name
              devices {
                items {
                  id
                  measuringPoint
                  startDate
                  endDate
                  deviceID
                  createdAt
                  updatedAt
                }
                nextToken
              }
              deviceCase
              deviceReady
              deviceReadyTime
              Project {
                id
                name
                active
                comment
                timeoutActive
                measuringPoints {
                  nextToken
                }
                projectOwner
                plan
                planStart
                planEnd
                planNumber
                createdAt
                updatedAt
              }
              createdAt
              updatedAt
            }
            nextToken
          }
          projectOwner
          plan
          planStart
          planEnd
          planNumber
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateMeasuringPointDevice = /* GraphQL */ `
  subscription OnUpdateMeasuringPointDevice {
    onUpdateMeasuringPointDevice {
      id
      measuringPoint
      device {
        id
        MeasuringPointDevices {
          items {
            id
            measuringPoint
            device {
              id
              MeasuringPointDevices {
                items {
                  id
                  measuringPoint
                  startDate
                  endDate
                  deviceID
                  createdAt
                  updatedAt
                }
                nextToken
              }
              name
              status
              active
              lastData
              comment
              createdAt
              updatedAt
            }
            startDate
            endDate
            deviceID
            MeasuringPoint {
              id
              project
              name
              devices {
                items {
                  id
                  measuringPoint
                  startDate
                  endDate
                  deviceID
                  createdAt
                  updatedAt
                }
                nextToken
              }
              deviceCase
              deviceReady
              deviceReadyTime
              Project {
                id
                name
                active
                comment
                timeoutActive
                measuringPoints {
                  nextToken
                }
                projectOwner
                plan
                planStart
                planEnd
                planNumber
                createdAt
                updatedAt
              }
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        name
        status
        active
        lastData
        comment
        createdAt
        updatedAt
      }
      startDate
      endDate
      deviceID
      MeasuringPoint {
        id
        project
        name
        devices {
          items {
            id
            measuringPoint
            device {
              id
              MeasuringPointDevices {
                items {
                  id
                  measuringPoint
                  startDate
                  endDate
                  deviceID
                  createdAt
                  updatedAt
                }
                nextToken
              }
              name
              status
              active
              lastData
              comment
              createdAt
              updatedAt
            }
            startDate
            endDate
            deviceID
            MeasuringPoint {
              id
              project
              name
              devices {
                items {
                  id
                  measuringPoint
                  startDate
                  endDate
                  deviceID
                  createdAt
                  updatedAt
                }
                nextToken
              }
              deviceCase
              deviceReady
              deviceReadyTime
              Project {
                id
                name
                active
                comment
                timeoutActive
                measuringPoints {
                  nextToken
                }
                projectOwner
                plan
                planStart
                planEnd
                planNumber
                createdAt
                updatedAt
              }
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        deviceCase
        deviceReady
        deviceReadyTime
        Project {
          id
          name
          active
          comment
          timeoutActive
          measuringPoints {
            items {
              id
              project
              name
              devices {
                items {
                  id
                  measuringPoint
                  startDate
                  endDate
                  deviceID
                  createdAt
                  updatedAt
                }
                nextToken
              }
              deviceCase
              deviceReady
              deviceReadyTime
              Project {
                id
                name
                active
                comment
                timeoutActive
                measuringPoints {
                  nextToken
                }
                projectOwner
                plan
                planStart
                planEnd
                planNumber
                createdAt
                updatedAt
              }
              createdAt
              updatedAt
            }
            nextToken
          }
          projectOwner
          plan
          planStart
          planEnd
          planNumber
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteMeasuringPointDevice = /* GraphQL */ `
  subscription OnDeleteMeasuringPointDevice {
    onDeleteMeasuringPointDevice {
      id
      measuringPoint
      device {
        id
        MeasuringPointDevices {
          items {
            id
            measuringPoint
            device {
              id
              MeasuringPointDevices {
                items {
                  id
                  measuringPoint
                  startDate
                  endDate
                  deviceID
                  createdAt
                  updatedAt
                }
                nextToken
              }
              name
              status
              active
              lastData
              comment
              createdAt
              updatedAt
            }
            startDate
            endDate
            deviceID
            MeasuringPoint {
              id
              project
              name
              devices {
                items {
                  id
                  measuringPoint
                  startDate
                  endDate
                  deviceID
                  createdAt
                  updatedAt
                }
                nextToken
              }
              deviceCase
              deviceReady
              deviceReadyTime
              Project {
                id
                name
                active
                comment
                timeoutActive
                measuringPoints {
                  nextToken
                }
                projectOwner
                plan
                planStart
                planEnd
                planNumber
                createdAt
                updatedAt
              }
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        name
        status
        active
        lastData
        comment
        createdAt
        updatedAt
      }
      startDate
      endDate
      deviceID
      MeasuringPoint {
        id
        project
        name
        devices {
          items {
            id
            measuringPoint
            device {
              id
              MeasuringPointDevices {
                items {
                  id
                  measuringPoint
                  startDate
                  endDate
                  deviceID
                  createdAt
                  updatedAt
                }
                nextToken
              }
              name
              status
              active
              lastData
              comment
              createdAt
              updatedAt
            }
            startDate
            endDate
            deviceID
            MeasuringPoint {
              id
              project
              name
              devices {
                items {
                  id
                  measuringPoint
                  startDate
                  endDate
                  deviceID
                  createdAt
                  updatedAt
                }
                nextToken
              }
              deviceCase
              deviceReady
              deviceReadyTime
              Project {
                id
                name
                active
                comment
                timeoutActive
                measuringPoints {
                  nextToken
                }
                projectOwner
                plan
                planStart
                planEnd
                planNumber
                createdAt
                updatedAt
              }
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        deviceCase
        deviceReady
        deviceReadyTime
        Project {
          id
          name
          active
          comment
          timeoutActive
          measuringPoints {
            items {
              id
              project
              name
              devices {
                items {
                  id
                  measuringPoint
                  startDate
                  endDate
                  deviceID
                  createdAt
                  updatedAt
                }
                nextToken
              }
              deviceCase
              deviceReady
              deviceReadyTime
              Project {
                id
                name
                active
                comment
                timeoutActive
                measuringPoints {
                  nextToken
                }
                projectOwner
                plan
                planStart
                planEnd
                planNumber
                createdAt
                updatedAt
              }
              createdAt
              updatedAt
            }
            nextToken
          }
          projectOwner
          plan
          planStart
          planEnd
          planNumber
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const onCreateDevice = /* GraphQL */ `
  subscription OnCreateDevice {
    onCreateDevice {
      id
      MeasuringPointDevices {
        items {
          id
          measuringPoint
          device {
            id
            MeasuringPointDevices {
              items {
                id
                measuringPoint
                device {
                  id
                  name
                  status
                  active
                  lastData
                  comment
                  createdAt
                  updatedAt
                }
                startDate
                endDate
                deviceID
                MeasuringPoint {
                  id
                  project
                  name
                  deviceCase
                  deviceReady
                  deviceReadyTime
                  createdAt
                  updatedAt
                }
                createdAt
                updatedAt
              }
              nextToken
            }
            name
            status
            active
            lastData
            comment
            createdAt
            updatedAt
          }
          startDate
          endDate
          deviceID
          MeasuringPoint {
            id
            project
            name
            devices {
              items {
                id
                measuringPoint
                device {
                  id
                  name
                  status
                  active
                  lastData
                  comment
                  createdAt
                  updatedAt
                }
                startDate
                endDate
                deviceID
                MeasuringPoint {
                  id
                  project
                  name
                  deviceCase
                  deviceReady
                  deviceReadyTime
                  createdAt
                  updatedAt
                }
                createdAt
                updatedAt
              }
              nextToken
            }
            deviceCase
            deviceReady
            deviceReadyTime
            Project {
              id
              name
              active
              comment
              timeoutActive
              measuringPoints {
                items {
                  id
                  project
                  name
                  deviceCase
                  deviceReady
                  deviceReadyTime
                  createdAt
                  updatedAt
                }
                nextToken
              }
              projectOwner
              plan
              planStart
              planEnd
              planNumber
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
        }
        nextToken
      }
      name
      status
      active
      lastData
      comment
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateDevice = /* GraphQL */ `
  subscription OnUpdateDevice {
    onUpdateDevice {
      id
      MeasuringPointDevices {
        items {
          id
          measuringPoint
          device {
            id
            MeasuringPointDevices {
              items {
                id
                measuringPoint
                device {
                  id
                  name
                  status
                  active
                  lastData
                  comment
                  createdAt
                  updatedAt
                }
                startDate
                endDate
                deviceID
                MeasuringPoint {
                  id
                  project
                  name
                  deviceCase
                  deviceReady
                  deviceReadyTime
                  createdAt
                  updatedAt
                }
                createdAt
                updatedAt
              }
              nextToken
            }
            name
            status
            active
            lastData
            comment
            createdAt
            updatedAt
          }
          startDate
          endDate
          deviceID
          MeasuringPoint {
            id
            project
            name
            devices {
              items {
                id
                measuringPoint
                device {
                  id
                  name
                  status
                  active
                  lastData
                  comment
                  createdAt
                  updatedAt
                }
                startDate
                endDate
                deviceID
                MeasuringPoint {
                  id
                  project
                  name
                  deviceCase
                  deviceReady
                  deviceReadyTime
                  createdAt
                  updatedAt
                }
                createdAt
                updatedAt
              }
              nextToken
            }
            deviceCase
            deviceReady
            deviceReadyTime
            Project {
              id
              name
              active
              comment
              timeoutActive
              measuringPoints {
                items {
                  id
                  project
                  name
                  deviceCase
                  deviceReady
                  deviceReadyTime
                  createdAt
                  updatedAt
                }
                nextToken
              }
              projectOwner
              plan
              planStart
              planEnd
              planNumber
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
        }
        nextToken
      }
      name
      status
      active
      lastData
      comment
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteDevice = /* GraphQL */ `
  subscription OnDeleteDevice {
    onDeleteDevice {
      id
      MeasuringPointDevices {
        items {
          id
          measuringPoint
          device {
            id
            MeasuringPointDevices {
              items {
                id
                measuringPoint
                device {
                  id
                  name
                  status
                  active
                  lastData
                  comment
                  createdAt
                  updatedAt
                }
                startDate
                endDate
                deviceID
                MeasuringPoint {
                  id
                  project
                  name
                  deviceCase
                  deviceReady
                  deviceReadyTime
                  createdAt
                  updatedAt
                }
                createdAt
                updatedAt
              }
              nextToken
            }
            name
            status
            active
            lastData
            comment
            createdAt
            updatedAt
          }
          startDate
          endDate
          deviceID
          MeasuringPoint {
            id
            project
            name
            devices {
              items {
                id
                measuringPoint
                device {
                  id
                  name
                  status
                  active
                  lastData
                  comment
                  createdAt
                  updatedAt
                }
                startDate
                endDate
                deviceID
                MeasuringPoint {
                  id
                  project
                  name
                  deviceCase
                  deviceReady
                  deviceReadyTime
                  createdAt
                  updatedAt
                }
                createdAt
                updatedAt
              }
              nextToken
            }
            deviceCase
            deviceReady
            deviceReadyTime
            Project {
              id
              name
              active
              comment
              timeoutActive
              measuringPoints {
                items {
                  id
                  project
                  name
                  deviceCase
                  deviceReady
                  deviceReadyTime
                  createdAt
                  updatedAt
                }
                nextToken
              }
              projectOwner
              plan
              planStart
              planEnd
              planNumber
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
        }
        nextToken
      }
      name
      status
      active
      lastData
      comment
      createdAt
      updatedAt
    }
  }
`;
export const onCreateBackgroundData = /* GraphQL */ `
  subscription OnCreateBackgroundData {
    onCreateBackgroundData {
      deviceId
      datatimestamp
      vX
      vY
      vZ
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateBackgroundData = /* GraphQL */ `
  subscription OnUpdateBackgroundData {
    onUpdateBackgroundData {
      deviceId
      datatimestamp
      vX
      vY
      vZ
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteBackgroundData = /* GraphQL */ `
  subscription OnDeleteBackgroundData {
    onDeleteBackgroundData {
      deviceId
      datatimestamp
      vX
      vY
      vZ
      createdAt
      updatedAt
    }
  }
`;
export const onCreateEventData = /* GraphQL */ `
  subscription OnCreateEventData {
    onCreateEventData {
      deviceId
      starttimestamp
      duration
      vX
      vY
      vZ
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateEventData = /* GraphQL */ `
  subscription OnUpdateEventData {
    onUpdateEventData {
      deviceId
      starttimestamp
      duration
      vX
      vY
      vZ
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteEventData = /* GraphQL */ `
  subscription OnDeleteEventData {
    onDeleteEventData {
      deviceId
      starttimestamp
      duration
      vX
      vY
      vZ
      createdAt
      updatedAt
    }
  }
`;
export const onCreateEventDataDetail = /* GraphQL */ `
  subscription OnCreateEventDataDetail {
    onCreateEventDataDetail {
      deviceId
      datatimestamp
      starttimestamp
      duration
      vX
      vY
      vZ
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateEventDataDetail = /* GraphQL */ `
  subscription OnUpdateEventDataDetail {
    onUpdateEventDataDetail {
      deviceId
      datatimestamp
      starttimestamp
      duration
      vX
      vY
      vZ
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteEventDataDetail = /* GraphQL */ `
  subscription OnDeleteEventDataDetail {
    onDeleteEventDataDetail {
      deviceId
      datatimestamp
      starttimestamp
      duration
      vX
      vY
      vZ
      createdAt
      updatedAt
    }
  }
`;
export const onCreateSetting = /* GraphQL */ `
  subscription OnCreateSetting {
    onCreateSetting {
      type
      value
      name
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateSetting = /* GraphQL */ `
  subscription OnUpdateSetting {
    onUpdateSetting {
      type
      value
      name
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteSetting = /* GraphQL */ `
  subscription OnDeleteSetting {
    onDeleteSetting {
      type
      value
      name
      createdAt
      updatedAt
    }
  }
`;
