import "react-datepicker/dist/react-datepicker.css";

import { Box, Input } from "@chakra-ui/core";
import React, { FunctionComponent } from "react";
import DatePicker from "react-datepicker";

import { TimeSelector } from "./TimeSelector";

interface DatepickerProps extends React.ComponentProps<typeof Input> {
  date: Date | undefined;
  setDate: (date: Date) => void;
  placeholder?: string;
}

export const Datepicker: FunctionComponent<DatepickerProps> = ({
  date,
  setDate,
  placeholder,
  ...props
}) => {
  return (
    <Box {...props} w="full">
      <DatePicker
        selected={date}
        onChange={(date: Date) => {
          setDate(date);
        }}
        showTimeSelect
        locale="de"
        timeIntervals={15}
        todayButton="Heute"
        customInput={<TimeSelector />}
        placeholderText={placeholder || "Datum setzen"}
        dateFormat="dd.MM.yyyy, HH:mm"
      />
    </Box>
  );
};
