import { Button, Heading, Input, Spinner, Stack, Text } from "@chakra-ui/core";
import { API, graphqlOperation } from "aws-amplify";
import React, { FunctionComponent, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import Metrics from "../components/Metrics";
import { updateSetting } from "../graphql/mutations";
import { listSettings } from "../graphql/queries";
import { Layout } from "../Layout";
import { Setting } from "../types";
import { fetchEverything } from "../utils";

const Settings: FunctionComponent = () => {
  const [options, setOptions] = useState<Setting[]>();
  const [changes, setChanges] = useState<Setting[]>();
  const [isStoring, setIsStoring] = useState<boolean>(false);
  const { t } = useTranslation();
  const readSettings = async () => {
    try {
      const settingsData = await fetchEverything(listSettings, "listSettings", {});
      setOptions(settingsData);
      setIsStoring(false);
    } catch (e) {
      console.error(e);
    }
  };

  const updateSettings = async () => {
    setIsStoring(true);
    // @ts-ignore
    await Promise.all(
      // @ts-ignore
      changes?.map(async (change) => {
        await API.graphql(
          graphqlOperation(updateSetting, { input: { type: change.type, value: change.value } })
        );
      })
    );
    readSettings();
  };

  useEffect(() => {
    readSettings();
  }, []);

  useEffect(() => {
    setChanges([]);
  }, [options]);

  return (
    <Layout>
      <Stack spacing={4}>
        <Metrics />
        <Stack rounded="lg" shadow="sm" bg="white" px="10" py="6" spacing={8}>
          <Heading as="h2" size="lg">
            {t("Einstellungen")}
          </Heading>
          <Stack spacing={4}>
            {options?.map((option) => {
              return (
                <Stack key={option.type}>
                  <Text fontWeight="bold">{option.name}</Text>
                  <Input
                    isDisabled={isStoring}
                    name={option.type}
                    value={
                      changes?.find((change) => change.type === option.type)?.value ?? option.value
                    }
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                      const updated = options?.map((current) => {
                        const change = changes?.find((change) => change.type === current.type);
                        if (current.type === option.type) {
                          return {
                            type: current.type,
                            name: current.name,
                            value: e.target.value,
                          };
                        } else if (change) {
                          return change;
                        }
                        return current;
                      });
                      setChanges(updated);
                    }}
                  />
                </Stack>
              );
            })}
            <Stack isInline justify="flex-end">
              <Button
                isDisabled={!changes || changes?.length === 0 || isStoring}
                onClick={() => {
                  setChanges([]);
                }}
              >
                {t("Änderungen verwerfen")}
              </Button>
              <Button
                isDisabled={!changes || changes?.length === 0 || isStoring}
                variantColor="green"
                onClick={() => {
                  updateSettings();
                }}
              >
                {isStoring ? <Spinner size="sm" /> : "Speichern"}
              </Button>
            </Stack>
          </Stack>
        </Stack>
      </Stack>
    </Layout>
  );
};

export default Settings;
