import "react-datepicker/dist/react-datepicker.css";

import {
  Alert,
  AlertIcon,
  AlertTitle,
  Button,
  IconButton,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  useDisclosure,
} from "@chakra-ui/core";
import { API, graphqlOperation } from "aws-amplify";
import de from "date-fns/locale/de";
import React, { useState } from "react";
import { registerLocale } from "react-datepicker";
import { useTranslation } from "react-i18next";

import { CreateMeasuringPointInput } from "../API";
import { createMeasuringPoint } from "../graphql/mutations";
registerLocale("de", de);

interface CreateMeasuringPointProps {
  projectID: string;
  measuringPoint?: any;
}

const CreateMeasuringPoint = ({ projectID, measuringPoint }: CreateMeasuringPointProps) => {
  const { t } = useTranslation();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [inputData, setInputData] = useState({
    name: measuringPoint ? measuringPoint.name : undefined,
  });
  const [success, setSuccess] = useState(false);

  return (
    <>
      <IconButton variant="ghost" aria-label="Add Measuring Point" icon="add" onClick={onOpen} />

      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          {success ? (
            <Alert
              status="success"
              variant="subtle"
              flexDirection="column"
              justifyContent="center"
              textAlign="center"
              height="200px"
            >
              <AlertIcon size="40px" mr={0} />
              <AlertTitle mt={4} mb={1} fontSize="lg">
                {t("Änderungen gespeichert")}!
              </AlertTitle>
            </Alert>
          ) : (
            <>
              <ModalHeader>{t("Messstelle hinzufügen")}</ModalHeader>
              <ModalCloseButton />
              <ModalBody>
                <Input
                  placeholder="Name der Messstelle"
                  onChange={(e: any) => {
                    setInputData({ ...inputData, name: e.target.value });
                  }}
                  value={inputData.name || undefined}
                />
              </ModalBody>
              <ModalFooter>
                <Button mr={3} variant="outline" onClick={onClose}>
                  Abbrechen
                </Button>
                <Button
                  variantColor="green"
                  onClick={async () => {
                    const createInput: CreateMeasuringPointInput = {
                      name: inputData.name,
                      measuringPointProjectId: projectID,
                      project: projectID,
                    };
                    await API.graphql(
                      graphqlOperation(createMeasuringPoint, {
                        input: createInput,
                      })
                    );
                    setSuccess(true);
                    setTimeout(() => {
                      onClose();
                      setSuccess(false);
                    }, 1000);
                  }}
                >
                  OK
                </Button>
              </ModalFooter>
            </>
          )}
        </ModalContent>
      </Modal>
    </>
  );
};

export default CreateMeasuringPoint;
