import { isBefore, isFuture, isPast } from "date-fns";
import { fft } from "ezfft";

import theme from "../theme";
import {
  Amps,
  BackgroundData,
  Device,
  EventData,
  EventDataDetail,
  MeasuringPointDevice,
  MPDRange,
  Peaks,
} from "../types";
import { dateToTimestamp, dbTimeToDate } from "./time";

export const getActiveDevices = (
  mpds: Array<MeasuringPointDevice>
): Array<MeasuringPointDevice> => {
  if (mpds.length === 0) {
    return [];
  }
  return mpds.filter((mpd) => {
    const start = mpd.startDate ? dbTimeToDate(mpd.startDate) : Infinity;
    const end = mpd.endDate ? dbTimeToDate(mpd.endDate) : Infinity;
    const now = new Date();
    return start < now && now < end;
  });
};

export const getActiveInRange = (
  mpds: Array<MeasuringPointDevice>,
  earliest?: Date | null,
  latest?: Date | null
): Array<MeasuringPointDevice> => {
  if (mpds.length === 0) {
    return [];
  }
  if (!earliest && !latest) {
    return mpds;
  }
  return mpds.filter((mpd) => {
    const mStart = mpd.startDate ? dbTimeToDate(mpd.startDate) : Infinity;
    const mEnd = mpd.endDate ? dbTimeToDate(mpd.endDate) : Infinity;
    const rStart = earliest ? earliest : -Infinity;
    const rEnd = latest ? latest : Infinity;
    if (rStart <= mStart && mStart <= rEnd) {
      return true;
    }
    if (rStart <= mEnd && mEnd <= rEnd) {
      return true;
    }
    return false;
  });
};

export const dateInRange = (d: Date, earliest?: Date | null, latest?: Date | null) => {
  if (!earliest && !latest) {
    return false;
  }
  const start = earliest ? earliest : -Infinity;
  const end = latest ? latest : Infinity;
  const inRange = start <= d && d <= end;
  return inRange;
};

export const transformEventDetailsToPeaks = (inputs: Array<EventDataDetail>): Peaks | null => {
  const time: Peaks["time"] = [];
  const vX: Peaks["vX"] = [];
  const vY: Peaks["vY"] = [];
  const vZ: Peaks["vZ"] = [];

  const convertedAndSorted = inputs
    .map((input) => {
      return {
        ...input,
        datatimestamp: dateToTimestamp(dbTimeToDate(input.datatimestamp)),
      };
    })
    .sort((a, b) => {
      if (a.datatimestamp < b.datatimestamp) {
        return -1;
      }
      if (a.datatimestamp > b.datatimestamp) {
        return 1;
      }
      return 0;
    });

  if (convertedAndSorted.length > 0) {
    convertedAndSorted.forEach((input) => {
      time.push(input.datatimestamp);
      vX.push(input.vX || null);
      vY.push(input.vY || null);
      vZ.push(input.vZ || null);
    });
    return { time, vX, vY, vZ };
  }
  return null;
};

export const transformBackgroundToPeaks = (
  backgroundDatas: Array<BackgroundData>
): Peaks | null => {
  const time: Peaks["time"] = [];
  const vX: Peaks["vX"] = [];
  const vY: Peaks["vY"] = [];
  const vZ: Peaks["vZ"] = [];
  if (backgroundDatas.length > 0) {
    backgroundDatas.forEach((backgroundData) => {
      time.push(dateToTimestamp(dbTimeToDate(backgroundData.datatimestamp)));
      vX.push(backgroundData.vX);
      vY.push(backgroundData.vY);
      vZ.push(backgroundData.vZ);
    });
    return { time, vX, vY, vZ };
  }
  return null;
};

export const transformEventDetailsToAmps = (inputs: Array<EventDataDetail>): Amps | null => {
  const peaks = transformEventDetailsToPeaks(inputs);
  if (peaks?.time && peaks?.vX && peaks?.vY && peaks?.vZ) {
    const n = peaks.time.length;
    const tStart = peaks.time[0];
    const tEnd = peaks.time[n - 1];
    const t = tEnd - tStart;
    const samplingRate = n / t;

    const aX = fft(peaks.vX.slice(0, 200), samplingRate);
    const aY = fft(peaks.vY.slice(0, 200), samplingRate);
    const aZ = fft(peaks.vZ.slice(0, 200), samplingRate);

    return {
      time: peaks.time.slice(0, 200),
      aX,
      aY,
      aZ,
    };
  }
  return null;
};

export const roundTwoDigits = (x: number) => {
  return Math.round(x * 100) / 100;
};

export const getStatusColor = (code: number) => {
  // 0 = DEFAULT
  // 1 = OK
  // 2 = WARNING
  // 3 = ERROR
  const { colors } = theme;
  const statusColors = [
    colors.status.default,
    colors.status.ok,
    colors.status.warning,
    colors.status.error,
  ];
  return statusColors[code] || statusColors[0];
};

export const worstStatus = (devices: Device[]) => {
  let worstStatus = 0;
  devices.forEach((device) => {
    if (device.status && device.status > worstStatus) {
      worstStatus = device.status;
    }
  });
  return worstStatus;
};

export const calcVectorLength = (event: EventData) => {
  return roundTwoDigits(Math.sqrt(event.vX * event.vX + event.vY * event.vY + event.vZ * event.vZ));
};

/**
 * Shows if the device measuringpoint assignment is in the past.
 */
export const assignedInPast = (mpd: MeasuringPointDevice) => {
  return mpd.startDate && mpd.endDate && isPast(dbTimeToDate(mpd.endDate));
};

/**
 * Shows if a device is currently assigned.
 */
export const hasAssignedDevice = (mpd: MeasuringPointDevice) => {
  if (
    mpd.startDate &&
      isPast(dbTimeToDate(mpd.startDate)) &&
      (!mpd.endDate || isFuture(dbTimeToDate(mpd.endDate)))
  ) {
    return true;
  }
  return false;
};

export const hasOverlap = (rangesA: MPDRange[], rangesB?: MPDRange[]): boolean => {
  let overlap = false;

  rangesA.forEach((a, idxA) => {
    const aStart = a.startDate ? dateToTimestamp(a.startDate) : 0;
    const aEnd = a.endDate ? dateToTimestamp(a.endDate) : Infinity;

    // Test A with A.
    rangesA.forEach((i, idxI) => {
      const iStart = i.startDate ? dateToTimestamp(i.startDate) : 0;
      const iEnd = i.endDate ? dateToTimestamp(i.endDate) : Infinity;
      if (idxA !== idxI) {
        if ((iStart <= aStart && aStart <= iEnd) || (iStart <= aEnd && aEnd <= iEnd)) {
          overlap = true;
        }
      }
    });

    if (rangesB) {
      // Test A with B.
      rangesB.forEach((i) => {
        const iStart = i.startDate ? dateToTimestamp(i.startDate) : 0;
        const iEnd = i.endDate ? dateToTimestamp(i.endDate) : Infinity;
        if ((iStart <= aStart && aStart <= iEnd) || (iStart <= aEnd && aEnd <= iEnd)) {
          overlap = true;
        }
      });
    }
  });

  if (rangesB) {
    rangesB.forEach((b, idxB) => {
      const bStart = b.startDate ? dateToTimestamp(b.startDate) : 0;
      const bEnd = b.endDate ? dateToTimestamp(b.endDate) : Infinity;

      // Test B with A.
      rangesA.forEach((i) => {
        const iStart = i.startDate ? dateToTimestamp(i.startDate) : 0;
        const iEnd = i.endDate ? dateToTimestamp(i.endDate) : Infinity;
        if ((iStart <= bStart && bStart <= iEnd) || (iStart <= bEnd && bEnd <= iEnd)) {
          overlap = true;
        }
      });

      // Test B with B.
      rangesB.forEach((i, idxI) => {
        const iStart = i.startDate ? dateToTimestamp(i.startDate) : 0;
        const iEnd = i.endDate ? dateToTimestamp(i.endDate) : Infinity;

        if (idxB !== idxI) {
          if ((iStart <= bStart && bStart <= iEnd) || (iStart <= bEnd && bEnd <= iEnd)) {
            overlap = true;
          }
        }
      });
    });
  }

  return overlap;
};

export const hasWrongOrder = (ranges: MPDRange[]) => {
  let wrongOrder = false;

  for (const r of ranges) {
    if (r.startDate && r.endDate) {
      if (isBefore(r.endDate, r.startDate)) {
        wrongOrder = true;
      }
    }
  }
  return wrongOrder;
};
