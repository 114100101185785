import { Stack, Text } from "@chakra-ui/core";
import React from "react";

interface FilterToggleProps {
  onClick: (active: boolean | number | undefined) => void;
  options: Array<{ name: string; value: boolean | number | undefined }>;
  selectedId: boolean | number | undefined | null;
}

const FilterToggle = ({ onClick, options, selectedId }: FilterToggleProps) => (
  <Stack isInline spacing={2}>
    {options.map((option) => (
      <Text
        key={"" + option.value}
        cursor="pointer"
        onClick={() => {
          onClick(option.value);
        }}
        fontWeight={selectedId === option.value ? "bold" : "normal"}
      >
        {option.name}
      </Text>
    ))}
  </Stack>
);

export default FilterToggle;
