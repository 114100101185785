export const measuringPointDeviceHistory = /* GraphQL */ `
  query MeasuringPointDeviceHistory(
    $filter: ModelMeasuringPointDeviceFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listMeasuringPointDevices(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        MeasuringPoint {
          Project {
            name
          }
          name
        }
        endDate
        startDate
      }
    }
  }
`;

export const deviceDismantling = `
query MyQuery(
    $filter: ModelProjectFilterInput
    $limit: Int
    $nextToken: String
  ) {
  listProjects(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
    items {
      measuringPoints(filter: {deviceReady: {eq: true}}) {
        items {
          name
          Project {
            name
            id
            projectOwner
          }
          deviceReadyTime
          deviceCase
        }
      }
    }
  }
}
`;

export const deviceUtilization = `
query ListUtilization(
    $filter: ModelProjectFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listProjects(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
    items {
      measuringPoints {
        items {
          deviceReadyTime
          devices {
            items {
              id
              endDate
              startDate
            }
          }
        }
      }
      id
      active
      plan
      planEnd
      planNumber
      planStart
    }
  }
}
`;

export const deviceUtilizationDevices = `
query ListUtilizationDevices(
    $filter: ModelDeviceFilterInput
    $limit: Int
    $nextToken: String
  ) {
     listDevices(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
    items{
      id
    }
  }
}
`;

export const shortQuery = `
  query ShortQuery(
    $filter: ModelMeasuringPointDeviceFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listMeasuringPointDevices(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        measuringPoint
        device {
          id
          MeasuringPointDevices {
            items {
              id
              measuringPoint
              device {
                id
                MeasuringPointDevices {
                  nextToken
                }
              }
              MeasuringPoint {
                id
                project
                name
                devices {
                  nextToken
                }
                deviceCase
                deviceReady
                deviceReadyTime
                
                createdAt
                updatedAt
              }
              createdAt
              updatedAt
            }
            nextToken
          }
          name
          status
          active
          lastData
          comment
          createdAt
          updatedAt
        }
        startDate
        endDate
        deviceID

      }
    }
  }
`;

export const shortlistMeasuringPointDevices = /* GraphQL */ `
  query ListMeasuringPointDevices(
    $filter: ModelMeasuringPointDeviceFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listMeasuringPointDevices(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        measuringPoint
        device {
          id
          MeasuringPointDevices {
            items {
              id
              measuringPoint
              device {
                id
                MeasuringPointDevices {
                  nextToken
                }
                name
                status
                active
                lastData
                comment
                createdAt
                updatedAt
              }
              startDate
              endDate
              deviceID
              MeasuringPoint {
                id
                project
                name
                devices {
                  nextToken
                }
                deviceCase
                deviceReady
                deviceReadyTime
                createdAt
                updatedAt
              }
              createdAt
              updatedAt
            }
            nextToken
          }
          name
          status
          active
          lastData
          comment
          createdAt
          updatedAt
        }
        startDate
        endDate
        deviceID
        MeasuringPoint {
          id
          project
          name
          devices {
            items {
              id
              measuringPoint
              device {
                id
                name
                status
                active
                lastData
                comment
                createdAt
                updatedAt
              }
              startDate
              endDate
              deviceID
              createdAt
              updatedAt
            }
            nextToken
          }
          deviceCase
          deviceReady
          deviceReadyTime
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
