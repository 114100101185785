import { API, graphqlOperation } from "aws-amplify";
import { max, min } from "date-fns";
import _ from "lodash";

import { getMeasuringPoint, listBackgroundDatas, listEventDatas } from "../graphql/queries";
import {
  BackgroundData,
  DateFilter,
  EventData,
  MeasuringPoint,
  MeasuringPointDevice,
  Peaks,
} from "../types";
import {
  dateInRange,
  dateToDbTime,
  dbTimeToDate,
  fetchEverything,
  hasOverlap,
  transformBackgroundToPeaks,
} from ".";

export const readMp = async (mpId: string): Promise<MeasuringPoint | null> => {
  const mpData = await API.graphql(graphqlOperation(getMeasuringPoint, { id: mpId }));
  const mp = (mpData as any).data.getMeasuringPoint;
  return mp;
};

export const readData = async (
  mp: MeasuringPoint,
  dateFilter: DateFilter
): Promise<{
  mp: MeasuringPoint;
  peaks: Peaks | null;
  bgDatas: BackgroundData[];
  events: EventData[];
}> => {
  // @ts-ignore
  const activeDevices = mp?.devices?.items.filter((mpd: MeasuringPointDevice) => {
    return hasOverlap(
      [
        {
          startDate: mpd.startDate ? dbTimeToDate(mpd.startDate) : null,
          endDate: mpd.endDate ? dbTimeToDate(mpd.endDate) : null,
        },
      ],
      [{ startDate: dateFilter.earliest ?? null, endDate: dateFilter.latest ?? null }]
    );
  });

  if (!activeDevices[0]) {
    return { mp, peaks: null, bgDatas: [], events: [] };
  }

  // Prepare filter
  let eventCondition = {};
  let backgroundCondition = {};
  if (dateFilter.earliest || dateFilter.latest) {
    if (dateFilter.earliest && dateFilter.latest) {
      eventCondition = {
        between: [dateToDbTime(dateFilter.earliest), dateToDbTime(dateFilter.latest)],
      };
    } else {
      if (dateFilter.earliest) {
        eventCondition = { gt: dateToDbTime(dateFilter.earliest) };
      }
      if (dateFilter.latest) {
        eventCondition = { lt: dateToDbTime(dateFilter.latest) };
      }
    }
    backgroundCondition = eventCondition;
  }

  // Get background data for device id.
  let backgroundDatas: BackgroundData[] = [];
  if (Object.keys(backgroundCondition).length > 0) {
    for (const mpd of activeDevices) {
      backgroundDatas = backgroundDatas.concat(
        (
          await fetchEverything(listBackgroundDatas, "listBackgroundDatas", {
            deviceId: mpd.device.id,
            datatimestamp: backgroundCondition,
          })
        ).filter((bg: BackgroundData) => {
          return dateInRange(
            dbTimeToDate(bg.datatimestamp),
            mpd.startDate
              ? max([
                  dbTimeToDate(mpd.startDate),
                  dateFilter.earliest ? dateFilter.earliest : dbTimeToDate(mpd.startDate),
                ])
              : null,
            mpd.endDate
              ? min([
                  dbTimeToDate(mpd.endDate),
                  dateFilter.latest ? dateFilter.latest : dbTimeToDate(mpd.endDate),
                ])
              : null
          );
        })
      );
    }
  } else {
    for (const mpd of activeDevices) {
      backgroundDatas = backgroundDatas.concat(
        (
          await fetchEverything(listBackgroundDatas, "listBackgroundDatas", {
            deviceId: mpd.device.id,
          })
        ).filter((bg: BackgroundData) => {
          return dateInRange(
            dbTimeToDate(bg.datatimestamp),
            mpd.startDate ? dbTimeToDate(mpd.startDate) : null,
            mpd.endDate ? dbTimeToDate(mpd.endDate) : null
          );
        })
      );
    }
  }

  const peaks = transformBackgroundToPeaks(backgroundDatas);

  const bgDatas = backgroundDatas.length > 0 ? backgroundDatas : [];

  // Get event data for device id.
  let events: EventData[] = [];

  if (Object.keys(eventCondition).length > 0) {
    for (const mpd of activeDevices) {
      events = events.concat(
        (
          await fetchEverything(listEventDatas, "listEventDatas", {
            deviceId: mpd.device.id,
            starttimestamp: eventCondition,
          })
        ).filter((eventData: EventData) => {
          return dateInRange(
            dbTimeToDate(eventData.starttimestamp),
            mpd.startDate ? dbTimeToDate(mpd.startDate) : null,
            mpd.endDate ? dbTimeToDate(mpd.endDate) : null
          );
        })
      );
    }
  } else {
    for (const mpd of activeDevices) {
      events = events.concat(
        (
          await fetchEverything(listEventDatas, "listEventDatas", { deviceId: mpd.device.id })
        ).filter((eventData: EventData) => {
          return dateInRange(
            dbTimeToDate(eventData.starttimestamp),
            mpd.startDate ? dbTimeToDate(mpd.startDate) : null,
            mpd.endDate ? dbTimeToDate(mpd.endDate) : null
          );
        })
      );
    }
  }

  return { mp, peaks, events, bgDatas };
};
