import { API, graphqlOperation } from "aws-amplify";
import { useLocation } from "react-router-dom";

export const useQuery = () => new URLSearchParams(useLocation().search);

export const fetchEverything = async (
  query: string,
  accessor: string,
  variables: Record<string, unknown>
) => {
  // Fetch data in a loop until nextToken is falsy and return the merged result.

  let nextToken = null;
  let items: any = [];

  do {
    const result: any = await API.graphql(
      graphqlOperation(query, {
        ...variables,
        nextToken,
        limit: 10000,
      })
    );

    items = items.concat(result.data[accessor].items);
    nextToken = result.data[accessor].nextToken;
  } while (nextToken);

  return items;
};
