import create  from "zustand"
import { Setting } from "../types";
import { readSettings } from "../utils/settings";

interface SettingsState extends Record<string, unknown>{
  settings: Setting[];
  loadSettings:() => void;
  projectOwners: string[];
};


const initialState = {
  settings: [],
  projectOwners: [],
  loading: true
};

export const useSettingsStore = create<SettingsState>((set, get) => ({
  ...initialState,
  loadSettings: async () => {
    set({settings: await readSettings(), loading: false})
  }
}))



const filterPo = (settings: Setting[]):string[] => {
  let po = [""]
  if(settings?.length > 0){
    settings.forEach((item:Setting | null) => {
      if(item?.type === "project-owner" && item.value){
       po = item.value.split(";").filter(e => e.length > 0)
      }
    })
  }
  return po
}


useSettingsStore.subscribe((settings: Setting[]) => {
  useSettingsStore.setState({projectOwners: filterPo(settings)})
},
  (state ) => state.settings
  )