import { Heading, Spinner, Stack } from "@chakra-ui/core";
import { API, graphqlOperation } from "aws-amplify";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { withRouter } from "react-router-dom";

import { CreateProjectInput } from "../API";
import FilterToggle from "../components/FilterToggle";
import Metrics from "../components/Metrics";
import ProjectTable from "../components/ProjectTable";
import { SearchBar } from "../components/SearchBar";
import UpsertProject from "../components/UpsertProject";
import { createProject } from "../graphql/mutations";
import {
  onCreateMeasuringPoint,
  onCreateMeasuringPointDevice,
  onCreateProject,
  onDeleteMeasuringPoint,
  onDeleteMeasuringPointDevice,
  onDeleteProject,
  onUpdateMeasuringPoint,
  onUpdateMeasuringPointDevice,
  onUpdateProject,
} from "../graphql/subscriptions";
import { Layout } from "../Layout";
import { useProjectStore } from "../store/project-store";
import { useSettingsStore } from "../store/settings-store";
import subQuery from "../subQuery";

const Overview = () => {
  const { projects, projectFilter, setProjectFilter, loading, loadProjects } = useProjectStore();

  const { loadSettings } = useSettingsStore();
  const { t } = useTranslation();
  useEffect(() => {
    loadProjects();
    loadSettings();

    subQuery(
      [
        onCreateProject,
        onUpdateProject,
        onDeleteProject,
        onCreateMeasuringPoint,
        onUpdateMeasuringPoint,
        onDeleteMeasuringPoint,
        onCreateMeasuringPointDevice,
        onUpdateMeasuringPointDevice,
        onDeleteMeasuringPointDevice,
      ],
      loadProjects
    );
  }, []);

  return (
    <Layout>
      <Stack spacing={4}>
        <Metrics />
        <Stack rounded="lg" shadow="sm" bg="white" px="10" py="6" spacing="5">
          <Stack isInline align="center" justify="space-between">
            <Heading as="h2" size="lg">
              {t("Projektverwaltung")}
            </Heading>
            <UpsertProject
              title={t("Neues Projekt")}
              triggerText={t("Projekt erstellen")}
              submit={async (input: CreateProjectInput, successCb: () => void) => {
                await API.graphql(graphqlOperation(createProject, { input }));
                successCb();
              }}
            />
          </Stack>
          <Stack isInline alignItems="center" justify="space-between">
            <FilterToggle
              onClick={(active: boolean | number | undefined) => {
                if (active === true) {
                  setProjectFilter({ ...projectFilter, active: true });
                } else if (active === undefined) {
                  setProjectFilter({ ...projectFilter, active: undefined });
                } else if (active === false) {
                  setProjectFilter({ ...projectFilter, active: false });
                }
              }}
              options={[
                { name: `${t("Aktive")}`, value: true },
                { name: `${t("Alle")}`, value: undefined },
                { name: `${t("Archiviert")}`, value: false },
              ]}
              selectedId={projectFilter.active}
            />
            <SearchBar
              value={projectFilter.search}
              onChange={(e) => {
                const term = e?.target?.value;
                if (term) {
                  setProjectFilter({ ...projectFilter, search: term });
                } else {
                  setProjectFilter({ ...projectFilter, search: "" });
                }
              }}
              onClear={() => {
                setProjectFilter({ ...projectFilter, search: "" });
              }}
            />
          </Stack>
          {loading ? <Spinner mx="auto" /> : <ProjectTable projects={projects} />}
        </Stack>
      </Stack>
    </Layout>
  );
};

export default withRouter(Overview);
