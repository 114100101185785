import { formatRelative, getTime } from "date-fns";
import { utcToZonedTime, zonedTimeToUtc } from "date-fns-tz";
import { de } from "date-fns/locale";
import Moment from "moment"
import { extendMoment } from "moment-range"
import {UtilizationResponse, WeekObject, WeekUtilization} from "../types";

// @ts-ignore
const moment = extendMoment(Moment);
const timezone = "Europe/Berlin";

export const timestampToString = (timestamp: number, format?: string): string => {
  if (format) {
    return moment.unix(timestamp).format(format);
  }
  return moment.unix(timestamp).format("DD.MM.YYYY, HH:mm");
};

export const stringToTimestamp = (date: string): number => moment(date, "DD.MM.YYYY, HH:mm").unix();

export const dateToTimestamp = (date: Date): number => getTime(date) / 1000;

export const timestampToDate = (ts: number): Date => moment.unix(ts).toDate();

export const dateToString = (date: Date, format?: string): string => {
  if (format) {
    return timestampToString(dateToTimestamp(date), format);
  }
  return timestampToString(dateToTimestamp(date));
};

export const dbTimeToDate = (dbTime: string) => {
  const output = utcToZonedTime(dbTime, timezone);
  return output;
};

export const dateToDbTime = (date: Date) => {
  const utcDate = zonedTimeToUtc(date, timezone);
  const output = utcDate.toISOString();
  return output;
};

export const dateToHuman = (date: Date) => {
  return formatRelative(date, new Date(), { locale: de, weekStartsOn: 1 });
};

export const stringToIsecon = (date: string) => {
  const parsed = new Date(date);
  const formatted = dateToString(parsed, "YYYY-MM-DD HH:mm:ss.SSSSSSZ");
  return formatted;
};

export const stringToExport = (date: string) => {
  const parsed = new Date(date);
  const formatted = dateToString(parsed, "DD.MM.YYYY HH:mm:ss");
  return formatted;
};


export const calendarWeekToDbDate = (year: string, calendarWeek: string) => {
  moment().locale("de")
  let yearInt = parseInt(year)
  let calendarWeekInt = parseInt(calendarWeek)
  let date = moment().set("year", yearInt).set("isoWeek", calendarWeekInt).toDate()
  return dateToDbTime(date);

}


export const getWeeksDates = (start:string  | null = null, end:string |null = null):WeekObject[] => {
  moment().locale("de")
  let currentDate = start ? moment(start):moment()
  let endDate = end ? moment(end): moment().add(52, "weeks")
  let weeks = []
  while(currentDate.isSameOrBefore(endDate)){
    let start = currentDate.startOf('isoWeek').toISOString()
    let end = currentDate.endOf("isoWeek").toISOString()
    weeks.push({
      calendarWeek: currentDate.isoWeek(),
      year: currentDate.year(),
      start: start,
      end:  end
    });
    currentDate.add(7, "days")
  }
  return weeks
}


export const calculateOccupation = (occupiedStart: string | undefined | null, occupiedEnd: string | undefined | null, weekStartS: string, weekEndS: string) => {

  let weekStart = moment(weekStartS)
  let weekEnd = moment(weekEndS)

  let start = occupiedStart ? moment(occupiedStart) : moment(weekStart).startOf("isoWeek")
  let end = occupiedEnd ? moment(occupiedEnd) : moment().add(100, "years")

  const weekRange = moment.range(weekStart, weekEnd)

  const projectRange = moment.range(start, end)

  if(projectRange.overlaps(weekRange, {adjacent: true}) ){
    return 1
  } else {
    return 0
  }
}

export const dbDateToCalendarWeek = (date:string) => {
  let dateInt = moment(dbTimeToDate(date))
  return {
    year: dateInt.get("year"),
    week: dateInt.isoWeek()
  }
}

export const getWeeksToYear = (year: number) => {
  moment().locale("de")
  const currentDate = moment();
  let weeks = [];
  if (currentDate.get("year") === year) {
    let currentWeek = currentDate.isoWeek();
    const lastWeek = currentDate.isoWeeksInYear();
    while (currentWeek <= lastWeek) {
      weeks.push(currentWeek);
      currentWeek++;
    }
  } else {
    let weekOfYear = 1;
    const lastWeekOfYear = moment().set("year", year).isoWeeksInYear();
    while (weekOfYear <= lastWeekOfYear) {
      weeks.push(weekOfYear);
      weekOfYear++;
    }
  }
  return weeks;
};

export const getNextYears = (amount = 2) => {
  moment().locale("de")
  const years = [];
  const currentDate = moment();
  let count = 0;
  while (count <= amount) {
    years.push(currentDate.get("year"));
    currentDate.add(1, "years");
    count++;
  }
  return years;
};


export const getUsageByWeeks = (utilResp : UtilizationResponse,start:string  | null = null, end:string |null = null, additionalPlannedPerWeek: number = 0):WeekUtilization[] => {

  let weeks = getWeeksDates(start, end)

  const devices = utilResp.devices
  const projects = utilResp.projects


  let columnObjects:WeekUtilization[] = []

  weeks.forEach(week => {
    let weekStart = moment(week.start)
    let weekEnd = moment(week.end)
    let devicesPlanned = additionalPlannedPerWeek;
    let devicesUsed = 0;

    projects.forEach(project => {
      if(project.plan === true){
        if(calculateOccupation(project.planStart, project.planEnd, weekStart.toISOString(), weekEnd.toISOString()) === 1){
          devicesPlanned = devicesPlanned + (project.planNumber ? project.planNumber : 0);
        }
      } else if(project.active === 1){

        let startDateToCheck = project.planStart //? project.planStart : moment().startOf("isoWeek").toISOString()
        let endDateToCheck = project.planEnd

        project.measuringPoints.items.forEach(mp => {
          mp.devices.items.forEach(device => {
            //wenn das enddatum von Device vor dem Enddatum des Projekts liegt bzw. das device ein enddatum hat und das projekt nicht
            /*if(((device.endDate && endDateToCheck ) && moment(device.endDate).isSameOrBefore(moment(endDateToCheck))) || device.endDate && !endDateToCheck) endDateToCheck = device.endDate
            devicesUsed = devicesUsed + calculateOccupation(startDateToCheck, endDateToCheck, weekStart.toISOString(), weekEnd.toISOString())*/

            if(!device.endDate){
              devicesUsed = devicesUsed + calculateOccupation(startDateToCheck, endDateToCheck, weekStart.toISOString(), weekEnd.toISOString())
            }
          })
        })
      }
    })

    let neededAmount = devices.length - (devicesPlanned + devicesUsed)

    columnObjects.push({
      calendarWeek: `KW ${week.calendarWeek} | ${week.year}`,
      assignedDevices: devicesUsed,
      plannedDevices: devicesPlanned,
      utilization: Math.round(((devicesPlanned + devicesUsed) / devices.length * 100 + Number.EPSILON) * 100) / 100,
      needed: neededAmount < 0 ? Math.abs(neededAmount) : 0
    })
  });

  return columnObjects

}