/* eslint-disable react/display-name */
import { Box, Heading, Icon, Link, Stack, Text } from "@chakra-ui/core";
import { API, graphqlOperation } from "aws-amplify";
import _ from "lodash";
import moment from "moment";
import React from "react";
import DataTable from "react-data-table-component";
import { useTranslation } from "react-i18next";
import { FaBriefcase } from "react-icons/fa";
import { useHistory } from "react-router-dom";
import { Table } from "reactstrap";

import { UpdateProjectInput } from "../API";
import CreateMeasuringPoint from "../components/CreateMeasuringPoint";
import UpsertProject from "../components/UpsertProject";
import { updateProject } from "../graphql/mutations";
import { useProjectStore } from "../store/project-store";
import { MeasuringPointDevice, Project } from "../types";
import {
  dateToHuman,
  dbTimeToDate,
  getActiveDevices,
  hasAssignedDevice,
  worstStatus,
} from "../utils";
import DeleteMeasuringPoint from "./DeleteMeasuringPoint";
import DeleteProject from "./DeleteProject";
import Status from "./Status";
import UpdateMeasuringPoint from "./UpdateMeasuringPoints/UpdateMeasuringPoint";

interface ProjectTableProps {
  projects: any[];
}

const MeasuringPointRow = ({ data }: any) => {
  const { t } = useTranslation();
  const measuringPoints = data.measuringPoints.items;
  const projectID = data.id;
  const history = useHistory();

  if (measuringPoints.length > 0) {
    return (
      <Stack isInline>
        <Stack justify="center" w="48px" py={4} px={4}>
          <CreateMeasuringPoint projectID={projectID} />
        </Stack>
        <Box w="full">
          <Table responsive striped>
            <thead>
              <tr>
                <th>{t("Messstelle")}</th>
                <th>{t("Messgerät")}</th>
                <th>Timeout</th>
                <th>{t("Letzte Übertragung")}</th>
                <th></th>
                <th>{t("Bearbeiten")}</th>
              </tr>
            </thead>
            <tbody>
              {measuringPoints.map((measuringPoint: any) => {
                const lastDbTime = _.head(getActiveDevices(measuringPoint?.devices.items))?.device
                  ?.lastData;
                const lastDataHuman = lastDbTime ? dateToHuman(dbTimeToDate(lastDbTime)) : null;
                return (
                  <tr key={measuringPoint.id}>
                    <td>
                      <Link
                        onClick={() => {
                          history.push(`/mp/${measuringPoint.id}`);
                        }}
                      >
                        <Stack isInline align="center">
                          <Icon name="external-link" />
                          <Text>{measuringPoint.name}</Text>
                        </Stack>
                      </Link>
                    </td>
                    <td>
                      <span>
                        {_.head(getActiveDevices(measuringPoint?.devices.items))?.device?.name}
                      </span>
                    </td>
                    <td>
                      <Status
                        code={worstStatus(
                          measuringPoint?.devices.items
                            .filter((mpd: MeasuringPointDevice) => hasAssignedDevice(mpd))
                            .map((mpd: MeasuringPointDevice) => mpd.device)
                        )}
                      />
                    </td>
                    <td>
                      <div>
                        <span>{lastDataHuman}</span>
                      </div>
                    </td>
                    <td>
                      <Stack align={"center"} justify={"center"} isInline>
                        {measuringPoint.deviceCase && (
                          <Box
                            justifyContent={"center"}
                            alignItems={"center"}
                            fontSize={"lg"}
                            as={FaBriefcase}
                          ></Box>
                        )}
                        {measuringPoint.deviceReady && (
                          <Box py={2} justifyContent={"center"} alignItems={"center"}>
                            <Text textAlign={"center"} fontSize={"2xl"} color={"green.500"}>
                              F
                            </Text>
                          </Box>
                        )}
                      </Stack>
                    </td>
                    <td>
                      <UpdateMeasuringPoint measuringPoint={measuringPoint} />
                      <DeleteMeasuringPoint measuringPoint={measuringPoint} />
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </Table>
        </Box>
      </Stack>
    );
  }
  return (
    <Stack isInline justify="center" align="center">
      <CreateMeasuringPoint projectID={projectID} />
      <Text textAlign="center" p={12}>
        {t("Keine Messpunkte zugeordnet")}
      </Text>
    </Stack>
  );
};

const ProjectTable = ({ projects }: ProjectTableProps) => {
  const { expandedProjects, expandProject, unexpandProject } = useProjectStore();
  const { t } = useTranslation();
  const columns = [
    {
      name: "Name",
      selector: "name",
      sortable: true,
      width: "450px",
    },
    {
      name: `${t("Messstellen")}`,
      selector: "measuringPointsCount",
      sortable: true,
      width: "120px",
      center: true,
    },
    {
      name: "Timeout",
      selector: "status",
      sortable: true,
      center: true,
      width: "120px",
      cell: (row: any) => {
        return (
          <Box pr={4}>
            <Status code={row.status} />
          </Box>
        );
      },
    },
    {
      name: `${t("Geräte")}`,
      selector: "devicesCount",
      sortable: true,
      center: true,
      width: "120px",
    },
    {
      name: `${t("Notizen")}`,
      selector: "comment",
      sortable: true,
      cell: (row: any) => {
        return <Text isTruncated>{row.comment}</Text>;
      },
      width: "120px",
    },
    {
      name: `${t("Projektleiter")}`,
      selector: "projectOwner",
      sortable: true,
      center: true,
      width: "70px",
    },
    {
      name: "Info",
      center: true,
      cell: (row: any) => {
        if (row.planEnd) {
          const currentDate = moment();
          const endDate = moment(row.planEnd);

          if (currentDate.isAfter(endDate))
            return (
              <Box py={2} justifyContent={"center"} alignItems={"center"}>
                <Text textAlign={"center"} fontSize={"2xl"} color={"red.500"}>
                  P
                </Text>
              </Box>
            );
        }
        return null;
      },
    },

    {
      name: `${t("Bearbeiten")}`,
      selector: "",
      sortable: false,
      right: true,
      cell: (row: any) => (
        <>
          <UpsertProject
            project={row}
            icon="settings"
            title="Projekt bearbeiten"
            submit={async (input: UpdateProjectInput, successCb: () => void) => {
              await API.graphql(graphqlOperation(updateProject, { input }));
              successCb();
            }}
          />
          <DeleteProject project={row} />
        </>
      ),
    },
  ];

  if (projects.length > 0) {
    const transformedProjects = projects.map((project: Project) => {
      // @ts-ignore
      const measuringPoints = project.measuringPoints?.items;
      let devicesCount = 0;

      // Set worst status to 2 (OK) and lower it every time a device with worse status is found.
      let statusCode = 0;

      measuringPoints.forEach((mp: MeasuringPointDevice) => {
        // @ts-ignore
        const devices = mp.devices.items
          .filter((mpd: MeasuringPointDevice) => hasAssignedDevice(mpd))
          .map((mpd: MeasuringPointDevice) => mpd.device);
        devicesCount += devices.length;
        if (worstStatus(devices) > statusCode) {
          statusCode = worstStatus(devices);
        }
      });

      return {
        ...project,
        measuringPointsCount: measuringPoints?.length || 0,
        status: devicesCount === 0 || project.active === 0 ? 0 : statusCode,
        keyField: project.id,
        devicesCount,
      };
    });

    return (
      <DataTable
        data={transformedProjects}
        columns={columns}
        noHeader
        expandableRows
        expandableRowExpanded={(row) => {
          return expandedProjects.includes(row.id);
        }}
        onRowExpandToggled={(expanded, row) => {
          if (!expanded) {
            unexpandProject(row.id);
          } else {
            expandProject(row.id);
          }
        }}
        expandableRowsComponent={<MeasuringPointRow />}
      />
    );
  }
  return null;
};

export default ProjectTable;
