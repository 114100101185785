/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable react/display-name */
import { Box, Icon, Link, Stack, Text } from "@chakra-ui/core";
import { API, graphqlOperation } from "aws-amplify";
import _ from "lodash";
import React from "react";
import DataTable from "react-data-table-component";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";

import { UpdateDeviceInput } from "../API";
import { updateDevice } from "../graphql/mutations";
import { useProjectStore } from "../store/project-store";
import { dateToHuman, dbTimeToDate } from "../utils";
import { getDeviceMapping } from "../utils/projects";
import DeleteDevice from "./DeleteDevice";
import DeviceHistoryModal from "./DeviceHistory/DeviceHistoryModal";
import Status from "./Status";
import UpsertDevice from "./UpsertDevice";

const DeviceTable = ({ devices, projects }: any) => {
  const history = useHistory();
  const { expandProject, setProjectFilter } = useProjectStore();
  const { t } = useTranslation();
  const deviceMapping = getDeviceMapping(projects);

  const columns = [
    {
      name: "Name",
      selector: "name",
      sortable: true,
      width: "200px",
    },
    {
      name: "Projekt",
      selector: "project",
      sortable: true,
      cell: (row: any) => {
        const projectName = _.get(deviceMapping, `${row.id}.project.name`, "");
        const projectId = _.get(deviceMapping, `${row.id}.project.id`, "");
        return projectId ? (
          <Link
            src="/"
            onClick={(e: React.MouseEvent<HTMLAnchorElement>) => {
              e.preventDefault();
              expandProject(projectId, true);
              setProjectFilter({ active: undefined });
              history.push("/");
            }}
          >
            <Stack isInline align="center">
              <Icon name="external-link" />
              <Text>{projectName}</Text>
            </Stack>
          </Link>
        ) : null;
      },
    },
    {
      name: `${t("Messstelle")}`,
      selector: "measuringPoint",
      sortable: true,
      cell: (row: any) => {
        const mpName = _.get(deviceMapping, `${row.id}.mp.name`, "");
        const mpId = _.get(deviceMapping, `${row.id}.mp.id`, "");
        return mpId ? (
          <Link
            src={`/mp/${mpId}`}
            onClick={(e: React.MouseEvent<HTMLAnchorElement>) => {
              e.preventDefault();
              history.push(`/mp/${mpId}`);
            }}
          >
            <Stack isInline align="center">
              <Icon name="external-link" />
              <Text>{mpName}</Text>
            </Stack>
          </Link>
        ) : null;
      },
    },
    {
      name: `${t("Seriennummer")} / ID`,
      selector: "id",
      sortable: true,
      width: "350px",
    },
    {
      name: `${t("Letzte Übertragung")}`,
      selector: "lastData",
      sortable: true,
      cell: (row: any) => {
        if (row.lastData) {
          return <Text>{dateToHuman(dbTimeToDate(row.lastData))}</Text>;
        }
        return null;
      },
    },
    {
      name: "Timeout",
      selector: "status",
      sortable: true,
      center: true,
      width: "100px",
      cell: (row: any) => (
        <Box pr={3}>
          <Status code={row.status} />
        </Box>
      ),
    },
    {
      name: `${t("Historie")}`,
      center: true,
      width: "100px",
      cell: (row: any) => (
        <Box pr={3}>
          <DeviceHistoryModal
            device={row}
            icon={"info-outline"}
            title={"Geräte Historie"}
          ></DeviceHistoryModal>
        </Box>
      ),
    },
    {
      name: `${t("Bearbeiten")}`,
      selector: "",
      sortable: false,
      right: true,
      width: "100px",
      cell: (row: any) => (
        <>
          <UpsertDevice
            device={row}
            icon="settings"
            title={t("Gerät bearbeiten")}
            submit={async (input: UpdateDeviceInput, successCb: Function) => {
              await API.graphql(graphqlOperation(updateDevice, { input }));
              successCb();
            }}
          />
          <DeleteDevice device={row} />
        </>
      ),
    },
  ];

  if (devices.length > 0) {
    const transformedDevices = devices.map((device: any) => ({
      ...device,
      keyField: device.id,
      status: device.status,
    }));

    return <DataTable data={transformedDevices} columns={columns} noHeader pagination />;
  }
  return null;
};

export default DeviceTable;
