import { Spinner } from "@chakra-ui/core";
import { API, graphqlOperation } from "aws-amplify";
import React, { useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import { useTranslation } from "react-i18next";

import { CreateProjectInput } from "../../API";
import { updateProject } from "../../graphql/mutations";
import { dbDateToCalendarWeek } from "../../utils";
import DeleteProject from "../DeleteProject";
import UpsertPlannedProject from "./UpsertPlannedProject";

interface PlannedProjectTableProps {
  plannedProjects: any[];
  loading: boolean;
}
const PlanningTable = ({ plannedProjects, loading }: PlannedProjectTableProps) => {
  const { t } = useTranslation();
  const columns = [
    {
      name: "Projekt",
      sortable: true,
      selector: "name",
    },
    {
      name: "Von KW",
      sortable: true,
      selector: "planStart",
      cell: (row: any) => {
        return row.planStart
          ? `${dbDateToCalendarWeek(row.planStart).week} | ${
              dbDateToCalendarWeek(row.planStart).year
            }`
          : `${t("Nicht angegeben")}`;
      },
    },
    {
      name: "Bis KW",
      sortable: true,
      selector: "planEnd",
      cell: (row: any) => {
        return row.planEnd
          ? `${dbDateToCalendarWeek(row.planEnd).week} | ${dbDateToCalendarWeek(row.planEnd).year}`
          : "Nicht angegeben";
      },
    },
    {
      name: `${t("Geräte")}`,
      selector: "deviceCount",
      sortable: true,
      cell: (row: any) => {
        return row.planNumber ? row.planNumber : "Nicht angegeben";
      },
    },
    {
      name: `${t("Bearbeiten")}`,
      sortable: false,
      right: true,
      width: "100px",
      cell: (row: any) => (
        <>
          <UpsertPlannedProject
            project={row}
            title={"Planung bearbeiten"}
            icon="settings"
            submit={async (input: CreateProjectInput, successCb: () => void) => {
              await API.graphql(graphqlOperation(updateProject, { input }));
              successCb();
            }}
          />
          <DeleteProject project={row} />
        </>
      ),
    },
  ];
  if (loading) {
    return <Spinner mx={"auto"} />;
  }
  if (plannedProjects.length > 0) {
    return (
      <DataTable
        defaultSortField={"planStart"}
        defaultSortAsc={true}
        pagination={true}
        columns={columns}
        data={plannedProjects}
        sortFunction={(rows, field, sortDirection) => {
          if (field === "planStart" || field === "planEnd") {
            rows.sort((a, b) => {
              if (sortDirection === "asc") {
                // @ts-ignore
                return new Date(a[field]) - new Date(b[field]);
              } else {
                // @ts-ignore
                return new Date(b[field]) - new Date(a[field]);
              }
            });
          }

          if (field === "deviceCount") {
            rows.sort((a, b) => {
              if (sortDirection === "asc") {
                return a.planNumber - b.planNumber;
              } else {
                return b.planNumber - a.planNumber;
              }
            });
          }

          if (field === "name") {
            rows.sort((a, b) => {
              if (sortDirection === "asc") {
                return b.name.localeCompare(a.name, "de", { sensitivity: "base" });
              } else {
                return a.name.localeCompare(b.name, "de", { sensitivity: "base" });
              }
            });
          }
          return rows.slice(0);
        }}
      />
    );
  } else {
    return <p>{t("Kein geplanten Projekte")}</p>;
  }
};

export default PlanningTable;
