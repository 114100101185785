import { graphqlOperation, API } from "aws-amplify";

export default function subQuery(queries: Array<string>, cb: Function) {
  queries.map((query: string) => {
    // @ts-ignore
    API.graphql(graphqlOperation(query)).subscribe({
      next: () => {
        cb();
      },
    });
    return true;
  });
}
