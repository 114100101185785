import "./i18n";

import Amplify from "aws-amplify";
import React from "react";
import ReactDOM from "react-dom";

import App from "./App";
import awsconfig from "./aws-exports";
import { zipExportEndpoints } from "./endpoints";

Amplify.configure({
  ...awsconfig,
  API: {
    endpoints: [
      {
        name: "events-zip-export",
        endpoint:
          process.env.REACT_APP_ENV === "production"
            ? zipExportEndpoints.production
            : zipExportEndpoints.dev,
      },
    ],
  },
});

ReactDOM.render(<App />, document.getElementById("root"));
