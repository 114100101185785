import React from "react";
import { useTranslation } from "react-i18next";
import { Route, Router, Switch } from "react-router-dom";

import { history } from "./history";
import Devices from "./views/Devices";
import EventDetailScreen from "./views/EventDetailScreen";
import MeasuringPointScreen from "./views/MeasuringPointScreen";
import Overview from "./views/Overview";
import Planner from "./views/Planner";
import Settings from "./views/Settings";

const AppRouter = () => {
  return (
    <Router history={history}>
      <Switch>
        <Route exact path="/" component={Overview} />
        <Route exact path="/devices" component={Devices} />
        <Route exact path="/planner" component={Planner} />
        <Route exact path="/mp/:mpId" component={MeasuringPointScreen} />
        <Route exact path="/event/:deviceId/:starttimestamp" component={EventDetailScreen} />
        <Route exact path="/settings" component={Settings} />
      </Switch>
    </Router>
  );
};

export default AppRouter;
