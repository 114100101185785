import "uplot/dist/uPlot.min.css";

import { Box } from "@chakra-ui/core";
import React, { ComponentProps, FunctionComponent, useEffect, useState } from "react";
import uPlot from "uplot";

interface Props extends ComponentProps<typeof Box> {
  data: any;
  label: string;
  color: string;
  title?: string;
  width?: number;
  height?: number;
}

export const TimeseriesDiagram: FunctionComponent<Props> = ({
  data,
  label,
  color,
  title,
  width,
  height,
  ...props
}) => {
  const rndId = Math.random().toString(36).substr(2, 9);
  const ref = React.useRef();
  const germanNames = {
    MMMM: [
      "Januar",
      "Februar",
      "März",
      "April",
      "Mai",
      "Juni",
      "Juli",
      "August",
      "September",
      "Oktober",
      "November",
      "Dezember",
    ],
    MMM: ["Jan", "Feb", "März", "Apr", "Mai", "Juni", "Juli", "Aug", "Sep", "Okt", "Nov", "Dez"],
    WWWW: ["Montag", "Dienstag", "Mittwoch", "Donnerstag", "Freitag", "Samstag", "Sonntag"],
    WWW: ["Mo", "Di", "Mi", "Do", "Fr", "Sa", "So"],
  };
  // const fmt = uPlot.fmtDate("{DD}.{MM}.{YYYY} {HH}:{mm}:{ss} Uhr");
  // const tzDate = (ts: number) => uPlot.tzDate(new Date(ts - 4 * 3600), "Europe/Berlin");

  const config = {
    title: title,
    id: rndId,
    class: "my-chart",
    width: width ?? 900,
    height: height ?? 250,
    fmtDate: (tpl: string) => uPlot.fmtDate(tpl, germanNames),
    // tzDate,
    axes: [
      {
        values: [
          // tick incr          default           year                             month    day                        hour     min                sec       mode
          [3600 * 24 * 365, "{YYYY}", null, null, null, null, null, null, 1],
          [3600 * 24 * 28, "{MMM}", "\n{YYYY}", null, null, null, null, null, 1],
          [3600 * 24, "{D}.{M}.", "\n{YYYY}", null, null, null, null, null, 1],
          [3600, "{HH}:{mm}", "\n{D}.{M}.{YY}", null, "\n{D}.{M}.", null, null, null, 1],
          [60, "{HH}:{mm}", "\n{D}.{M}.{YY}", null, "\n{D}.{M}.", null, null, null, 1],
          [
            1,
            ":{ss}",
            "\n{D}.{M}.{YY} {HH}:{mm}",
            null,
            "\n{D}.{M}. {HH}:{mm}",
            null,
            "\n{HH}:{mm}",
            null,
            1,
          ],
          [
            0.001,
            ":{ss}.{fff}",
            "\n{D}.{M}.{YY} {HH}:{mm}",
            null,
            "\n{D}.{M}. {HH}:{mm}",
            null,
            "\n{HH}:{mm}",
            null,
            1,
          ],
        ],
      },
      { label: label },
    ],
    series: [
      {
        value: "{DD}.{MM}.{YYYY} {HH}:{mm}:{ss} Uhr",
      },
      {
        // initial toggled state (optional)
        // show: true,
        spanGaps: false,
        // in-legend display
        label: label,
        // value: (self, rawValue) => "$" + rawValue.toFixed(2),
        // series style
        stroke: color,
        width: 1,
        // fill: color,
      },
    ],
  };
  const [instance, setInstance] = useState<any>(null);

  useEffect(() => {
    setInstance(new uPlot(config, data, ref.current));
  }, []);

  useEffect(() => {
    if (instance) {
      instance.setData(data);
    }
  }, [data]);

  return <Box {...props} ref={ref} />;
};
