import { Stack } from "@chakra-ui/core";
import React, { FunctionComponent } from "react";

type Props = React.ComponentProps<typeof Stack>;

export const PanelCard: FunctionComponent<Props> = ({ children, ...props }) => {
  return (
    <Stack rounded="lg" w="full" shadow="sm" bg="white" px="10" py="6" spacing="4" {...props}>
      {children}
    </Stack>
  );
};
