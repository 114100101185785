import { Box, Input, InputGroup, InputRightElement } from "@chakra-ui/core";
import React, { FunctionComponent } from "react";
import { useTranslation } from "react-i18next";
import { IoIosClose } from "react-icons/io";
interface Props {
  value?: string;
  onChange: React.ChangeEventHandler<HTMLInputElement>;
  onClear(): void;
}

export const SearchBar: FunctionComponent<Props> = ({ value, onChange, onClear }) => {
  const { t } = useTranslation();
  return (
    <InputGroup>
      <Input w={[32, 64]} placeholder={t("Suchen")} value={value} onChange={onChange} />
      <InputRightElement cursor="pointer">
        <Box
          as={IoIosClose}
          bg="white"
          fontSize="xl"
          color="gray.500"
          onClick={() => {
            onClear();
          }}
        />
      </InputRightElement>
    </InputGroup>
  );
};
