/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createProject = /* GraphQL */ `
  mutation CreateProject(
    $input: CreateProjectInput!
    $condition: ModelProjectConditionInput
  ) {
    createProject(input: $input, condition: $condition) {
      id
      name
      active
      comment
      timeoutActive
      measuringPoints {
        items {
          id
          project
          name
          devices {
            items {
              id
              measuringPoint
              device {
                id
                MeasuringPointDevices {
                  nextToken
                }
                name
                status
                active
                lastData
                comment
                createdAt
                updatedAt
              }
              startDate
              endDate
              deviceID
              MeasuringPoint {
                id
                project
                name
                devices {
                  nextToken
                }
                deviceCase
                deviceReady
                deviceReadyTime
                Project {
                  id
                  name
                  active
                  comment
                  timeoutActive
                  projectOwner
                  plan
                  planStart
                  planEnd
                  planNumber
                  createdAt
                  updatedAt
                }
                createdAt
                updatedAt
              }
              createdAt
              updatedAt
            }
            nextToken
          }
          deviceCase
          deviceReady
          deviceReadyTime
          Project {
            id
            name
            active
            comment
            timeoutActive
            measuringPoints {
              items {
                id
                project
                name
                devices {
                  nextToken
                }
                deviceCase
                deviceReady
                deviceReadyTime
                Project {
                  id
                  name
                  active
                  comment
                  timeoutActive
                  projectOwner
                  plan
                  planStart
                  planEnd
                  planNumber
                  createdAt
                  updatedAt
                }
                createdAt
                updatedAt
              }
              nextToken
            }
            projectOwner
            plan
            planStart
            planEnd
            planNumber
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
        }
        nextToken
      }
      projectOwner
      plan
      planStart
      planEnd
      planNumber
      createdAt
      updatedAt
    }
  }
`;
export const updateProject = /* GraphQL */ `
  mutation UpdateProject(
    $input: UpdateProjectInput!
    $condition: ModelProjectConditionInput
  ) {
    updateProject(input: $input, condition: $condition) {
      id
      name
      active
      comment
      timeoutActive
      measuringPoints {
        items {
          id
          project
          name
          devices {
            items {
              id
              measuringPoint
              device {
                id
                MeasuringPointDevices {
                  nextToken
                }
                name
                status
                active
                lastData
                comment
                createdAt
                updatedAt
              }
              startDate
              endDate
              deviceID
              MeasuringPoint {
                id
                project
                name
                devices {
                  nextToken
                }
                deviceCase
                deviceReady
                deviceReadyTime
                Project {
                  id
                  name
                  active
                  comment
                  timeoutActive
                  projectOwner
                  plan
                  planStart
                  planEnd
                  planNumber
                  createdAt
                  updatedAt
                }
                createdAt
                updatedAt
              }
              createdAt
              updatedAt
            }
            nextToken
          }
          deviceCase
          deviceReady
          deviceReadyTime
          Project {
            id
            name
            active
            comment
            timeoutActive
            measuringPoints {
              items {
                id
                project
                name
                devices {
                  nextToken
                }
                deviceCase
                deviceReady
                deviceReadyTime
                Project {
                  id
                  name
                  active
                  comment
                  timeoutActive
                  projectOwner
                  plan
                  planStart
                  planEnd
                  planNumber
                  createdAt
                  updatedAt
                }
                createdAt
                updatedAt
              }
              nextToken
            }
            projectOwner
            plan
            planStart
            planEnd
            planNumber
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
        }
        nextToken
      }
      projectOwner
      plan
      planStart
      planEnd
      planNumber
      createdAt
      updatedAt
    }
  }
`;
export const deleteProject = /* GraphQL */ `
  mutation DeleteProject(
    $input: DeleteProjectInput!
    $condition: ModelProjectConditionInput
  ) {
    deleteProject(input: $input, condition: $condition) {
      id
      name
      active
      comment
      timeoutActive
      measuringPoints {
        items {
          id
          project
          name
          devices {
            items {
              id
              measuringPoint
              device {
                id
                MeasuringPointDevices {
                  nextToken
                }
                name
                status
                active
                lastData
                comment
                createdAt
                updatedAt
              }
              startDate
              endDate
              deviceID
              MeasuringPoint {
                id
                project
                name
                devices {
                  nextToken
                }
                deviceCase
                deviceReady
                deviceReadyTime
                Project {
                  id
                  name
                  active
                  comment
                  timeoutActive
                  projectOwner
                  plan
                  planStart
                  planEnd
                  planNumber
                  createdAt
                  updatedAt
                }
                createdAt
                updatedAt
              }
              createdAt
              updatedAt
            }
            nextToken
          }
          deviceCase
          deviceReady
          deviceReadyTime
          Project {
            id
            name
            active
            comment
            timeoutActive
            measuringPoints {
              items {
                id
                project
                name
                devices {
                  nextToken
                }
                deviceCase
                deviceReady
                deviceReadyTime
                Project {
                  id
                  name
                  active
                  comment
                  timeoutActive
                  projectOwner
                  plan
                  planStart
                  planEnd
                  planNumber
                  createdAt
                  updatedAt
                }
                createdAt
                updatedAt
              }
              nextToken
            }
            projectOwner
            plan
            planStart
            planEnd
            planNumber
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
        }
        nextToken
      }
      projectOwner
      plan
      planStart
      planEnd
      planNumber
      createdAt
      updatedAt
    }
  }
`;
export const createMeasuringPoint = /* GraphQL */ `
  mutation CreateMeasuringPoint(
    $input: CreateMeasuringPointInput!
    $condition: ModelMeasuringPointConditionInput
  ) {
    createMeasuringPoint(input: $input, condition: $condition) {
      id
      project
      name
      devices {
        items {
          id
          measuringPoint
          device {
            id
            MeasuringPointDevices {
              items {
                id
                measuringPoint
                device {
                  id
                  name
                  status
                  active
                  lastData
                  comment
                  createdAt
                  updatedAt
                }
                startDate
                endDate
                deviceID
                MeasuringPoint {
                  id
                  project
                  name
                  deviceCase
                  deviceReady
                  deviceReadyTime
                  createdAt
                  updatedAt
                }
                createdAt
                updatedAt
              }
              nextToken
            }
            name
            status
            active
            lastData
            comment
            createdAt
            updatedAt
          }
          startDate
          endDate
          deviceID
          MeasuringPoint {
            id
            project
            name
            devices {
              items {
                id
                measuringPoint
                device {
                  id
                  name
                  status
                  active
                  lastData
                  comment
                  createdAt
                  updatedAt
                }
                startDate
                endDate
                deviceID
                MeasuringPoint {
                  id
                  project
                  name
                  deviceCase
                  deviceReady
                  deviceReadyTime
                  createdAt
                  updatedAt
                }
                createdAt
                updatedAt
              }
              nextToken
            }
            deviceCase
            deviceReady
            deviceReadyTime
            Project {
              id
              name
              active
              comment
              timeoutActive
              measuringPoints {
                items {
                  id
                  project
                  name
                  deviceCase
                  deviceReady
                  deviceReadyTime
                  createdAt
                  updatedAt
                }
                nextToken
              }
              projectOwner
              plan
              planStart
              planEnd
              planNumber
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
        }
        nextToken
      }
      deviceCase
      deviceReady
      deviceReadyTime
      Project {
        id
        name
        active
        comment
        timeoutActive
        measuringPoints {
          items {
            id
            project
            name
            devices {
              items {
                id
                measuringPoint
                device {
                  id
                  name
                  status
                  active
                  lastData
                  comment
                  createdAt
                  updatedAt
                }
                startDate
                endDate
                deviceID
                MeasuringPoint {
                  id
                  project
                  name
                  deviceCase
                  deviceReady
                  deviceReadyTime
                  createdAt
                  updatedAt
                }
                createdAt
                updatedAt
              }
              nextToken
            }
            deviceCase
            deviceReady
            deviceReadyTime
            Project {
              id
              name
              active
              comment
              timeoutActive
              measuringPoints {
                items {
                  id
                  project
                  name
                  deviceCase
                  deviceReady
                  deviceReadyTime
                  createdAt
                  updatedAt
                }
                nextToken
              }
              projectOwner
              plan
              planStart
              planEnd
              planNumber
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        projectOwner
        plan
        planStart
        planEnd
        planNumber
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const updateMeasuringPoint = /* GraphQL */ `
  mutation UpdateMeasuringPoint(
    $input: UpdateMeasuringPointInput!
    $condition: ModelMeasuringPointConditionInput
  ) {
    updateMeasuringPoint(input: $input, condition: $condition) {
      id
      project
      name
      devices {
        items {
          id
          measuringPoint
          device {
            id
            MeasuringPointDevices {
              items {
                id
                measuringPoint
                device {
                  id
                  name
                  status
                  active
                  lastData
                  comment
                  createdAt
                  updatedAt
                }
                startDate
                endDate
                deviceID
                MeasuringPoint {
                  id
                  project
                  name
                  deviceCase
                  deviceReady
                  deviceReadyTime
                  createdAt
                  updatedAt
                }
                createdAt
                updatedAt
              }
              nextToken
            }
            name
            status
            active
            lastData
            comment
            createdAt
            updatedAt
          }
          startDate
          endDate
          deviceID
          MeasuringPoint {
            id
            project
            name
            devices {
              items {
                id
                measuringPoint
                device {
                  id
                  name
                  status
                  active
                  lastData
                  comment
                  createdAt
                  updatedAt
                }
                startDate
                endDate
                deviceID
                MeasuringPoint {
                  id
                  project
                  name
                  deviceCase
                  deviceReady
                  deviceReadyTime
                  createdAt
                  updatedAt
                }
                createdAt
                updatedAt
              }
              nextToken
            }
            deviceCase
            deviceReady
            deviceReadyTime
            Project {
              id
              name
              active
              comment
              timeoutActive
              measuringPoints {
                items {
                  id
                  project
                  name
                  deviceCase
                  deviceReady
                  deviceReadyTime
                  createdAt
                  updatedAt
                }
                nextToken
              }
              projectOwner
              plan
              planStart
              planEnd
              planNumber
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
        }
        nextToken
      }
      deviceCase
      deviceReady
      deviceReadyTime
      Project {
        id
        name
        active
        comment
        timeoutActive
        measuringPoints {
          items {
            id
            project
            name
            devices {
              items {
                id
                measuringPoint
                device {
                  id
                  name
                  status
                  active
                  lastData
                  comment
                  createdAt
                  updatedAt
                }
                startDate
                endDate
                deviceID
                MeasuringPoint {
                  id
                  project
                  name
                  deviceCase
                  deviceReady
                  deviceReadyTime
                  createdAt
                  updatedAt
                }
                createdAt
                updatedAt
              }
              nextToken
            }
            deviceCase
            deviceReady
            deviceReadyTime
            Project {
              id
              name
              active
              comment
              timeoutActive
              measuringPoints {
                items {
                  id
                  project
                  name
                  deviceCase
                  deviceReady
                  deviceReadyTime
                  createdAt
                  updatedAt
                }
                nextToken
              }
              projectOwner
              plan
              planStart
              planEnd
              planNumber
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        projectOwner
        plan
        planStart
        planEnd
        planNumber
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const deleteMeasuringPoint = /* GraphQL */ `
  mutation DeleteMeasuringPoint(
    $input: DeleteMeasuringPointInput!
    $condition: ModelMeasuringPointConditionInput
  ) {
    deleteMeasuringPoint(input: $input, condition: $condition) {
      id
      project
      name
      devices {
        items {
          id
          measuringPoint
          device {
            id
            MeasuringPointDevices {
              items {
                id
                measuringPoint
                device {
                  id
                  name
                  status
                  active
                  lastData
                  comment
                  createdAt
                  updatedAt
                }
                startDate
                endDate
                deviceID
                MeasuringPoint {
                  id
                  project
                  name
                  deviceCase
                  deviceReady
                  deviceReadyTime
                  createdAt
                  updatedAt
                }
                createdAt
                updatedAt
              }
              nextToken
            }
            name
            status
            active
            lastData
            comment
            createdAt
            updatedAt
          }
          startDate
          endDate
          deviceID
          MeasuringPoint {
            id
            project
            name
            devices {
              items {
                id
                measuringPoint
                device {
                  id
                  name
                  status
                  active
                  lastData
                  comment
                  createdAt
                  updatedAt
                }
                startDate
                endDate
                deviceID
                MeasuringPoint {
                  id
                  project
                  name
                  deviceCase
                  deviceReady
                  deviceReadyTime
                  createdAt
                  updatedAt
                }
                createdAt
                updatedAt
              }
              nextToken
            }
            deviceCase
            deviceReady
            deviceReadyTime
            Project {
              id
              name
              active
              comment
              timeoutActive
              measuringPoints {
                items {
                  id
                  project
                  name
                  deviceCase
                  deviceReady
                  deviceReadyTime
                  createdAt
                  updatedAt
                }
                nextToken
              }
              projectOwner
              plan
              planStart
              planEnd
              planNumber
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
        }
        nextToken
      }
      deviceCase
      deviceReady
      deviceReadyTime
      Project {
        id
        name
        active
        comment
        timeoutActive
        measuringPoints {
          items {
            id
            project
            name
            devices {
              items {
                id
                measuringPoint
                device {
                  id
                  name
                  status
                  active
                  lastData
                  comment
                  createdAt
                  updatedAt
                }
                startDate
                endDate
                deviceID
                MeasuringPoint {
                  id
                  project
                  name
                  deviceCase
                  deviceReady
                  deviceReadyTime
                  createdAt
                  updatedAt
                }
                createdAt
                updatedAt
              }
              nextToken
            }
            deviceCase
            deviceReady
            deviceReadyTime
            Project {
              id
              name
              active
              comment
              timeoutActive
              measuringPoints {
                items {
                  id
                  project
                  name
                  deviceCase
                  deviceReady
                  deviceReadyTime
                  createdAt
                  updatedAt
                }
                nextToken
              }
              projectOwner
              plan
              planStart
              planEnd
              planNumber
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        projectOwner
        plan
        planStart
        planEnd
        planNumber
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const createMeasuringPointDevice = /* GraphQL */ `
  mutation CreateMeasuringPointDevice(
    $input: CreateMeasuringPointDeviceInput!
    $condition: ModelMeasuringPointDeviceConditionInput
  ) {
    createMeasuringPointDevice(input: $input, condition: $condition) {
      id
      measuringPoint
      device {
        id
        MeasuringPointDevices {
          items {
            id
            measuringPoint
            device {
              id
              MeasuringPointDevices {
                items {
                  id
                  measuringPoint
                  startDate
                  endDate
                  deviceID
                  createdAt
                  updatedAt
                }
                nextToken
              }
              name
              status
              active
              lastData
              comment
              createdAt
              updatedAt
            }
            startDate
            endDate
            deviceID
            MeasuringPoint {
              id
              project
              name
              devices {
                items {
                  id
                  measuringPoint
                  startDate
                  endDate
                  deviceID
                  createdAt
                  updatedAt
                }
                nextToken
              }
              deviceCase
              deviceReady
              deviceReadyTime
              Project {
                id
                name
                active
                comment
                timeoutActive
                measuringPoints {
                  nextToken
                }
                projectOwner
                plan
                planStart
                planEnd
                planNumber
                createdAt
                updatedAt
              }
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        name
        status
        active
        lastData
        comment
        createdAt
        updatedAt
      }
      startDate
      endDate
      deviceID
      MeasuringPoint {
        id
        project
        name
        devices {
          items {
            id
            measuringPoint
            device {
              id
              MeasuringPointDevices {
                items {
                  id
                  measuringPoint
                  startDate
                  endDate
                  deviceID
                  createdAt
                  updatedAt
                }
                nextToken
              }
              name
              status
              active
              lastData
              comment
              createdAt
              updatedAt
            }
            startDate
            endDate
            deviceID
            MeasuringPoint {
              id
              project
              name
              devices {
                items {
                  id
                  measuringPoint
                  startDate
                  endDate
                  deviceID
                  createdAt
                  updatedAt
                }
                nextToken
              }
              deviceCase
              deviceReady
              deviceReadyTime
              Project {
                id
                name
                active
                comment
                timeoutActive
                measuringPoints {
                  nextToken
                }
                projectOwner
                plan
                planStart
                planEnd
                planNumber
                createdAt
                updatedAt
              }
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        deviceCase
        deviceReady
        deviceReadyTime
        Project {
          id
          name
          active
          comment
          timeoutActive
          measuringPoints {
            items {
              id
              project
              name
              devices {
                items {
                  id
                  measuringPoint
                  startDate
                  endDate
                  deviceID
                  createdAt
                  updatedAt
                }
                nextToken
              }
              deviceCase
              deviceReady
              deviceReadyTime
              Project {
                id
                name
                active
                comment
                timeoutActive
                measuringPoints {
                  nextToken
                }
                projectOwner
                plan
                planStart
                planEnd
                planNumber
                createdAt
                updatedAt
              }
              createdAt
              updatedAt
            }
            nextToken
          }
          projectOwner
          plan
          planStart
          planEnd
          planNumber
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const updateMeasuringPointDevice = /* GraphQL */ `
  mutation UpdateMeasuringPointDevice(
    $input: UpdateMeasuringPointDeviceInput!
    $condition: ModelMeasuringPointDeviceConditionInput
  ) {
    updateMeasuringPointDevice(input: $input, condition: $condition) {
      id
      measuringPoint
      device {
        id
        MeasuringPointDevices {
          items {
            id
            measuringPoint
            device {
              id
              MeasuringPointDevices {
                items {
                  id
                  measuringPoint
                  startDate
                  endDate
                  deviceID
                  createdAt
                  updatedAt
                }
                nextToken
              }
              name
              status
              active
              lastData
              comment
              createdAt
              updatedAt
            }
            startDate
            endDate
            deviceID
            MeasuringPoint {
              id
              project
              name
              devices {
                items {
                  id
                  measuringPoint
                  startDate
                  endDate
                  deviceID
                  createdAt
                  updatedAt
                }
                nextToken
              }
              deviceCase
              deviceReady
              deviceReadyTime
              Project {
                id
                name
                active
                comment
                timeoutActive
                measuringPoints {
                  nextToken
                }
                projectOwner
                plan
                planStart
                planEnd
                planNumber
                createdAt
                updatedAt
              }
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        name
        status
        active
        lastData
        comment
        createdAt
        updatedAt
      }
      startDate
      endDate
      deviceID
      MeasuringPoint {
        id
        project
        name
        devices {
          items {
            id
            measuringPoint
            device {
              id
              MeasuringPointDevices {
                items {
                  id
                  measuringPoint
                  startDate
                  endDate
                  deviceID
                  createdAt
                  updatedAt
                }
                nextToken
              }
              name
              status
              active
              lastData
              comment
              createdAt
              updatedAt
            }
            startDate
            endDate
            deviceID
            MeasuringPoint {
              id
              project
              name
              devices {
                items {
                  id
                  measuringPoint
                  startDate
                  endDate
                  deviceID
                  createdAt
                  updatedAt
                }
                nextToken
              }
              deviceCase
              deviceReady
              deviceReadyTime
              Project {
                id
                name
                active
                comment
                timeoutActive
                measuringPoints {
                  nextToken
                }
                projectOwner
                plan
                planStart
                planEnd
                planNumber
                createdAt
                updatedAt
              }
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        deviceCase
        deviceReady
        deviceReadyTime
        Project {
          id
          name
          active
          comment
          timeoutActive
          measuringPoints {
            items {
              id
              project
              name
              devices {
                items {
                  id
                  measuringPoint
                  startDate
                  endDate
                  deviceID
                  createdAt
                  updatedAt
                }
                nextToken
              }
              deviceCase
              deviceReady
              deviceReadyTime
              Project {
                id
                name
                active
                comment
                timeoutActive
                measuringPoints {
                  nextToken
                }
                projectOwner
                plan
                planStart
                planEnd
                planNumber
                createdAt
                updatedAt
              }
              createdAt
              updatedAt
            }
            nextToken
          }
          projectOwner
          plan
          planStart
          planEnd
          planNumber
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const deleteMeasuringPointDevice = /* GraphQL */ `
  mutation DeleteMeasuringPointDevice(
    $input: DeleteMeasuringPointDeviceInput!
    $condition: ModelMeasuringPointDeviceConditionInput
  ) {
    deleteMeasuringPointDevice(input: $input, condition: $condition) {
      id
      measuringPoint
      device {
        id
        MeasuringPointDevices {
          items {
            id
            measuringPoint
            device {
              id
              MeasuringPointDevices {
                items {
                  id
                  measuringPoint
                  startDate
                  endDate
                  deviceID
                  createdAt
                  updatedAt
                }
                nextToken
              }
              name
              status
              active
              lastData
              comment
              createdAt
              updatedAt
            }
            startDate
            endDate
            deviceID
            MeasuringPoint {
              id
              project
              name
              devices {
                items {
                  id
                  measuringPoint
                  startDate
                  endDate
                  deviceID
                  createdAt
                  updatedAt
                }
                nextToken
              }
              deviceCase
              deviceReady
              deviceReadyTime
              Project {
                id
                name
                active
                comment
                timeoutActive
                measuringPoints {
                  nextToken
                }
                projectOwner
                plan
                planStart
                planEnd
                planNumber
                createdAt
                updatedAt
              }
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        name
        status
        active
        lastData
        comment
        createdAt
        updatedAt
      }
      startDate
      endDate
      deviceID
      MeasuringPoint {
        id
        project
        name
        devices {
          items {
            id
            measuringPoint
            device {
              id
              MeasuringPointDevices {
                items {
                  id
                  measuringPoint
                  startDate
                  endDate
                  deviceID
                  createdAt
                  updatedAt
                }
                nextToken
              }
              name
              status
              active
              lastData
              comment
              createdAt
              updatedAt
            }
            startDate
            endDate
            deviceID
            MeasuringPoint {
              id
              project
              name
              devices {
                items {
                  id
                  measuringPoint
                  startDate
                  endDate
                  deviceID
                  createdAt
                  updatedAt
                }
                nextToken
              }
              deviceCase
              deviceReady
              deviceReadyTime
              Project {
                id
                name
                active
                comment
                timeoutActive
                measuringPoints {
                  nextToken
                }
                projectOwner
                plan
                planStart
                planEnd
                planNumber
                createdAt
                updatedAt
              }
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        deviceCase
        deviceReady
        deviceReadyTime
        Project {
          id
          name
          active
          comment
          timeoutActive
          measuringPoints {
            items {
              id
              project
              name
              devices {
                items {
                  id
                  measuringPoint
                  startDate
                  endDate
                  deviceID
                  createdAt
                  updatedAt
                }
                nextToken
              }
              deviceCase
              deviceReady
              deviceReadyTime
              Project {
                id
                name
                active
                comment
                timeoutActive
                measuringPoints {
                  nextToken
                }
                projectOwner
                plan
                planStart
                planEnd
                planNumber
                createdAt
                updatedAt
              }
              createdAt
              updatedAt
            }
            nextToken
          }
          projectOwner
          plan
          planStart
          planEnd
          planNumber
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const createDevice = /* GraphQL */ `
  mutation CreateDevice(
    $input: CreateDeviceInput!
    $condition: ModelDeviceConditionInput
  ) {
    createDevice(input: $input, condition: $condition) {
      id
      MeasuringPointDevices {
        items {
          id
          measuringPoint
          device {
            id
            MeasuringPointDevices {
              items {
                id
                measuringPoint
                device {
                  id
                  name
                  status
                  active
                  lastData
                  comment
                  createdAt
                  updatedAt
                }
                startDate
                endDate
                deviceID
                MeasuringPoint {
                  id
                  project
                  name
                  deviceCase
                  deviceReady
                  deviceReadyTime
                  createdAt
                  updatedAt
                }
                createdAt
                updatedAt
              }
              nextToken
            }
            name
            status
            active
            lastData
            comment
            createdAt
            updatedAt
          }
          startDate
          endDate
          deviceID
          MeasuringPoint {
            id
            project
            name
            devices {
              items {
                id
                measuringPoint
                device {
                  id
                  name
                  status
                  active
                  lastData
                  comment
                  createdAt
                  updatedAt
                }
                startDate
                endDate
                deviceID
                MeasuringPoint {
                  id
                  project
                  name
                  deviceCase
                  deviceReady
                  deviceReadyTime
                  createdAt
                  updatedAt
                }
                createdAt
                updatedAt
              }
              nextToken
            }
            deviceCase
            deviceReady
            deviceReadyTime
            Project {
              id
              name
              active
              comment
              timeoutActive
              measuringPoints {
                items {
                  id
                  project
                  name
                  deviceCase
                  deviceReady
                  deviceReadyTime
                  createdAt
                  updatedAt
                }
                nextToken
              }
              projectOwner
              plan
              planStart
              planEnd
              planNumber
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
        }
        nextToken
      }
      name
      status
      active
      lastData
      comment
      createdAt
      updatedAt
    }
  }
`;
export const updateDevice = /* GraphQL */ `
  mutation UpdateDevice(
    $input: UpdateDeviceInput!
    $condition: ModelDeviceConditionInput
  ) {
    updateDevice(input: $input, condition: $condition) {
      id
      MeasuringPointDevices {
        items {
          id
          measuringPoint
          device {
            id
            MeasuringPointDevices {
              items {
                id
                measuringPoint
                device {
                  id
                  name
                  status
                  active
                  lastData
                  comment
                  createdAt
                  updatedAt
                }
                startDate
                endDate
                deviceID
                MeasuringPoint {
                  id
                  project
                  name
                  deviceCase
                  deviceReady
                  deviceReadyTime
                  createdAt
                  updatedAt
                }
                createdAt
                updatedAt
              }
              nextToken
            }
            name
            status
            active
            lastData
            comment
            createdAt
            updatedAt
          }
          startDate
          endDate
          deviceID
          MeasuringPoint {
            id
            project
            name
            devices {
              items {
                id
                measuringPoint
                device {
                  id
                  name
                  status
                  active
                  lastData
                  comment
                  createdAt
                  updatedAt
                }
                startDate
                endDate
                deviceID
                MeasuringPoint {
                  id
                  project
                  name
                  deviceCase
                  deviceReady
                  deviceReadyTime
                  createdAt
                  updatedAt
                }
                createdAt
                updatedAt
              }
              nextToken
            }
            deviceCase
            deviceReady
            deviceReadyTime
            Project {
              id
              name
              active
              comment
              timeoutActive
              measuringPoints {
                items {
                  id
                  project
                  name
                  deviceCase
                  deviceReady
                  deviceReadyTime
                  createdAt
                  updatedAt
                }
                nextToken
              }
              projectOwner
              plan
              planStart
              planEnd
              planNumber
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
        }
        nextToken
      }
      name
      status
      active
      lastData
      comment
      createdAt
      updatedAt
    }
  }
`;
export const deleteDevice = /* GraphQL */ `
  mutation DeleteDevice(
    $input: DeleteDeviceInput!
    $condition: ModelDeviceConditionInput
  ) {
    deleteDevice(input: $input, condition: $condition) {
      id
      MeasuringPointDevices {
        items {
          id
          measuringPoint
          device {
            id
            MeasuringPointDevices {
              items {
                id
                measuringPoint
                device {
                  id
                  name
                  status
                  active
                  lastData
                  comment
                  createdAt
                  updatedAt
                }
                startDate
                endDate
                deviceID
                MeasuringPoint {
                  id
                  project
                  name
                  deviceCase
                  deviceReady
                  deviceReadyTime
                  createdAt
                  updatedAt
                }
                createdAt
                updatedAt
              }
              nextToken
            }
            name
            status
            active
            lastData
            comment
            createdAt
            updatedAt
          }
          startDate
          endDate
          deviceID
          MeasuringPoint {
            id
            project
            name
            devices {
              items {
                id
                measuringPoint
                device {
                  id
                  name
                  status
                  active
                  lastData
                  comment
                  createdAt
                  updatedAt
                }
                startDate
                endDate
                deviceID
                MeasuringPoint {
                  id
                  project
                  name
                  deviceCase
                  deviceReady
                  deviceReadyTime
                  createdAt
                  updatedAt
                }
                createdAt
                updatedAt
              }
              nextToken
            }
            deviceCase
            deviceReady
            deviceReadyTime
            Project {
              id
              name
              active
              comment
              timeoutActive
              measuringPoints {
                items {
                  id
                  project
                  name
                  deviceCase
                  deviceReady
                  deviceReadyTime
                  createdAt
                  updatedAt
                }
                nextToken
              }
              projectOwner
              plan
              planStart
              planEnd
              planNumber
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
        }
        nextToken
      }
      name
      status
      active
      lastData
      comment
      createdAt
      updatedAt
    }
  }
`;
export const createBackgroundData = /* GraphQL */ `
  mutation CreateBackgroundData(
    $input: CreateBackgroundDataInput!
    $condition: ModelBackgroundDataConditionInput
  ) {
    createBackgroundData(input: $input, condition: $condition) {
      deviceId
      datatimestamp
      vX
      vY
      vZ
      createdAt
      updatedAt
    }
  }
`;
export const updateBackgroundData = /* GraphQL */ `
  mutation UpdateBackgroundData(
    $input: UpdateBackgroundDataInput!
    $condition: ModelBackgroundDataConditionInput
  ) {
    updateBackgroundData(input: $input, condition: $condition) {
      deviceId
      datatimestamp
      vX
      vY
      vZ
      createdAt
      updatedAt
    }
  }
`;
export const deleteBackgroundData = /* GraphQL */ `
  mutation DeleteBackgroundData(
    $input: DeleteBackgroundDataInput!
    $condition: ModelBackgroundDataConditionInput
  ) {
    deleteBackgroundData(input: $input, condition: $condition) {
      deviceId
      datatimestamp
      vX
      vY
      vZ
      createdAt
      updatedAt
    }
  }
`;
export const createEventData = /* GraphQL */ `
  mutation CreateEventData(
    $input: CreateEventDataInput!
    $condition: ModelEventDataConditionInput
  ) {
    createEventData(input: $input, condition: $condition) {
      deviceId
      starttimestamp
      duration
      vX
      vY
      vZ
      createdAt
      updatedAt
    }
  }
`;
export const updateEventData = /* GraphQL */ `
  mutation UpdateEventData(
    $input: UpdateEventDataInput!
    $condition: ModelEventDataConditionInput
  ) {
    updateEventData(input: $input, condition: $condition) {
      deviceId
      starttimestamp
      duration
      vX
      vY
      vZ
      createdAt
      updatedAt
    }
  }
`;
export const deleteEventData = /* GraphQL */ `
  mutation DeleteEventData(
    $input: DeleteEventDataInput!
    $condition: ModelEventDataConditionInput
  ) {
    deleteEventData(input: $input, condition: $condition) {
      deviceId
      starttimestamp
      duration
      vX
      vY
      vZ
      createdAt
      updatedAt
    }
  }
`;
export const createEventDataDetail = /* GraphQL */ `
  mutation CreateEventDataDetail(
    $input: CreateEventDataDetailInput!
    $condition: ModelEventDataDetailConditionInput
  ) {
    createEventDataDetail(input: $input, condition: $condition) {
      deviceId
      datatimestamp
      starttimestamp
      duration
      vX
      vY
      vZ
      createdAt
      updatedAt
    }
  }
`;
export const updateEventDataDetail = /* GraphQL */ `
  mutation UpdateEventDataDetail(
    $input: UpdateEventDataDetailInput!
    $condition: ModelEventDataDetailConditionInput
  ) {
    updateEventDataDetail(input: $input, condition: $condition) {
      deviceId
      datatimestamp
      starttimestamp
      duration
      vX
      vY
      vZ
      createdAt
      updatedAt
    }
  }
`;
export const deleteEventDataDetail = /* GraphQL */ `
  mutation DeleteEventDataDetail(
    $input: DeleteEventDataDetailInput!
    $condition: ModelEventDataDetailConditionInput
  ) {
    deleteEventDataDetail(input: $input, condition: $condition) {
      deviceId
      datatimestamp
      starttimestamp
      duration
      vX
      vY
      vZ
      createdAt
      updatedAt
    }
  }
`;
export const createSetting = /* GraphQL */ `
  mutation CreateSetting(
    $input: CreateSettingInput!
    $condition: ModelSettingConditionInput
  ) {
    createSetting(input: $input, condition: $condition) {
      type
      value
      name
      createdAt
      updatedAt
    }
  }
`;
export const updateSetting = /* GraphQL */ `
  mutation UpdateSetting(
    $input: UpdateSettingInput!
    $condition: ModelSettingConditionInput
  ) {
    updateSetting(input: $input, condition: $condition) {
      type
      value
      name
      createdAt
      updatedAt
    }
  }
`;
export const deleteSetting = /* GraphQL */ `
  mutation DeleteSetting(
    $input: DeleteSettingInput!
    $condition: ModelSettingConditionInput
  ) {
    deleteSetting(input: $input, condition: $condition) {
      type
      value
      name
      createdAt
      updatedAt
    }
  }
`;
