import "./index.css";
import "bootstrap/dist/css/bootstrap.css";

import { withAuthenticator } from "@aws-amplify/ui-react";
import { CSSReset, ThemeProvider } from "@chakra-ui/core";
import Amplify from "aws-amplify";
import React, { Suspense } from "react";

import awsconfig from "./aws-exports";
import { zipExportEndpoints } from "./endpoints";
import Router from "./Router";
import customTheme from "./theme";

Amplify.configure({
  ...awsconfig,
  API: {
    endpoints: [
      {
        name: "events-zip-export",
        endpoint:
          process.env.REACT_APP_ENV === "production"
            ? zipExportEndpoints.production
            : zipExportEndpoints.dev,
      },
    ],
  },
});

const App = () => (
  <Suspense fallback={<div>loading...</div>}>
    <ThemeProvider theme={customTheme}>
      <CSSReset />
      <Router />
    </ThemeProvider>
  </Suspense>
);

export default withAuthenticator(App);
