import create from "zustand";

import { MeasuringPoint, MeasuringPointDevice, Project } from "../types";
import { hasAssignedDevice } from "../utils";
import { readPlannedProjects, readProjects } from "../utils/projects";

type ProjectFilter = { deviceStatus?: number; active?: boolean; search?: string };

interface OverviewState extends Record<string, unknown> {
  allProjects: Project[];
  projects: Project[];
  plannedProjects: Project[];
  setProjects: (projects: Project[]) => void;

  loading: boolean;
  setLoading: (loading: boolean) => void;

  loadProjects: () => void;
  loadPlannedProjects: () => void;

  expandedProjects: string[];
  expandProject: (project: string, only?: boolean) => void;
  unexpandProject: (project: string) => void;

  projectFilter: ProjectFilter;
  setProjectFilter: (projectFilter: ProjectFilter) => void;
}

const initialState = {
  allProjects: [],
  projects: [],
  plannedProjects: [],
  loading: true,
  expandedProjects: [],
  projectFilter: { active: true },
};

export const useProjectStore = create<OverviewState>((set, get) => ({
  ...initialState,
  setProjects: (projects) => set({ projects }),
  setLoading: (loading) => set({ loading }),
  loadProjects: async () => {
    set({ allProjects: await readProjects(), loading: false });
  },

  loadPlannedProjects: async () => {
    set({ plannedProjects: await readPlannedProjects(), loading: false });
  },

  expandProject: (project, only) => {
    if (only) {
      set({ expandedProjects: [project] });
    } else {
      set({ expandedProjects: [...get().expandedProjects, project] });
    }
  },
  unexpandProject: (project) =>
    set({ expandedProjects: get().expandedProjects.filter((x: string) => x != project) }),
  setProjectFilter: (projectFilter) => set({ projectFilter }),
}));

const filter = (allProjects: Project[]): Project[] => {
  const state = useProjectStore.getState();
  return allProjects
    .filter((project) =>
      project.name.toLowerCase().includes(state.projectFilter?.search?.toLowerCase() || "")
    )
    .filter((project) => {
      if (state.projectFilter.active === undefined) {
        return true;
      }
      return state.projectFilter.active ? project.active === 1 : project.active === 0;
    })
    .filter((project) => {
      if (!state.projectFilter.deviceStatus && state.projectFilter.deviceStatus === undefined) {
        return true;
      }
      // @ts-ignore
      return project.measuringPoints?.items.find((mp: MeasuringPoint) => {
        // @ts-ignore
        return mp.devices?.items.find((mpd: MeasuringPointDevice) => {
          if (!state.projectFilter.deviceStatus || state.projectFilter.deviceStatus === undefined) {
            return true;
          }
          if (!hasAssignedDevice(mpd)) {
            return false;
          }
          if (state.projectFilter.deviceStatus === mpd.device.status) {
            return true;
          }
          return false;
        });
      });
    });
};

useProjectStore.subscribe(
  (allProjects: Project[]) => {
    useProjectStore.setState({ projects: filter(allProjects) });
  },
  (state) => state.allProjects
);

useProjectStore.subscribe(
  () => {
    useProjectStore.setState({ projects: filter(useProjectStore.getState().allProjects) });
  },
  (state) => state.projectFilter
);
