import {
  Button,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverCloseButton,
  PopoverContent,
  PopoverHeader,
  PopoverTrigger,
  Stack,
} from "@chakra-ui/core";
import _ from "lodash";
import React from "react";

import { Changes, Device } from "../../types";
import { dateToDbTime } from "../../utils";

interface NewMPDPopoverProps {
  availableDevices: Device[];
  changes: Changes;
  setChanges: any;
}

export const NewMPDPopover = ({ availableDevices, changes, setChanges }: NewMPDPopoverProps) => (
  <Popover>
    <PopoverTrigger>
      <Button leftIcon="link" aria-label="Gerät zuweisen">
        Gerät zuweisen
      </Button>
    </PopoverTrigger>
    <PopoverContent zIndex={4}>
      <PopoverArrow />
      <PopoverCloseButton />
      <PopoverHeader>Gerät auswählen zum Zuweisen</PopoverHeader>
      <PopoverBody>
        <Stack spacing={2}>
          {availableDevices
            .filter((device: Device) => {
              if (_.size(changes.addMeasuringPointDevices) > 0) {
                // @ts-ignore
                return !Object.keys(changes.addMeasuringPointDevices).includes(device.id);
              }
              return true;
            })
            .map((device: any) => (
              <Button
                key={device.id}
                w="full"
                onClick={() => {
                  setChanges(
                    _.merge({}, changes, {
                      addMeasuringPointDevices: _.merge({}, changes.addMeasuringPointDevices, {
                        [device.id]: {
                          startDate: dateToDbTime(new Date()),
                          device: { name: device.name },
                        },
                      }),
                    })
                  );
                }}
              >
                {device.name}
              </Button>
            ))}
        </Stack>
      </PopoverBody>
    </PopoverContent>
  </Popover>
);
