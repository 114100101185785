import { Box, Button, Flex, IconButton, Image, Stack, StackProps } from "@chakra-ui/core";
import { Auth } from "aws-amplify";
import React, { FunctionComponent } from "react";
import { isMobile } from "react-device-detect";
import {
  IoIosBug,
  IoIosLogOut,
  IoIosSettings,
  IoMdApps,
  IoMdCalendar,
  IoMdHome,
} from "react-icons/io";
import { useHistory } from "react-router-dom";

import i18n from "../i18n";
import { useDeviceStore } from "../store/device-store";
import { useProjectStore } from "../store/project-store";
import theme from "../theme";

const pages = [
  { text: "Dashboard", icon: IoMdHome, url: "/" },
  { text: "Geräteübersicht", icon: IoMdApps, url: "/devices" },
  { text: "Planung", icon: IoMdCalendar, url: "/planner" },
  { text: "Einstellungen", icon: IoIosSettings, url: "/settings" },
];

const Navigation: FunctionComponent<StackProps> = ({ ...props }) => {
  const { setDeviceFilter } = useDeviceStore();
  const { setProjectFilter } = useProjectStore();

  const logout = async () => {
    try {
      await Auth.signOut();
      window.location.reload();
    } catch (error) {
      console.error("error signing out: ", error);
    }
  };
  const changeLanguage = (lng: any) => {
    i18n.changeLanguage(lng);
  };
  const history = useHistory();
  return (
    <Stack
      spacing={8}
      px={isMobile ? 4 : 2}
      py={isMobile ? 4 : 8}
      bg={theme.colors.iseconRed}
      h={isMobile ? 16 : "100vh"}
      justify="space-between"
      align="center"
      isInline={isMobile}
      zIndex={1000}
      {...props}
    >
      <Stack spacing={16} isInline={isMobile} align="center">
        <Box w="full">
          <Image
            src="/cce-sign.png"
            height={isMobile ? 10 : 12}
            width={isMobile ? 10 : 12}
            objectFit="cover"
            alt="CCE"
            cursor="pointer"
            onClick={() => {
              setDeviceFilter({ search: "", assigned: undefined, status: undefined });
              setProjectFilter({ active: true });
              history.push("/");
            }}
          />
        </Box>
        <Stack spacing={4} isInline={isMobile}>
          {pages.map((page) => (
            <IconButton
              key={page.url}
              color="white"
              opacity={0.8}
              _hover={{ opacity: 1 }}
              _active={{}}
              aria-label={page.text}
              variant="ghost"
              fontSize="3xl"
              icon={page.icon}
              onClick={() => {
                setDeviceFilter({ search: "", assigned: undefined, status: undefined });
                setProjectFilter({ active: true });
                history.push(page.url);
              }}
            />
          ))}
        </Stack>
      </Stack>
      {window.location.host.includes("localhost") ? (
        <IconButton
          color="white"
          opacity={0.8}
          _hover={{ opacity: 1 }}
          aria-label={"DEBUG"}
          variant="ghost"
          fontSize="3xl"
          _active={{}}
          icon={IoIosBug}
          onClick={() => {
            console.debug("Put debug code here");
            history.goBack();
          }}
        />
      ) : null}

      <Flex>
        <Button
          size="xs"
          color="white"
          opacity={0.8}
          variant="ghost"
          onClick={() => {
            changeLanguage("de");
          }}
        >
          DE
        </Button>
        <Button
          size="xs"
          color="white"
          opacity={0.8}
          variant="ghost"
          onClick={() => {
            changeLanguage("en");
          }}
        >
          EN
        </Button>
      </Flex>

      <IconButton
        color="white"
        opacity={0.8}
        _hover={{ opacity: 1 }}
        aria-label={"Logout"}
        variant="ghost"
        fontSize="3xl"
        _active={{}}
        icon={IoIosLogOut}
        onClick={() => {
          logout();
        }}
      />
    </Stack>
  );
};

export default Navigation;
