import { IconButton, Input, Stack } from "@chakra-ui/core";
import React from "react";

export const TimeSelector = ({ value, onClick, placeholder }: any) => (
  <Stack isInline w="full" spacing={0}>
    <IconButton aria-label="Zeitpunkt wählen" icon="calendar" roundedRight={0} />
    <Input
      onClick={onClick}
      value={value}
      placeholder={placeholder || "Datum setzen"}
      roundedLeft={0}
      w="full"
    />
  </Stack>
);
