import { Heading, Spinner, Stack } from "@chakra-ui/core";
import { API, graphqlOperation } from "aws-amplify";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import { CreateDeviceInput } from "../API";
import DeviceTable from "../components/DeviceTable";
import FilterToggle from "../components/FilterToggle";
import Metrics from "../components/Metrics";
import { SearchBar } from "../components/SearchBar";
import UpsertDevice from "../components/UpsertDevice";
import { createDevice } from "../graphql/mutations";
import { listProjects } from "../graphql/queries";
import { onCreateDevice, onDeleteDevice, onUpdateDevice } from "../graphql/subscriptions";
import { Layout } from "../Layout";
import { useDeviceStore } from "../store/device-store";
import subQuery from "../subQuery";
import { fetchEverything } from "../utils";

const Devices = () => {
  const { t } = useTranslation();
  const [projects, setProjects] = useState([]);

  const { loading, deviceFilter, setDeviceFilter, devices, loadDevices } = useDeviceStore();

  const readProjects = async () => {
    const projectsData = await fetchEverything(listProjects, "listProjects", {});
    setProjects(projectsData);
  };

  useEffect(() => {
    readProjects();
    loadDevices();
    subQuery([onCreateDevice, onUpdateDevice, onDeleteDevice], loadDevices);
  }, []);

  return (
    <Layout>
      <Stack spacing={4} pb={24} w="full">
        <Metrics />
        <Stack rounded="lg" w="full" shadow="sm" bg="white" px="10" py="6" spacing="5">
          <Stack isInline align="center" justify="space-between">
            <Heading as="h2" size="lg">
              {t("Geräteverwaltung")}
            </Heading>
            <UpsertDevice
              title={t("Neues Gerät")}
              triggerText={t("Gerät erstellen")}
              submit={async (input: CreateDeviceInput, successCb: () => void) => {
                await API.graphql(graphqlOperation(createDevice, { input }));
                successCb();
              }}
            />
          </Stack>
          <Stack isInline alignItems="center" justify="space-between" w="full">
            <FilterToggle
              onClick={(active: boolean | number | undefined) => {
                if (active === true) {
                  setDeviceFilter({ ...deviceFilter, active: true });
                } else if (active === undefined) {
                  setDeviceFilter({ ...deviceFilter, active: undefined });
                } else if (active === false) {
                  setDeviceFilter({ ...deviceFilter, active: false });
                }
              }}
              options={[
                { name: `${t("Aktive")}`, value: true },
                { name: `${t("Alle")}`, value: undefined },
                { name: `${t("Ausgelagert")}`, value: false },
              ]}
              selectedId={deviceFilter.active}
            />
            <SearchBar
              value={deviceFilter.search}
              onChange={(e) => {
                const term = e?.target?.value;
                setDeviceFilter({ ...deviceFilter, search: term });
              }}
              onClear={() => {
                setDeviceFilter({ ...deviceFilter, search: "" });
              }}
            />
          </Stack>
          {loading ? <Spinner mx="auto" /> : <DeviceTable projects={projects} devices={devices} />}
        </Stack>
      </Stack>
    </Layout>
  );
};

export default Devices;
