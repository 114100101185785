import { Text } from "@chakra-ui/core";
import { Spinner } from "@chakra-ui/core";
import React, { useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import { useTranslation } from "react-i18next";

import {
  onCreateDevice,
  onCreateMeasuringPointDevice,
  onDeleteDevice,
  onDeleteMeasuringPointDevice,
  onUpdateMeasuringPointDevice,
} from "../../graphql/subscriptions";
import subQuery from "../../subQuery";
import { WeekUtilization } from "../../types";
import { getUsageByWeeks } from "../../utils";
import { fetchDeviceInfo } from "../../utils/devices";

const UtilizationTable = () => {
  const [loading, setLoading] = useState(true);
  const [devices, setDevices] = useState([]);
  const [utilResponse, setUtilResponse] = useState<any>([]);
  const { t } = useTranslation();
  const prepareDevices = () => {
    setLoading((status) => true);
    fetchDeviceInfo().then((devicesResponse) => {
      //@ts-ignore
      const devices = devicesResponse.devices;
      setDevices(devices);
      setUtilResponse(devicesResponse);
      setLoading((status) => false);
    });
  };

  useEffect(() => {
    prepareDevices();
    subQuery(
      [
        onDeleteDevice,
        onCreateDevice,
        onCreateMeasuringPointDevice,
        onUpdateMeasuringPointDevice,
        onDeleteMeasuringPointDevice,
      ],
      prepareDevices
    );
  }, []);

  const getColumns = (): WeekUtilization[] => {
    return getUsageByWeeks(utilResponse);
  };

  const columns = [
    {
      name: `${t("KW")}`,
      selector: "calendarWeek",
      center: true,
    },
    {
      name: `${t("Im Einsatz")}`,
      selector: "assignedDevices",
      center: true,
    },
    {
      name: `${t("Geplant")}`,
      selector: "plannedDevices",
      center: true,
    },
    {
      name: `${t("Auslastung")}`,
      selector: "utilization",
      center: true,
      cell: (row: any) => {
        return (
          <Text color={row.utilization > 100 ? "red.500" : "black"}>{row.utilization + "%"}</Text>
        );
      },
    },
    {
      name: `${t("Auslastung")}`,
      selector: "needed",
      center: true,
      cell: (row: any) => {
        return <Text color={row.needed > 0 ? "red.500" : "black"}>{row.needed}</Text>;
      },
    },
  ];
  if (loading) {
    return <Spinner mx={"auto"} />;
  } else {
    return (
      <DataTable
        noHeader={true}
        subHeaderAlign={"right"}
        subHeader={true}
        subHeaderComponent={
          <Text>{`${t("Geräte insgesamt")}: ${devices?.length ? devices.length : 0}`}</Text>
        }
        pagination={true}
        columns={columns}
        data={getColumns()}
      />
    );
  }
};

export default UtilizationTable;
