import "react-datepicker/dist/react-datepicker.css";

import { Box, Icon, IconButton, Stack, Text } from "@chakra-ui/core";
import de from "date-fns/locale/de";
import React from "react";
import DatePicker, { registerLocale } from "react-datepicker";

import { TimeSelector } from "../TimeSelector";

registerLocale("de", de);

interface MPDLineProps {
  startDate: Date | null;
  endDate: Date | null;
  name: string;
  onStartChange: (date: Date | null) => void;
  onEndChange: (date: Date | null) => void;
  onDelete: () => void;
  isActive: boolean;
}

const MPDLine = ({
  startDate,
  endDate,
  name,
  onStartChange,
  onEndChange,
  onDelete,
  isActive,
}: MPDLineProps) => {
  return (
    <Stack justify="space-between" align="center" spacing={3} isInline py={2}>
      <Stack isInline align="center" spacing={1}>
        {isActive ? <Icon name="check-circle" color="green.400" /> : <Icon name="time" />}
        <Text>{name}</Text>
      </Stack>
      <Stack isInline spacing={4}>
        <Box>
          <DatePicker
            selected={startDate}
            onChange={(date: Date) => {
              onStartChange(date);
            }}
            showTimeSelect
            locale="de"
            todayButton="Heute"
            customInput={<TimeSelector />}
            placeholderText="Startdatum"
            timeIntervals={15}
            dateFormat="dd.MM.yyyy, HH:mm"
          />
        </Box>
        <Stack isInline>
          <DatePicker
            selected={endDate}
            onChange={(date: Date) => {
              onEndChange(date);
            }}
            showTimeSelect
            locale="de"
            todayButton="Heute"
            customInput={<TimeSelector />}
            placeholderText="Enddatum"
            timeIntervals={15}
            dateFormat="dd.MM.yyyy, HH:mm"
          />
          <IconButton
            aria-label="Clear Time"
            icon="small-close"
            onClick={() => {
              onEndChange(null);
            }}
          />
        </Stack>
        <IconButton
          variant="ghost"
          aria-label="Remove Device from Measuring Point"
          icon="delete"
          onClick={() => {
            onDelete();
          }}
        />
      </Stack>
    </Stack>
  );
};

export default MPDLine;
