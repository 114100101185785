import { Box } from "@chakra-ui/core";
import React from "react";

import { getStatusColor } from "../utils";

interface StatusProps {
  code: number;
}

const Status = ({ code }: StatusProps) => {
  return <Box h={4} w={4} rounded="full" background={getStatusColor(code || 0)} />;
};

export default Status;
