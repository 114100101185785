import {
  Button,
  FormControl,
  FormLabel,
  IconButton,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Spinner,
  Stack,
  Text,
  useDisclosure,
} from "@chakra-ui/core";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import { Project } from "../types";
import { deleteProject } from "../utils/delete";

interface DeleteProjectProps {
  project: Project;
}

const DeleteProject = ({ project }: DeleteProjectProps) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [applying, setApplying] = useState<boolean>(false);
  const [approved, setApproved] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [warning, setWarning] = useState<string>("");
  const { t } = useTranslation();
  useEffect(() => {
    if (!isOpen) {
      return;
    }
    setLoading(true);
    // Check if project has measuring points.
    // @ts-ignore
    const mps = project.measuringPoints?.items;
    if (mps?.length > 0) {
      setWarning(
        `${t(
          "Dieses Projekt beinhaltet mindestens eine Messstelle. Wenn Sie fortfahren werden diese Messstellen und alle Zuordnungen zu Geräten ebenfalls gelöscht."
        )}`
      );
    }
    setLoading(false);
  }, [isOpen]);

  return (
    <>
      <IconButton
        variant="ghost"
        aria-label="Update Measuring Point"
        icon="delete"
        onClick={async () => {
          onOpen();
        }}
      />
      <Modal
        isOpen={isOpen}
        onClose={() => {
          onClose();
        }}
        size="5xl"
      >
        <ModalOverlay />
        <ModalContent>
          <>
            <ModalHeader>{`Projekt löschen: ${project.name}`}</ModalHeader>
            <ModalCloseButton />
            <ModalBody>
              <Stack spacing={3}>
                <FormControl>
                  <FormLabel htmlFor="email">
                    {t(
                      `Um diese Aktion zu bestätigen, tippen Sie bitte 'entfernen' in das Eingabefeld und klicken Sie auf bestätigen.`
                    )}
                  </FormLabel>
                  <Input
                    placeholder={t("entfernen")}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                      if (e.target.value === `${t("delete")}`) {
                        setApproved(true);
                      } else {
                        setApproved(false);
                      }
                    }}
                  />
                </FormControl>
              </Stack>
            </ModalBody>
            <ModalFooter>
              <Stack spacing={4}>
                {loading ? (
                  <Text>{t("Überprüfe ob Projekt Messstellen beinhaltet")} ...</Text>
                ) : null}
                {warning ? <Text color="orange.500">{warning}</Text> : null}

                <Stack w="full" spacing={4} isInline justifyContent="flex-end">
                  <Button
                    onClick={() => {
                      onClose();
                    }}
                  >
                    abbrechen
                  </Button>
                  <Button
                    minW={32}
                    variantColor="red"
                    isDisabled={!approved}
                    onClick={async () => {
                      (async () => {
                        setApplying(true);
                        await deleteProject(project.id);
                        setApplying(false);
                        onClose();
                      })();
                    }}
                  >
                    {applying ? <Spinner size="sm" /> : "bestätigen"}
                  </Button>
                </Stack>
              </Stack>
            </ModalFooter>
          </>
        </ModalContent>
      </Modal>
    </>
  );
};

export default DeleteProject;
